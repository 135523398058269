/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment, useCallback, useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import { ErrorComponent } from 'pages/Orders/OrderForm/UtilComponents';

import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

import { OptionalLabelIndicator } from './OptionalLabelIndicator';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface ISelect {
  options: { label: string; key: string }[];
  label: string;
  name: string;
  value: string | string[];
  onChange: (...event: any[]) => void;
  error?: FieldError;
  optional?: boolean;
  disabled?: boolean;
  controlStyle?: any;
  multiple?: boolean; // New prop to handle multi-select
}

export const Select: React.FC<ISelect> = ({
  options,
  label,
  name,
  value,
  error,
  onChange,
  optional = false,
  disabled = false,
  controlStyle = undefined,
  multiple = false, // Default to single-select
}) => {
  const isSelected = useCallback(
    (key: string) =>
      Array.isArray(value) ? value.includes(key) : value === key,
    [value],
  );

  const sortedOptions = useMemo(() => {
    return options.sort((a, b) =>
      a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1,
    );
  }, [options]);

  const renderMultiSelect = useCallback(() => {
    return Array.isArray(value) && value.length ? (
      sortedOptions
        .filter(({ key }) => value.includes(key))
        .map(({ label }) => label)
        .join(', ')
    ) : (
      <div className='text-gray-600 text-wrap'>{`Select ${label}`}</div>
    );
  }, [label, sortedOptions, value]);

  return (
    <div className='relative mb-2'>
      <Listbox
        value={value}
        name={name}
        onChange={onChange}
        disabled={disabled}
        multiple={multiple} // Enable multiple selection
      >
        {({ open }) => (
          <>
            <div className='flex justify-between'>
              <Listbox.Label className='block text-sm font-medium leading-6 text-gray-900 text-wrap'>
                {label}
              </Listbox.Label>
              {optional ? <OptionalLabelIndicator /> : null}
            </div>
            <div className='relative' style={controlStyle}>
              <Listbox.Button
                className={classNames(
                  disabled ? 'bg-slate' : 'bg-white',
                  'relative w-full min-h-10 cursor-default rounded-md py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-green-950 sm:text-sm sm:leading-6',
                )}
              >
                <div className='text-gray-950'>
                  {multiple
                    ? renderMultiSelect()
                    : sortedOptions.find(({ key }) => key === value)?.label ?? (
                        <div className='text-gray-600 text-wrap'>{`Select ${label}`}</div>
                      )}
                </div>
                <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                  <ChevronUpDownIcon
                    className='h-5 w-5 text-gray-400'
                    aria-hidden='true'
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave='transition ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <Listbox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                  {sortedOptions.map(({ label, key }) => (
                    <Listbox.Option
                      key={key}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-stepperBg cursor-pointer' : '',
                          'relative cursor-default select-none py-2 pl-8 pr-4 text-gray-950',
                        )
                      }
                      value={key}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block text-wrap',
                            )}
                          >
                            {label}
                          </span>

                          {isSelected(key) ? (
                            <span
                              className={classNames(
                                'absolute inset-y-0 left-0 flex items-center pl-1.5 text-gray-950',
                              )}
                            >
                              <CheckIcon
                                className='h-5 w-5'
                                aria-hidden='true'
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      <ErrorComponent id='state-error' msg={error?.message} />
    </div>
  );
};

export default Select;
