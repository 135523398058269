/* eslint-disable max-lines */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { UseFormGetFieldState, UseFormGetValues } from 'react-hook-form';
import { ReactComponent as DownloadIcon } from 'assets/orderSubmission/Interface/Download.svg';
import { ReactComponent as PackageCheck } from 'assets/orderSubmission/package-check.svg';
import { ReactComponent as PackagePencil } from 'assets/orderSubmission/package-check-1.svg';
import { ReactComponent as PackageHold } from 'assets/orderSubmission/package-check-2.svg';
import { ReactComponent as PackageCancel } from 'assets/orderSubmission/package-check-3.svg';
import classNames from 'classnames';
import Button from 'components/Buttons/Index';
import {
  WEB_CONF_DATE_TIME_FORM_KEYS,
  WEB_CONF_EVENT_INFO_FORM_KEYS,
} from 'constants/order-form';
import { DEFAULT_TIMEZONE } from 'constants/timezone';
import dayjs from 'dayjs';
import * as _ from 'lodash';
import { useRecoilValue } from 'recoil';
import { downloadPDF } from 'services/order';
import { currentUserState } from 'state/atoms';
import { AVOrderFormField } from 'types/av-form-fields';
import { AVOrder, CreateAVOrder, EndClient, State } from 'types/av-orders';
import { InputTypes } from 'types/form';
import { AV_ORDER_STATUS } from 'utils/common.enum';
import { getFormatedDate, getFormatedTime } from 'utils/dayjs';
import { breakAndCapitalize } from 'utils/helper';

import {
  EventItemContainer,
  getOrderStatusName,
  InfoCard,
} from '../OrderForm/UtilComponents';

import WebConfOrderLineItems from './WebconfOrderLineItems';

interface IWebConfOrderSubmissionSuccess {
  states: State[];
  endClients: EndClient[];
  editOrder: AVOrder | undefined;
  getFieldState: UseFormGetFieldState<CreateAVOrder>;
  latesSubmittedOrder: AVOrder | undefined;
  getValues: UseFormGetValues<CreateAVOrder>;
  goBack: () => void;
  eventProfileDynamicFields: AVOrderFormField[];
  contactDynamicFields: AVOrderFormField[];
  eventDetailFormFields: AVOrderFormField[];
}

const WebConfOrderSubmissionSuccess = ({
  editOrder,
  endClients,
  latesSubmittedOrder,
  contactDynamicFields,
  eventDetailFormFields,
  eventProfileDynamicFields,
  goBack,
  getValues,
  getFieldState,
}: IWebConfOrderSubmissionSuccess) => {
  const user = useRecoilValue(currentUserState);
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState<CreateAVOrder>();

  const checkIfFieldDirty = useCallback(
    (fieldName: string) => {
      if (
        editOrder?.orderStatus === AV_ORDER_STATUS.ON_HOLD &&
        formValues?.orderStatus === AV_ORDER_STATUS.SUBMITTED
      ) {
        return false;
      }
      if (editOrder?._id)
        return getFieldState(fieldName as keyof CreateAVOrder).isDirty;
      return false;
    },
    [
      editOrder?._id,
      editOrder?.orderStatus,
      formValues?.orderStatus,
      getFieldState,
    ],
  );

  useEffect(() => {
    setFormValues(getValues());
  }, [getValues]);

  const getFormBasicValues = useCallback(
    (key: string) => {
      if (key === 'enteredBy') return `${user?.firstName} ${user?.lastName}`;
      if (key === 'endClient') {
        return endClients.find((e) => e._id === _.get(formValues, key))?.name;
      }
      return _.get(formValues, key);
    },

    [endClients, formValues, user?.firstName, user?.lastName],
  );

  const onDownload = useCallback(async () => {
    if (!latesSubmittedOrder) return;
    setIsLoading(true);
    await downloadPDF(latesSubmittedOrder);
    setIsLoading(false);
  }, [latesSubmittedOrder]);

  const { status, heading, icon, msg } = useMemo(() => {
    let heading = '';
    let msg = '';
    let icon;
    let status = '';
    switch (formValues?.orderStatus) {
      case AV_ORDER_STATUS.CANCELLED:
        status = 'Cancelled';
        heading = 'Your order has been cancelled!';
        msg = '';
        icon = <PackageCancel className='w-12 h-12' />;
        break;
      case AV_ORDER_STATUS.ON_HOLD:
        status = 'On Hold';
        heading = 'Your order has been put on hold!';
        msg = 'You can change the status of the order from the home page.';
        icon = <PackageHold className='w-12 h-12' />;
        break;

      default:
        status = 'Submitted';
        heading = 'Your order has been submitted!';
        msg =
          'From this moment, we will handle all the necessary arrangements for your event.';
        icon = <PackageCheck className='w-12 h-12' />;
        break;
    }

    if (
      editOrder?.orderStatus === AV_ORDER_STATUS.ON_HOLD &&
      formValues?.orderStatus === AV_ORDER_STATUS.SUBMITTED
    ) {
      status = 'Submitted';
      heading = 'Your order has been submitted!';
      msg =
        'From this moment, we will handle all the necessary arrangements for your event.';
      icon = <PackageCheck className='w-12 h-12' />;
    } else if (
      editOrder?.orderStatus === AV_ORDER_STATUS.ON_HOLD &&
      formValues?.orderStatus === AV_ORDER_STATUS.ON_HOLD
    ) {
      status = 'On Hold';
      heading = 'Your order has been put on hold!';
      msg = 'You can change the status of the order from the home page.';
      icon = <PackageHold className='w-12 h-12' />;
    } else if (editOrder?._id) {
      status = 'Modified';
      heading = 'Your order has been modified!';
      msg =
        'Some fields have been modified from the last time you edited this order.';
      icon = <PackagePencil className='w-12 h-12' />;
    }

    return { heading, icon, msg, status };
  }, [formValues?.orderStatus, editOrder]);

  const getDateTimeValues = (key: string, date: Date, timezone: string) => {
    if (key === 'startDate' || key === 'endDate') {
      return getFormatedDate(date);
    }
    return getFormatedTime(date, timezone);
  };

  return (
    <div className='bg-white p-6 md:px-20 h-full max-w-[1200px] mx-auto'>
      <div className='flex items-center justify-between'>
        <h2 className='font-bold text-2xl text-gray-950'>{`Order ${status}`}</h2>
        <Button
          variant='outlined'
          label='Download PDF'
          type='button'
          startIcon={DownloadIcon}
          loading={isLoading}
          onClick={onDownload}
          disabled={formValues?.orderStatus === AV_ORDER_STATUS.ON_HOLD}
          className={classNames(
            '!border-gray-300 !text-gray-500 !py-3 !rounded-2xl',
            { '!px-16': isLoading },
          )}
        />
      </div>
      <div
        className={classNames(
          'rounded-2xl p-6 mt-4 flex items-center justify-between',
          {
            'bg-green-200':
              formValues?.orderStatus === AV_ORDER_STATUS.SUBMITTED,
            'bg-yellow-200':
              formValues?.orderStatus === AV_ORDER_STATUS.ON_HOLD ||
              (editOrder && editOrder?.orderStatus !== AV_ORDER_STATUS.ON_HOLD),
            'bg-red-200': formValues?.orderStatus === AV_ORDER_STATUS.CANCELLED,
          },
        )}
      >
        <div>
          <h2 className='font-bold text-xl text-gray-950'>{heading}</h2>
          <p>{msg}</p>
        </div>
        {icon}
      </div>

      <div className='flex justify-center gap-4 my-4 h-[calc(100%-220px)]'>
        <InfoCard heading='Event Information'>
          <div className='h-[calc(100%-36px)] overflow-auto'>
            <div className='mb-5'>
              {WEB_CONF_EVENT_INFO_FORM_KEYS.map((key) => (
                <EventItemContainer
                  key={key}
                  heading={breakAndCapitalize(key)}
                  value={getFormBasicValues(key)}
                  isDirty={checkIfFieldDirty(key)}
                />
              ))}

              {eventProfileDynamicFields.map(
                ({ _id, label, metadataKey, inputType }) => {
                  const { DATE } = InputTypes;
                  const key = formValues?.metadata?.[metadataKey];
                  const val =
                    inputType === DATE ? dayjs(key).format('MM/DD/YYYY') : key;

                  return (
                    <EventItemContainer
                      key={_id}
                      heading={breakAndCapitalize(label)}
                      isDirty={checkIfFieldDirty(`metadata.${metadataKey}`)}
                      value={val}
                    />
                  );
                },
              )}

              <p className='text-base font-medium text-gray-700'>
                Event Status:&nbsp;
                <span
                  className={classNames('font-normal text-green-600 ml-1', {
                    'text-yellow-600':
                      formValues?.orderStatus === AV_ORDER_STATUS.ON_HOLD,
                    'text-red-600':
                      formValues?.orderStatus === AV_ORDER_STATUS.CANCELLED,
                  })}
                >
                  {getOrderStatusName(formValues?.orderStatus as string)}
                </span>
              </p>
            </div>
            <div className='mb-5'>
              <h2 className='text-lg font-medium text-gray-950'>Date & Time</h2>
              <EventItemContainer
                heading={breakAndCapitalize('Local Timezone')}
                value={_.get(formValues, 'localTimeZone')}
              />
              {WEB_CONF_DATE_TIME_FORM_KEYS.map((key) => (
                <EventItemContainer
                  key={key}
                  heading={breakAndCapitalize(key)}
                  value={getDateTimeValues(
                    key,
                    _.get(formValues, key),
                    _.get(formValues, 'localTimeZone') || DEFAULT_TIMEZONE,
                  )}
                  isDirty={checkIfFieldDirty(key)}
                />
              ))}
            </div>

            <div>
              <h2 className='text-lg font-medium text-gray-950'>Contacts</h2>

              <p className='text-base font-medium text-gray-700 mt-3 mb-1'>
                Primary Contact
              </p>
              <EventItemContainer
                heading={'Name'}
                value={_.get(formValues, 'onSiteContact.name')}
                isDirty={checkIfFieldDirty('onSiteContact.name')}
              />
              <EventItemContainer
                heading={'Phone Number'}
                value={_.get(formValues, 'onSiteContact.phone')}
                isDirty={checkIfFieldDirty('onSiteContact.phone')}
              />

              {contactDynamicFields.map(({ _id, label, metadataKey }) => (
                <EventItemContainer
                  key={_id}
                  heading={breakAndCapitalize(label)}
                  value={
                    formValues?.metadata?.[metadataKey as keyof CreateAVOrder]
                  }
                  isDirty={checkIfFieldDirty(`metadata.${metadataKey}`)}
                />
              ))}
            </div>
            {eventDetailFormFields.length ? (
              <div className='mt-5 mb-5'>
                <h2 className='text-lg font-medium text-gray-950'>
                  Event Details
                </h2>

                {eventDetailFormFields.map(({ _id, label, metadataKey }) => (
                  <EventItemContainer
                    key={_id}
                    heading={breakAndCapitalize(label)}
                    isDirty={checkIfFieldDirty(`metadata.${metadataKey}`)}
                    value={
                      formValues?.metadata?.[metadataKey as keyof CreateAVOrder]
                    }
                  />
                ))}
              </div>
            ) : null}
            <div className='mb-3'>
              <EventItemContainer
                heading={breakAndCapitalize('Comments')}
                value={_.get(formValues, 'comments')}
                isDirty={checkIfFieldDirty('comments')}
              />
            </div>
          </div>
        </InfoCard>
        <InfoCard heading='Product Selection'>
          <WebConfOrderLineItems
            order={formValues}
            checkIfFieldDirty={checkIfFieldDirty}
          />
        </InfoCard>
      </div>
      <div className='flex justify-end'>
        <Button
          className='!bg-green-950 !px-4 !py-2 rounded-xl'
          label='Back To Home'
          type='button'
          onClick={goBack}
        />
      </div>
    </div>
  );
};

export default WebConfOrderSubmissionSuccess;
