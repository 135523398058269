/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { FieldError } from 'react-hook-form';
import classNames from 'classnames';
import { ErrorComponent } from 'pages/Orders/OrderForm/UtilComponents';

import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

import { OptionalLabelIndicator } from './OptionalLabelIndicator';

interface InputPropTypes {
  disabled?: boolean;
  autoComplete?: 'off';
  defaultValue?: string | number;
  className?: string;
  label: string;
  type: 'text' | 'password' | 'email' | 'number' | 'tel' | 'hidden';
  name: string;
  placeholder: string;
  value: string | number | undefined;
  onChange: (...event: any[]) => void;
  onFocus?: (...event: any[]) => void;
  onBlur?: (...event: any[]) => void;
  error?: FieldError | undefined;
  maxLength?: number;
  icon?: React.ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, 'ref'>
  >;
  optional?: boolean;
}

const Input: React.FC<InputPropTypes> = ({
  label,
  type,
  name,
  placeholder,
  value,
  error,
  onChange,
  onFocus,
  onBlur,
  icon: Icon,
  autoComplete,
  defaultValue,
  className = '',
  disabled = false,
  maxLength,
  optional = false,
}) => {
  return (
    <div
      className={classNames('w-full relative mb-2', {
        hidden: type === 'hidden',
      })}
    >
      <div className='flex justify-between'>
        <label
          htmlFor='common-input'
          className='block text-sm font-medium leading-6 text-gray-700 text-wrap'
        >
          {label}
        </label>
        {optional ? <OptionalLabelIndicator /> : null}
      </div>

      <div className='relative rounded-md shadow-sm '>
        {Icon ? (
          <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
            <Icon className='h-5 w-5 text-gray-400' aria-hidden='true' />
          </div>
        ) : null}

        <input
          autoComplete={autoComplete}
          disabled={disabled}
          type={type}
          name={name}
          value={value}
          id={`${label}-${name}`}
          className={classNames(
            `block h-10 w-full text-sm pr-3 rounded-md border-0 py-1 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset disabled:bg-slate-100 disabled:cursor-not-allowed focus-visible:outline-green-950 disabled:bg-gray-100 ${className}`,
            {
              'ring-red-300': !!error?.message,
              'pl-10': !!Icon,
              'pl-4': !Icon,
            },
          )}
          placeholder={placeholder}
          onChange={onChange}
          defaultValue={defaultValue}
          maxLength={maxLength}
          onFocus={onFocus}
          onBlur={onBlur}
        />

        {error?.message ? (
          <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
            <ExclamationCircleIcon
              className='h-5 w-5 text-red-500'
              aria-hidden='true'
            />
          </div>
        ) : null}
      </div>
      <ErrorComponent msg={error?.message} id={`${name}-error`} />
    </div>
  );
};

export default Input;
