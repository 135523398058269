/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-lines */
import React, { useCallback, useMemo, useState } from 'react';
import {
  Control,
  SubmitHandler,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import AlertMessage from 'components/AlertMessage';
import Modal from 'components/Modal';
import { PolarSelect } from 'constants/common';
import { ORDER_ALERT_MESSAGES, WEBCONF_BASE_PRICE } from 'constants/orders';
import { API_ROUTES } from 'constants/routes';
import { DEFAULT_TIMEZONE } from 'constants/timezone';
import dayjs from 'dayjs';
import { useRequest } from 'hooks/useRequest';
import { useRecoilValue } from 'recoil';
import { currentCustomerState, currentUserState } from 'state/atoms';
import { AVOrderFormField, GenericObject } from 'types/av-form-fields';
import {
  AVOrder,
  CreateAVOrder,
  EndClient,
  GoogleTimeZone,
  State,
  UpdatedCreateAVOrder,
} from 'types/av-orders';
import { calculateWebConfOrderPrices, isRushOrder } from 'utils/avUtils';
import { AV_ORDER_STATUS } from 'utils/common.enum';

import OrderModalFooter from '../OrderForm/Footer';

import WebConfOrderForm from './WebConfOrderForm';
import WebConfOrderSummary from './WebConfOrderSummary';

interface IWebConfOrderModal {
  states: State[];
  endClients: EndClient[];
  webConfOrderModal: boolean;
  dynamicFieldsLoader: boolean;
  editOrder: AVOrder | undefined;
  control: Control<CreateAVOrder>;
  dirtyFields: Partial<CreateAVOrder>;
  contactFields: AVOrderFormField[];
  eventProfileFields: AVOrderFormField[];
  eventDetailFields: AVOrderFormField[];
  watch: UseFormWatch<CreateAVOrder>;
  onCloseWebConfOrderModal: () => void;
  trigger: UseFormTrigger<CreateAVOrder>;
  setValue: UseFormSetValue<CreateAVOrder>;
  getValues: UseFormGetValues<CreateAVOrder>;
  addToLocalOrderState: (order: AVOrder) => void;
  updateLocalOrderState: (order: AVOrder) => void;
  handleSubmitDataState: (state: boolean) => void;
  handleSubmit: UseFormHandleSubmit<CreateAVOrder>;
  handleSubmittedOrder: (order: AVOrder) => void;
  isWithinCancellationPolicy: boolean;
}

const WebConfOrderModal: React.FC<IWebConfOrderModal> = ({
  states,
  control,
  editOrder,
  endClients,
  dirtyFields,
  contactFields,
  eventDetailFields,
  webConfOrderModal,
  eventProfileFields,
  dynamicFieldsLoader,
  watch,
  trigger,
  setValue,
  getValues,
  handleSubmit,
  addToLocalOrderState,
  handleSubmitDataState,
  updateLocalOrderState,
  handleSubmittedOrder,
  onCloseWebConfOrderModal,
  isWithinCancellationPolicy,
}) => {
  const currentUser = useRecoilValue(currentUserState);
  const currentCustomer = useRecoilValue(currentCustomerState);

  const { post, put, isLoading } = useRequest();
  const [showErrorAlert, setShowErrorAlter] = useState(false);
  const [showOnHoldAlert, setShowOnHoldAlert] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showRushOrderAlert, setShowRushOrderAlert] = useState(false);
  const [googleTimezone] = useState<GoogleTimeZone | null>();
  const formValues = watch();

  const addOrder = useCallback(
    async (payload: UpdatedCreateAVOrder) => {
      const { data } = await post<AVOrder, UpdatedCreateAVOrder>(
        `${API_ROUTES.AV_CUSTOMER}/web-conf-order`,
        payload,
      );

      if (data) {
        addToLocalOrderState(data);
        handleSubmittedOrder(data);
        handleSubmitDataState(true);
      }
    },
    [addToLocalOrderState, handleSubmitDataState, handleSubmittedOrder, post],
  );

  const updateOrder = useCallback(
    async (
      payload: UpdatedCreateAVOrder,
      orderId: string,
      modifiedPrices: GenericObject,
    ) => {
      const { data } = await put<AVOrder, UpdatedCreateAVOrder>(
        `${API_ROUTES.AV_CUSTOMER}/web-conf_order/${orderId}`,
        { ...payload, modifiedFields: { ...dirtyFields, ...modifiedPrices } },
      );

      if (data) {
        updateLocalOrderState(data);
        handleSubmitDataState(true);
      }
    },
    [dirtyFields, handleSubmitDataState, put, updateLocalOrderState],
  );

  const createOrUpdateOrder = useCallback(
    ({
      orderId,
      orderStatus: _orderStatus,
      customer,
      attendeeCount,
      endClient,
      enteredBy,
      onSiteContact,
      speakerName,
      startDate,
      type,
      comments,
      endDate,
      endTime,
      hasAdditionalVenues,
      lineItems: _lineItems,
      localTimeZone,
      modifiedFields,
      rushFee,
      setupTime: _setupTime,
      startTime,
      stripeSetupIntentId,
      basePrice,
      metadata,
      orderStatus,
      additionalAttendeesPrice: oldAdditionalAttendeesPrice,
      additionalDurationPrice: oldAdditionalDurationPrice,
    }: CreateAVOrder) => {
      const additionalVenues = hasAdditionalVenues === PolarSelect.YES;
      const modifiedPrices: GenericObject = {};

      let orderTotal = WEBCONF_BASE_PRICE;
      if (startTime && endTime && attendeeCount) {
        const { total, additionalAttendeesPrice, additionalDurationPrice } =
          calculateWebConfOrderPrices(
            basePrice || WEBCONF_BASE_PRICE,
            attendeeCount,
            startDate,
            startTime ?? '',
            endDate,
            endTime ?? '',
          );

        orderTotal = total;

        if (oldAdditionalAttendeesPrice !== additionalAttendeesPrice) {
          modifiedPrices.additionalAttendeesPrice = true;
          setValue('additionalAttendeesPrice', additionalAttendeesPrice, {
            shouldDirty: true,
          });
        }

        if (oldAdditionalDurationPrice !== additionalDurationPrice) {
          modifiedPrices.additionalDurationPrice = true;
          setValue('additionalDurationPrice', additionalDurationPrice, {
            shouldDirty: true,
          });
        }
      }

      const lineItems = [{ item: '', quantity: 1, price: orderTotal }];
      const isOnHold = orderStatus === AV_ORDER_STATUS.ON_HOLD;
      const apiPayload = {
        orderId,
        type,
        enteredBy: currentUser?._id ?? enteredBy,
        attendeeCount: attendeeCount || 0,
        startDate:
          !startDate && isOnHold ? '' : dayjs(startDate).format('YYYY-MM-DD'),
        startTime:
          !startTime && isOnHold ? '' : dayjs(startTime).format('HH:mm'),
        endDate:
          !endDate && isOnHold ? '' : dayjs(endDate).format('YYYY-MM-DD'),
        endTime: !endTime && isOnHold ? '' : dayjs(endTime).format('HH:mm'),
        hasAdditionalVenues: additionalVenues,
        customer,
        endClient,
        onSiteContact,
        modifiedFields,
        speakerName,
        localTimeZone: !localTimeZone && isOnHold ? '' : localTimeZone,
        metadata,
        comments,
        orderStatus,
        stripeSetupIntentId,
        rushFee,
        basePrice,
        lineItems,
      };

      if (editOrder?._id) {
        updateOrder(apiPayload, editOrder?._id, modifiedPrices);
        return;
      }

      addOrder(apiPayload);
    },
    [addOrder, currentUser?._id, editOrder?._id, setValue, updateOrder],
  );

  const onOrderSubmit: SubmitHandler<CreateAVOrder> = useCallback(
    (formData) => {
      const { startTime, localTimeZone, startDate } = formData;
      const orderStatus = showOnHoldAlert
        ? AV_ORDER_STATUS.ON_HOLD
        : AV_ORDER_STATUS.SUBMITTED;

      setValue('orderStatus', orderStatus);

      const orderStartTime = dayjs(startTime).format('HH:mm');

      const isStartTimeChanged = editOrder?.startTime !== orderStartTime;

      const isStartDateChanged =
        editOrder?.startDate !== dayjs(startDate).format('YYYY-MM-DD');

      const isOrderTimeChanged = isStartDateChanged || isStartTimeChanged;

      if (currentCustomer && startTime) {
        const { rushFee } = currentCustomer;
        if (
          (!editOrder || isOrderTimeChanged) &&
          isRushOrder(
            currentCustomer,
            startDate,
            startTime,
            localTimeZone ?? DEFAULT_TIMEZONE,
          )
        ) {
          setValue('rushFee', rushFee, { shouldDirty: false });
          if (orderStatus !== AV_ORDER_STATUS.ON_HOLD) {
            setShowRushOrderAlert(true);
            return;
          }
        } else if (editOrder?.orderStatus === AV_ORDER_STATUS.ON_HOLD) {
          setValue('rushFee', 0, { shouldDirty: false });
        } else {
          setValue('rushFee', editOrder?.rushFee ?? 0, { shouldDirty: false });
        }
      }
      createOrUpdateOrder(getValues());
    },
    [
      showOnHoldAlert,
      setValue,
      editOrder,
      currentCustomer,
      createOrUpdateOrder,
      getValues,
    ],
  );

  const onConfirmRushOrder = useCallback(() => {
    setShowRushOrderAlert(false);
    createOrUpdateOrder(getValues());
  }, [createOrUpdateOrder, getValues]);

  const holdOrder = async () => {
    const shouldPutOnHold = await trigger('endClient');

    setShowOnHoldAlert(false);

    if (shouldPutOnHold) {
      onOrderSubmit(getValues());
    }
  };

  const onClose = useCallback(() => {
    setShowConfirmationModal(true);
  }, []);

  const hideOnHoldButton = useMemo(() => {
    return editOrder?._id && editOrder?.orderStatus !== AV_ORDER_STATUS.ON_HOLD;
  }, [editOrder?._id, editOrder?.orderStatus]);

  return (
    <Modal
      className='w-[70%] max-w-[1200px] h-[calc(100vh-80px)] mt-3'
      open={webConfOrderModal}
      onClose={onClose}
    >
      <form
        onSubmit={handleSubmit(onOrderSubmit)}
        className='h-[calc(100%-75px)]'
      >
        <div className='w-[100%] mx-auto overflow-y-auto h-full mt-5'>
          <div className='flex h-[calc(100%-30px)] gap-4'>
            <WebConfOrderForm
              contactFields={contactFields}
              eventDetailFields={eventDetailFields}
              eventProfileFields={eventProfileFields}
              dynamicFieldsLoader={dynamicFieldsLoader}
              formValues={formValues}
              googleTimezone={googleTimezone}
              setValue={setValue}
              watch={watch}
              states={states}
              control={control}
              editOrder={editOrder}
              endClients={endClients}
              currentUser={currentUser}
              isWithinCancellationPolicy={isWithinCancellationPolicy}
            />

            <WebConfOrderSummary
              states={states}
              formValues={formValues}
              endClients={endClients}
              currentUser={currentUser}
              contactFields={contactFields}
              eventDetailFields={eventDetailFields}
              eventProfileFields={eventProfileFields}
              editOrder={editOrder}
            />
          </div>
        </div>

        <OrderModalFooter
          submitButton
          isLoading={isLoading}
          onHold={hideOnHoldButton ? undefined : () => setShowOnHoldAlert(true)}
          onClose={onClose}
        />
      </form>

      <AlertMessage
        title='Hold Order'
        message={ORDER_ALERT_MESSAGES.ON_HOLD}
        buttons={[
          {
            label: 'Cancel',
            className:
              '!bg-white !border-gray-300 !text-gray-950 mr-2 !px-12 rounded-xl',
            onClick: () => setShowOnHoldAlert(false),
          },
          {
            label: 'Confirm',
            className: '!text-white !bg-green-950 mr-2 !px-12 rounded-xl',
            onClick: holdOrder,
          },
        ]}
        isOpen={showOnHoldAlert}
        onClose={() => setShowOnHoldAlert(false)}
      />

      <AlertMessage
        title='Error'
        message={ORDER_ALERT_MESSAGES.FORM_VALIDATION_ERROR}
        buttons={[
          {
            label: 'Ok',
            className: '!text-white !bg-green-950 mr-2 !px-12 rounded-xl',
            onClick: () => setShowErrorAlter(false),
          },
        ]}
        isOpen={showErrorAlert}
        onClose={() => setShowErrorAlter(false)}
      />

      <AlertMessage
        title='Close Order'
        message={ORDER_ALERT_MESSAGES.CLOSE}
        buttons={[
          {
            label: 'Close',
            className: '!text-white !bg-red-500 mr-2 !px-12 rounded-xl',
            onClick: onCloseWebConfOrderModal,
          },
        ]}
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
      />
      <AlertMessage
        title='Warning'
        message={ORDER_ALERT_MESSAGES.RUSH_ORDER}
        buttons={[
          {
            label: 'Cancel',
            className:
              '!bg-white !border-gray-300 !text-gray-950 mr-2 !px-12 rounded-xl',
            onClick: () => setShowRushOrderAlert(false),
          },
          {
            label: 'Continue',
            className: '!text-white !bg-green-950 mr-2 !px-12 rounded-xl',
            onClick: onConfirmRushOrder,
          },
        ]}
        isOpen={showRushOrderAlert}
        onClose={() => setShowRushOrderAlert(false)}
      />
    </Modal>
  );
};

export default WebConfOrderModal;
