import { AxiosResponse } from 'axios';
import { IResponse } from 'types/response';

export async function SerializeResponse<T>(
  responseP: Promise<AxiosResponse<IResponse<T>>>,
  customMessage?: string,
): Promise<IResponse<T>> {
  try {
    const {
      data: { data, message, error },
      status,
    } = await responseP;
    if (status >= 200 && status < 400) {
      return {
        data,
        error: false,
        message: customMessage ?? message,
      };
    }
    return { data, error, message: message ?? 'Something went wrong' };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const data = error?.response?.data;
    return {
      data: data?.data ?? null,
      error: true,
      message: data?.message ?? 'Something went wrong!',
    };
  }
}
