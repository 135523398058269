import React from 'react';
import {
  Control,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { ItemBundleForm } from 'components/ItemBundleForm';
import { CreateAVOrder } from 'types/av-orders';
import { AvailableBundles, AvailableItems } from 'types/item-bundle';

import { InfoBox, InfoBoxContent, MainHeading } from '../UtilComponents';

interface ISecondStep {
  bundles: AvailableBundles[];
  items: AvailableItems[];
  control: Control<CreateAVOrder>;
  setValue: UseFormSetValue<CreateAVOrder>;
  errors: FieldErrors<CreateAVOrder>;
  watch: UseFormWatch<CreateAVOrder>;
}

const SecondStep: React.FC<ISecondStep> = ({
  bundles,
  items,
  control,
  errors,
  setValue,
  watch,
}) => {
  const formValues = watch();
  const memoizedBundleValues = React.useMemo(() => {
    const bundleValues: AvailableBundles[] = [];
    formValues?.lineItems?.bundles?.forEach((bundle) => {
      if (bundle.quantity > 0) {
        const filteredBundle = bundles.find((b) => b._id === bundle.bundle);
        const alreadyExists = bundleValues.find(
          (b) => b._id === filteredBundle?._id,
        );
        if (filteredBundle?._id && !alreadyExists) {
          bundleValues.push({
            ...filteredBundle,
            quantity: bundle.quantity,
          });
        }
      }
    });
    return bundleValues;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(formValues?.lineItems?.bundles), bundles]);

  const memoizedEquipmentItemsValues = React.useMemo(() => {
    const itemValues: AvailableItems[] = [];
    formValues?.lineItems?.items?.forEach((item) => {
      if (item.quantity > 0) {
        const filteredItem = items.find((i) => i._id === item.item);
        const alreadyExists = itemValues.find(
          (i) => i._id === filteredItem?._id,
        );
        if (
          filteredItem?._id &&
          !alreadyExists &&
          filteredItem?.item.category === 'EQUIPMENT'
        ) {
          itemValues.push({ ...filteredItem, quantity: item.quantity });
        }
      }
    });
    return itemValues;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(formValues?.lineItems?.items), items]);

  const memoizedLaborItemsValues = React.useMemo(() => {
    const itemValues: AvailableItems[] = [];
    formValues?.lineItems?.items?.forEach((item) => {
      if (item.quantity > 0) {
        const filteredItem = items.find((i) => i._id === item.item);
        const alreadyExists = itemValues.find(
          (i) => i._id === filteredItem?._id,
        );
        if (
          filteredItem?._id &&
          !alreadyExists &&
          filteredItem?.item.category === 'LABOR'
        ) {
          itemValues.push({ ...filteredItem, quantity: item.quantity });
        }
      }
    });
    return itemValues;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(formValues?.lineItems?.items), items]);

  return (
    <>
      <div className='flex-[0.6] h-full flex gap-4 flex-col'>
        <MainHeading title='Product Categories' />
        <ItemBundleForm
          bundles={bundles}
          items={items}
          control={control}
          setValue={setValue}
          formValues={formValues}
        />

        {errors.lineItems && (
          <p className='text-red-700 text-sm font-normal'>
            {errors.lineItems.root?.message}
          </p>
        )}
      </div>
      <div className='flex-[0.4] bg-stepperBg rounded-2xl ml-2 p-6 overflow-y-auto flex flex-col gap-4'>
        <MainHeading title='My Cart' />
        {memoizedBundleValues.length ? (
          <InfoBox title='Packages'>
            <div>
              {memoizedBundleValues.map((bundle, idx) => {
                return (
                  <InfoBoxContent
                    key={idx}
                    quantity={bundle.quantity}
                    price={(bundle.price || 0) * bundle.quantity}
                    title={bundle.bundle?.name}
                    subItems={bundle.bundle?.items}
                  />
                );
              })}
            </div>
          </InfoBox>
        ) : null}
        {memoizedEquipmentItemsValues.length ? (
          <InfoBox title='Equipment'>
            <div>
              {memoizedEquipmentItemsValues.map((item, idx) => (
                <InfoBoxContent
                  key={idx}
                  quantity={item.quantity}
                  price={(item.price || 0) * item.quantity}
                  title={item.item?.name}
                />
              ))}
            </div>
          </InfoBox>
        ) : null}
        {memoizedLaborItemsValues.length ? (
          <InfoBox title='Labor'>
            <div>
              {memoizedLaborItemsValues.map((item, idx) => (
                <InfoBoxContent
                  key={idx}
                  quantity={item.quantity}
                  price={(item.price || 0) * item.quantity}
                  title={item.item?.name}
                />
              ))}
            </div>
          </InfoBox>
        ) : null}
        {formValues?.rushFee !== 0 ? (
          <InfoBox title='Extras'>
            <div>
              <InfoBoxContent
                price={formValues?.rushFee || 0}
                title={'Rush Fee'}
              />
            </div>
          </InfoBox>
        ) : null}
      </div>
    </>
  );
};

export default SecondStep;
