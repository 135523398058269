import React from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import Input from 'components/Inputs';
import { OrderDynamicForm } from 'pages/Orders/OrderForm/OrderDynamicForm';
import { AVOrderFormField } from 'types/av-form-fields';
import { CreateAVOrder } from 'types/av-orders';

interface IWebConfOrderContactForm {
  isLoading: boolean;
  control: Control<CreateAVOrder>;
  setValue: UseFormSetValue<CreateAVOrder>;
  contactDynamicFields: AVOrderFormField[];
}

const WebConfOrderContactForm: React.FC<IWebConfOrderContactForm> = ({
  setValue,
  control,
  isLoading,
  contactDynamicFields,
}) => {
  return (
    <>
      <div className='flex gap-2 mt-1'>
        <div className='sm:w-2/5 xl:w-1/3'>
          <Controller
            control={control}
            name='onSiteContact.name'
            rules={{
              required: 'Full Name is required',
            }}
            render={({ fieldState: { error }, field }) => (
              <Input
                type='text'
                label='Full Name'
                value={field.value}
                onChange={field.onChange}
                error={error}
                name={field.name}
                placeholder='Enter Full Name'
              />
            )}
          />
        </div>
        <div className='sm:w-2/5 xl:w-1/3'>
          <Controller
            control={control}
            name='onSiteContact.phone'
            rules={{
              required: 'Phone Number is required',
            }}
            render={({ fieldState: { error }, field }) => (
              <Input
                type='text'
                label='Phone Number'
                value={field.value}
                onChange={field.onChange}
                error={error}
                name={field.name}
                placeholder='Enter Phone Number'
              />
            )}
          />
        </div>
      </div>

      <OrderDynamicForm
        setValue={setValue}
        isWebConf
        control={control}
        isLoading={isLoading}
        dynamicFields={contactDynamicFields}
      />
    </>
  );
};

export default WebConfOrderContactForm;
