/* eslint-disable max-lines */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { UseFormGetFieldState, UseFormGetValues } from 'react-hook-form';
import { ReactComponent as DownloadIcon } from 'assets/orderSubmission/Interface/Download.svg';
import { ReactComponent as PackageCheck } from 'assets/orderSubmission/package-check.svg';
import { ReactComponent as PackagePencil } from 'assets/orderSubmission/package-check-1.svg';
import { ReactComponent as PackageHold } from 'assets/orderSubmission/package-check-2.svg';
import { ReactComponent as PackageCancel } from 'assets/orderSubmission/package-check-3.svg';
import classNames from 'classnames';
import Button from 'components/Buttons/Index';
import {
  AV_RENTAL_EVENT_INFO_FORM_KEYS,
  DATE_TIME_FORM_KEYS,
  VENUE_INFO_FORM_KEYS,
} from 'constants/order-form';
import { API_ROUTES } from 'constants/routes';
import { COLORS } from 'constants/theme';
import { DEFAULT_TIMEZONE } from 'constants/timezone';
import dayjs from 'dayjs';
import { useRequest } from 'hooks/useRequest';
import * as _ from 'lodash';
import { useRecoilValue } from 'recoil';
import { downloadPDF } from 'services/order';
import { currentOrderTaxRate, currentUserState } from 'state/atoms';
import { AVCustomer } from 'types/av-customer';
import { AVOrderFormField } from 'types/av-form-fields';
import { AVOrder, CreateAVOrder, EndClient, State } from 'types/av-orders';
import { InputTypes } from 'types/form';
import { AvailableBundles, AvailableItems } from 'types/item-bundle';
import { formVenueValues } from 'utils/avUtils';
import { AV_ORDER_STATUS } from 'utils/common.enum';
import { getFormatedDate, getFormatedTime } from 'utils/dayjs';
import { breakAndCapitalize, formatCurrency } from 'utils/helper';

import {
  EventItemContainer,
  getOrderStatusName,
  InfoCard,
  TotalQuoteItemContainer,
} from './UtilComponents';

interface IOrderSubmissionSuccess {
  items: AvailableItems[];
  bundles: AvailableBundles[];
  states: State[];
  endClients: EndClient[];
  editOrder: AVOrder | undefined;
  getFieldState: UseFormGetFieldState<CreateAVOrder>;
  latesSubmittedOrder: AVOrder | undefined;
  getValues: UseFormGetValues<CreateAVOrder>;
  goBack: () => void;
  eventProfileDynamicFields: AVOrderFormField[];
  contactDynamicFields: AVOrderFormField[];
  eventDetailFormFields: AVOrderFormField[];
  programManagers: AVCustomer[];
}
type DirtyType = { isDirty?: boolean };
type SelectedBundleType = AvailableBundles & DirtyType;
type selectedItemType = AvailableItems & DirtyType;

const OrderSubmissionSuccess = ({
  endClients,
  items,
  bundles,
  states,
  editOrder,
  getFieldState,
  latesSubmittedOrder,
  getValues,
  goBack,
  eventProfileDynamicFields,
  contactDynamicFields,
  eventDetailFormFields,
  programManagers,
}: IOrderSubmissionSuccess) => {
  const orderTaxRate = useRecoilValue(currentOrderTaxRate);
  const [formValues, setFormValues] = useState<CreateAVOrder>();
  const [selectedBundles, setSelectedBundles] =
    useState<SelectedBundleType[]>();
  const [selectedItems, setSelectedItems] = useState<selectedItemType[]>();
  const user = useRecoilValue(currentUserState);
  const [isLoading, setIsLoading] = useState(false);
  const { get } = useRequest();
  const [order, setOrder] = useState<AVOrder | undefined>();

  useEffect(() => {
    if (editOrder?._id) {
      get<AVOrder>(
        `${API_ROUTES.AV_CUSTOMER}/client-order/${editOrder?._id}`,
      ).then((res) => {
        if (res.data) setOrder(res.data);
      });
    }
  }, [get]);

  const checkIfFieldDirty = useCallback(
    (fieldName: string) => {
      if (
        formValues?.orderStatus === AV_ORDER_STATUS.ON_HOLD ||
        (editOrder?.orderStatus === AV_ORDER_STATUS.ON_HOLD &&
          formValues?.orderStatus === AV_ORDER_STATUS.SUBMITTED)
      ) {
        return false;
      }

      if (editOrder?._id) {
        let name = fieldName;
        if (fieldName === 'venue.state.name') name = 'venue.state'; // Special case for venue.state.name
        return getFieldState(name as keyof CreateAVOrder).isDirty;
      }

      return false;
    },
    [
      editOrder?._id,
      editOrder?.orderStatus,
      formValues?.orderStatus,
      getFieldState,
    ],
  );

  useEffect(() => {
    if (formValues?.lineItems?.items?.length) {
      const temp: selectedItemType[] = [];
      items.map((item) => {
        formValues?.lineItems?.items.map((selectedItem, idx) => {
          const alreadyExists = temp.find((i) => i?._id === item?._id);
          if (
            selectedItem &&
            selectedItem.item === item?._id &&
            !alreadyExists
          ) {
            temp.push({
              ...item,
              quantity: selectedItem.quantity,
              price: selectedItem.price,
              isDirty: checkIfFieldDirty(`lineItems.items.${idx}`),
            });
          }
        });
      });
      setSelectedItems(temp);
    }
  }, [formValues?.lineItems?.items, checkIfFieldDirty, items]);

  useEffect(() => {
    if (formValues?.lineItems?.bundles?.length) {
      const temp: SelectedBundleType[] = [];
      bundles.map((bundle) => {
        formValues?.lineItems?.bundles.map((selectedBundle, idx) => {
          const alreadyExists = temp.find((b) => b?._id === bundle?._id);
          if (
            selectedBundle &&
            selectedBundle.bundle === bundle?._id &&
            !alreadyExists
          ) {
            temp.push({
              ...bundle,
              quantity: selectedBundle.quantity,
              price: selectedBundle.price,
              isDirty: checkIfFieldDirty(`lineItems.bundles.${idx}`),
            });
          }
        });
      });
      setSelectedBundles(temp);
    }
  }, [bundles, formValues?.lineItems?.bundles, checkIfFieldDirty]);

  useEffect(() => {
    setFormValues(getValues());
  }, [getValues]);

  const programManager = useMemo(() => {
    const manager = programManagers?.find(
      ({ _id }) => _id === _.get(formValues, 'programManager'),
    );

    if (!manager) return '';

    const { firstName, lastName } = manager;
    return `${firstName} ${lastName}`;
  }, [programManagers, formValues]);

  const getFormBasicValues = useCallback(
    (key: string) => {
      if (key === 'enteredBy') return `${user?.firstName} ${user?.lastName}`;
      if (key === 'endClient') {
        return endClients.find((e) => e._id === _.get(formValues, key))?.name;
      }
      if (key === 'programManager') {
        return programManager;
      }
      return _.get(formValues, key);
    },

    [endClients, formValues, programManager, user?.firstName, user?.lastName],
  );

  const cartValue = useMemo(() => {
    const bundleValue = selectedBundles?.reduce(
      (acc, bundle) => acc + bundle.price * bundle.quantity,
      0,
    );
    const itemValue = selectedItems?.reduce((acc, item) => {
      if (!item.item) return acc;
      return acc + item.price * item.quantity;
    }, 0);
    let total = 0;
    if (bundleValue) {
      total += bundleValue;
    }
    if (itemValue) {
      total += itemValue;
    }
    if (order?.discount) {
      total -= order?.discount;
    }
    return total;
  }, [selectedBundles, selectedItems, order?.discount]);

  const onDownload = useCallback(async () => {
    if (!latesSubmittedOrder) return;
    setIsLoading(true);
    await downloadPDF(latesSubmittedOrder);
    setIsLoading(false);
  }, [latesSubmittedOrder]);

  const taxAmount = useMemo(() => {
    return Number((cartValue * (orderTaxRate / 100)).toFixed(2));
  }, [orderTaxRate, cartValue]);

  const removedItems = order?.cartRemovals.filter((item) => !!item.item);
  const removedBundle = order?.cartRemovals.filter((item) => !!item.bundle);
  const { status, heading, icon, msg } = useMemo(() => {
    let heading = '';
    let msg = '';
    let icon;
    let status = '';
    switch (formValues?.orderStatus) {
      case AV_ORDER_STATUS.CANCELLED:
        status = 'Cancelled';
        heading = 'Your order has been cancelled!';
        msg = '';
        icon = <PackageCancel className='w-12 h-12' />;
        break;
      case AV_ORDER_STATUS.ON_HOLD:
        status = 'On Hold';
        heading = 'Your order has been put on hold!';
        msg = 'You can change the status of the order from the home page.';
        icon = <PackageHold className='w-12 h-12' />;
        break;

      default:
        status = 'Submitted';
        heading = 'Your order has been submitted!';
        msg =
          'From this moment, we will handle all the necessary arrangements for your event.';
        icon = <PackageCheck className='w-12 h-12' />;
        break;
    }

    if (
      editOrder?.orderStatus === AV_ORDER_STATUS.ON_HOLD &&
      formValues?.orderStatus === AV_ORDER_STATUS.SUBMITTED
    ) {
      status = 'Submitted';
      heading = 'Your order has been submitted!';
      msg =
        'From this moment, we will handle all the necessary arrangements for your event.';
      icon = <PackageCheck className='w-12 h-12' />;
    } else if (
      editOrder?.orderStatus === AV_ORDER_STATUS.ON_HOLD &&
      formValues?.orderStatus === AV_ORDER_STATUS.ON_HOLD
    ) {
      status = 'On Hold';
      heading = 'Your order has been put on hold!';
      msg = 'You can change the status of the order from the home page.';
      icon = <PackageHold className='w-12 h-12' />;
    } else if (editOrder?._id) {
      status = 'Modified';
      heading = 'Your order has been modified!';
      msg =
        'Some fields have been modified from the last time you edited this order.';
      icon = <PackagePencil className='w-12 h-12' />;
    }

    return { heading, icon, msg, status };
  }, [formValues?.orderStatus, editOrder]);

  const getDateTimeValues = (key: string, date: Date, timezone: string) => {
    if (key === 'startDate' || key === 'endDate') {
      return getFormatedDate(date);
    }
    return getFormatedTime(date, timezone);
  };

  const getFormVenueValues = useCallback(
    (key: string) => {
      return formVenueValues(key, states, formValues);
    },
    [formValues, states],
  );

  return (
    <div className='bg-white p-6 md:px-20 h-full max-w-[1200px] mx-auto'>
      <div className='flex items-center justify-between'>
        <h2 className='font-bold text-2xl text-gray-950'>{`Order ${status}`}</h2>
        <Button
          className={classNames(
            '!border-gray-300 !text-gray-500 !py-3 !rounded-2xl',
            {
              '!px-16': isLoading,
            },
          )}
          variant='outlined'
          label='Download PDF'
          type='button'
          startIcon={DownloadIcon}
          loading={isLoading}
          disabled={formValues?.orderStatus === AV_ORDER_STATUS.ON_HOLD}
          onClick={onDownload}
        />
      </div>
      <div
        className={classNames(
          'rounded-2xl p-6 mt-4 flex items-center justify-between',
          {
            'bg-green-200':
              formValues?.orderStatus === AV_ORDER_STATUS.SUBMITTED,
            'bg-yellow-200':
              formValues?.orderStatus === AV_ORDER_STATUS.ON_HOLD ||
              (editOrder && editOrder?.orderStatus !== AV_ORDER_STATUS.ON_HOLD),
            'bg-red-200': formValues?.orderStatus === AV_ORDER_STATUS.CANCELLED,
          },
        )}
      >
        <div>
          <h2 className='font-bold text-xl text-gray-950'>{heading}</h2>
          <p>{msg}</p>
        </div>
        {icon}
      </div>

      <div className='flex gap-4 my-4 h-[calc(100%-220px)]'>
        <InfoCard heading='Event Information'>
          <div className='h-[calc(100%-36px)] overflow-auto'>
            <div className='mb-5'>
              {AV_RENTAL_EVENT_INFO_FORM_KEYS.map((key) => (
                <EventItemContainer
                  key={key}
                  heading={breakAndCapitalize(key)}
                  value={getFormBasicValues(key)}
                  isDirty={checkIfFieldDirty(key)}
                />
              ))}

              {eventProfileDynamicFields.map(
                ({ _id, label, metadataKey, inputType }) => {
                  const value =
                    formValues?.metadata?.[metadataKey as keyof CreateAVOrder];
                  return (
                    <EventItemContainer
                      key={_id}
                      heading={breakAndCapitalize(label)}
                      isDirty={checkIfFieldDirty(`metadata.${metadataKey}`)}
                      value={
                        inputType === InputTypes.DATE
                          ? getDateTimeValues(
                              metadataKey,
                              value,
                              _.get(formValues, 'localTimeZone') ||
                                DEFAULT_TIMEZONE,
                            )
                          : value
                      }
                    />
                  );
                },
              )}

              <p className='text-base font-medium text-gray-700'>
                Event Status:&nbsp;
                <span
                  className={classNames('font-normal text-green-600 ml-1', {
                    'text-yellow-600':
                      formValues?.orderStatus === AV_ORDER_STATUS.ON_HOLD,
                    'text-red-600':
                      formValues?.orderStatus === AV_ORDER_STATUS.CANCELLED,
                  })}
                >
                  {getOrderStatusName(`${formValues?.orderStatus}`)}
                </span>
              </p>
            </div>
            <div className='mb-5'>
              <h2 className='text-lg font-medium text-gray-950'>
                Venue Information
              </h2>
              {VENUE_INFO_FORM_KEYS.map((key) => {
                if (key === 'venue.state.name') {
                  const isDirty = checkIfFieldDirty('venue.state.name');
                  return (
                    <Fragment key={key}>
                      <EventItemContainer
                        heading='State'
                        value={getFormVenueValues(key)}
                        isDirty={isDirty}
                      />
                      <EventItemContainer
                        heading='Country'
                        value={
                          states.find(
                            (s) => s._id === _.get(formValues, 'venue.state'),
                          )?.country
                        }
                        isDirty={isDirty}
                      />
                    </Fragment>
                  );
                }
                return (
                  <EventItemContainer
                    key={key}
                    heading={breakAndCapitalize(key)}
                    value={getFormVenueValues(key)}
                    isDirty={checkIfFieldDirty(key)}
                  />
                );
              })}
            </div>
            <div className='mb-5'>
              <h2 className='text-lg font-medium text-gray-950'>Date & Time</h2>
              <EventItemContainer
                heading={breakAndCapitalize('Local Timezone')}
                value={_.get(formValues, 'localTimeZone')}
                isDirty={checkIfFieldDirty('localTimeZone')}
              />
              {DATE_TIME_FORM_KEYS.map((key) => (
                <EventItemContainer
                  key={key}
                  heading={breakAndCapitalize(key)}
                  value={getDateTimeValues(
                    key,
                    _.get(formValues, key),
                    _.get(formValues, 'localTimeZone') || DEFAULT_TIMEZONE,
                  )}
                  isDirty={checkIfFieldDirty(key)}
                />
              ))}
            </div>

            <div>
              <h2 className='text-lg font-medium text-gray-950'>Contacts</h2>
              <p className='text-base font-medium text-gray-700 mb-1'>
                Venue Contact
              </p>
              <EventItemContainer
                heading={'Name'}
                value={_.get(formValues, 'venue.venueContact.name')}
                isDirty={checkIfFieldDirty('venue.venueContact.name')}
              />
              <EventItemContainer
                heading={'Phone Number'}
                value={_.get(formValues, 'venue.venueContact.phone')}
                isDirty={checkIfFieldDirty('venue.venueContact.phone')}
              />

              <p className='text-base font-medium text-gray-700 mt-3 mb-1'>
                On-Site Contact
              </p>
              <EventItemContainer
                heading={'Name'}
                value={_.get(formValues, 'onSiteContact.name')}
                isDirty={checkIfFieldDirty('onSiteContact.name')}
              />
              <EventItemContainer
                heading={'Phone Number'}
                value={_.get(formValues, 'onSiteContact.phone')}
                isDirty={checkIfFieldDirty('onSiteContact.phone')}
              />

              {contactDynamicFields.map(
                ({ _id, label, metadataKey, inputType }) => {
                  const value =
                    formValues?.metadata?.[metadataKey as keyof CreateAVOrder];
                  return (
                    <EventItemContainer
                      key={_id}
                      heading={breakAndCapitalize(label)}
                      isDirty={checkIfFieldDirty(`metadata.${metadataKey}`)}
                      value={
                        inputType === InputTypes.DATE
                          ? getDateTimeValues(
                              metadataKey,
                              value,
                              _.get(formValues, 'localTimeZone') ||
                                DEFAULT_TIMEZONE,
                            )
                          : value
                      }
                    />
                  );
                },
              )}
            </div>
            {eventDetailFormFields.length ? (
              <div className='mt-5 mb-5'>
                <h2 className='text-lg font-medium text-gray-950'>
                  Event Details
                </h2>

                {eventDetailFormFields.map(
                  ({ _id, label, metadataKey, inputType }) => {
                    const value =
                      formValues?.metadata?.[
                        metadataKey as keyof CreateAVOrder
                      ];
                    return (
                      <EventItemContainer
                        key={_id}
                        heading={breakAndCapitalize(label)}
                        isDirty={checkIfFieldDirty(`metadata.${metadataKey}`)}
                        value={
                          inputType === InputTypes.DATE
                            ? dayjs(value).format('DD-MM-YYYY')
                            : value
                        }
                      />
                    );
                  },
                )}
              </div>
            ) : null}
            <div className='mb-3'>
              <EventItemContainer
                heading={breakAndCapitalize('Comments')}
                value={_.get(formValues, 'comments')}
                isDirty={checkIfFieldDirty('comments')}
              />
            </div>
          </div>
        </InfoCard>
        <InfoCard heading='Product Selection'>
          <>
            <div>
              {selectedBundles?.map((bundle, idx) => (
                <div
                  key={`${bundle.bundle?._id}-${idx}`}
                  className='flex justify-between mb-4'
                >
                  <div className='w-full'>
                    <div
                      className={classNames(
                        'flex justify-between items-center',
                        {
                          'bg-yellow-200 px-1 rounded-lg mb-1': bundle.isDirty,
                        },
                      )}
                    >
                      <h2 className='text-base font-medium text-gray-950'>{`${bundle.quantity} ${bundle.bundle?.name}`}</h2>
                      <p className='flex-[0.2] text-base font-normal text-gray-950 text-right'>
                        {formatCurrency(bundle.price * bundle.quantity)}
                      </p>
                    </div>
                    <ul>
                      {bundle?.bundle?.items.map((bundleItem) => (
                        <li
                          key={bundleItem._id}
                          className='text-sm text-gray-500'
                        >
                          <span className='pl-2'>{bundleItem.name}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {/* <p className='flex-[0.2] text-base font-normal text-gray-950 text-right'>
                    {formatCurrency(bundle.price || 0)}
                  </p> */}
                </div>
              ))}
              {selectedItems?.map((item, idx) => {
                if (!item.item) return null;
                return (
                  <div
                    key={`${item.item?._id}-${idx}`}
                    className={classNames('flex justify-between mb-4', {
                      'bg-yellow-200 px-1 rounded-lg mb-1': item.isDirty,
                    })}
                  >
                    <div className='flex-[0.8]'>
                      <h2 className='text-base font-medium text-gray-950'>{`${item.quantity} ${item.item?.name}`}</h2>
                    </div>
                    <p className='flex-[0.2] text-base font-normal text-gray-950 text-right'>
                      {formatCurrency(item.price * item.quantity)}
                    </p>
                  </div>
                );
              })}
            </div>
            {order?.cartRemovals && order.cartRemovals.length > 0 ? (
              <div>
                <h2 className='text-lg font-medium text-gray-950 text-center mb-2'>
                  Removed Items
                </h2>
                <div>
                  {removedBundle?.map((bundle, idx) => (
                    <div
                      key={`${bundle.bundle?._id}-${idx}`}
                      className='flex justify-between mb-4'
                    >
                      <div className='w-full'>
                        <div
                          style={{ backgroundColor: COLORS.highlightRed }}
                          className={classNames(
                            'flex justify-between items-center',
                          )}
                        >
                          <h2 className='text-base font-medium text-gray-950'>{`${bundle.quantity} ${bundle.bundle?.bundle?.name}`}</h2>
                          <p className='flex-[0.2] text-base font-normal text-gray-950 text-right'>
                            {formatCurrency(bundle.price * bundle.quantity)}
                          </p>
                        </div>
                      </div>
                      {/* <p className='flex-[0.2] text-base font-normal text-gray-950 text-right'>
                    {formatCurrency(bundle.price || 0)}
                  </p> */}
                    </div>
                  ))}
                  {removedItems?.map((item, idx) => {
                    if (!item.item) return null;
                    return (
                      <div
                        key={`${item.item?._id}-${idx}`}
                        style={{ backgroundColor: COLORS.highlightRed }}
                        className={classNames('flex justify-between mb-4', {})}
                      >
                        <div className='flex-[0.8]'>
                          <h2 className='text-base font-medium text-gray-950'>{`${item.quantity} ${item.item?.item?.name}`}</h2>
                        </div>
                        <p className='flex-[0.2] text-base font-normal text-gray-950 text-right'>
                          {formatCurrency(item.price * item.quantity)}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
            <div className='flex justify-between mt-2 pt-1 border-t border-t-gray-300' />
            {order?.discount ? (
              <TotalQuoteItemContainer
                heading={'Discount'}
                value={formatCurrency(order?.discount ?? 0)}
              />
            ) : null}
            <div className='flex justify-between mt-2'>
              <h2 className='font-bold text-base text-gray-950 flex-[0.8]'>
                Sub-total
              </h2>
              <h2 className='font-bold text-base text-gray-950 flex-[0.2] text-right'>
                {formatCurrency(cartValue)}
              </h2>
            </div>
            <TotalQuoteItemContainer
              heading={`Tax (${orderTaxRate.toFixed(2)}%)`}
              value={formatCurrency(taxAmount)}
            />
            {formValues?.rushFee ? (
              <TotalQuoteItemContainer
                heading={'Rush Fee'}
                value={formatCurrency(formValues?.rushFee || 0)}
              />
            ) : null}

            <div className='flex justify-between mt-8'>
              <h2 className='font-bold text-base text-gray-950 uppercase flex-[0.8]'>
                Order Total
              </h2>
              <h2 className='font-bold text-base text-gray-950 flex-[0.2] text-right'>
                {formatCurrency(
                  taxAmount + cartValue + (formValues?.rushFee || 0),
                )}
              </h2>
            </div>
          </>
        </InfoCard>
      </div>
      <div className='flex justify-end'>
        <Button
          className='!bg-green-950 !px-4 !py-2 rounded-xl'
          label='Back To Home'
          type='button'
          onClick={goBack}
        />
      </div>
    </div>
  );
};

export default OrderSubmissionSuccess;
