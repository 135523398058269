import Checkbox from 'components/Inputs/Checkbox';
import { AVOrderType } from 'constants/orders';
import { DEFAULT_TIMEZONE } from 'constants/timezone';
import {
  InvoiceStatusChip,
  OrderStatusChip,
} from 'pages/Orders/OrderForm/UtilComponents';
import { AVOrderFormField } from 'types/av-form-fields';
import { AVOrder } from 'types/av-orders';
import { InputTypes } from 'types/form';
import { convertEnumToString } from 'utils/avUtils';
import { getFormatedDate, getFormatedTime } from 'utils/dayjs';

export const getAVOrderColumn = (
  timezone: string | undefined = DEFAULT_TIMEZONE,
  setOrderId: (orderId: string) => void,
  selectedOrders: string[],
  onChange: (orderId: string) => void,
  dashboardFormFields: AVOrderFormField[],
) => {
  const avOrderColumn = [
    {
      label: 'Order ID',
      fieldName: 'orderId',
      renderDiv: (row: AVOrder) => {
        return (
          <div className='flex items-center gap-2'>
            <Checkbox
              name={`row-selector-${row._id}`}
              containerClass='!py-1'
              checked={selectedOrders.includes(row._id)}
              onChange={() => onChange(row._id)}
            />
            <p
              className='text-green-950 !font-bold cursor-pointer'
              onClick={() => setOrderId(row._id)}
            >
              {row.orderId}
            </p>
          </div>
        );
      },
    },
    {
      label: 'End Client',
      fieldName: 'endClient.name',
      minWidth: 130,
      renderDiv: (row: AVOrder) => {
        return <h1>{row.endClient.name}</h1>;
      },
    },
    {
      label: 'Venue',
      fieldName: 'venue.name',
      minWidth: 120,
      renderDiv: ({ type, venue }: AVOrder) => {
        const { WEB_CONF } = AVOrderType;
        return (
          <h1>
            {type === WEB_CONF ? convertEnumToString(WEB_CONF) : venue?.name}
          </h1>
        );
      },
    },
    {
      label: 'Local Start Date',
      fieldName: 'startDate',
      minWidth: 140,
      renderDiv: (row: AVOrder) => {
        return <h1>{getFormatedDate(row.startDate)}</h1>;
      },
      sortingFn: 'datetime',
    },
    {
      label: 'Local Start Time',
      fieldName: 'startTime',
      minWidth: 140,
      renderDiv: (row: AVOrder) => {
        return (
          <h1>
            {getFormatedTime(row.startTime, row.localTimeZone || timezone)}
          </h1>
        );
      },
    },
    {
      label: 'Local End Date',
      fieldName: 'endDate',
      minWidth: 140,
      renderDiv: (row: AVOrder) => {
        return <h1>{getFormatedDate(row.endDate)}</h1>;
      },
      sortingFn: 'datetime',
    },
    {
      label: 'Local End Time',
      minWidth: 140,
      fieldName: 'endTime',
      renderDiv: (row: AVOrder) => {
        return (
          <h1>{getFormatedTime(row.endTime, row.localTimeZone || timezone)}</h1>
        );
      },
    },
    {
      label: 'Status',
      fieldName: 'orderStatus',
      renderDiv: (row: AVOrder) => <OrderStatusChip value={row.orderStatus} />,
    },
    {
      label: 'Invoice Status',
      minWidth: 140,
      fieldName: 'invoiceStatus',
      renderDiv: (row: AVOrder) => (
        <InvoiceStatusChip value={row.invoiceStatus} />
      ),
    },
  ];

  dashboardFormFields.forEach((val) => {
    const isSameKey = avOrderColumn.some(
      ({ fieldName }) => fieldName === `metadata.${val.metadataKey}`,
    );

    if (isSameKey) return;

    if (val.inputType !== InputTypes.SECTION) {
      avOrderColumn.push({
        minWidth: 120,
        fieldName: `metadata.${val.metadataKey}`,
        label: val.label,
        renderDiv: (row: AVOrder) => {
          const meta = row?.metadata;
          if (!meta?.[val.metadataKey]) {
            return 'N/A';
          }
          return meta[val?.metadataKey];
        },
      });
    }
  });

  return avOrderColumn;
};
