import React, { ChangeEvent } from 'react';
import { ReactComponent as SearchIcon } from 'assets/search_Magnifying_Glass.svg';

interface ISearchBar {
  placeholder: string;
  defaultValue?: string;
  inputClass?: string;
  containerClass?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const SearchBar = ({
  defaultValue,
  containerClass,
  inputClass,
  placeholder,
  onChange,
}: ISearchBar) => {
  return (
    <div className={`relative ${containerClass}`}>
      <input
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={onChange}
        id='comment'
        className={`text-gray-900 shadow-sm placeholder:text-gray-400 text-base font-normal focus-visible:outline-none bg-slate rounded-2xl pl-10 pr-3 py-2 ${inputClass}`}
      />
      <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
        <SearchIcon className='h-5 w-5' />
      </div>
    </div>
  );
};
