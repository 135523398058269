import React from 'react';
import { RiLoader2Line } from 'react-icons/ri';

interface ILoader {
  size?: number;
}

const Loader: React.FC<ILoader> = ({ size = 40 }) => {
  return (
    <div className='flex justify-center h-40 items-center'>
      <RiLoader2Line className='animate-spin' size={size} />
    </div>
  );
};

export default Loader;
