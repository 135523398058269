import { GenericObject } from 'types/av-form-fields';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const formatCurrency = (number?: number) => {
  if (!number) return '$0.00';
  const formattedNumber = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }).format(number);

  return formattedNumber;
};

export const breakAndCapitalize = (input: string) => {
  if (input === 'venue.state.name') return 'State'; // Special case for venue.state.name
  // Check if the string has camel case and dots
  if (/[a-z]/.test(input) && /[A-Z]/.test(input) && /\./.test(input)) {
    // Break camel case and capitalize each part, then split by dots
    const parts = input.replace(/([a-z])([A-Z])/g, '$1 $2').split('.');
    return parts
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(' ');
  } else if (/\./.test(input)) {
    // Split by dots and capitalize each part
    const parts = input.split('.');
    return parts
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(' ');
  }
  // Just capitalize the whole string
  return input
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .split(' ')
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join(' ');
};

export const convertObjIntoQueryParam = (obj: any) => {
  const str = [];
  for (const p in obj)
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join('&');
};

export function convertString(input: string): string {
  return input
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space before uppercase letters
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2') // Insert space between consecutive uppercase letters
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
}

export function removeEmptyKeys(obj: GenericObject) {
  // Filter out entries where the value is undefined or an empty string
  const filteredEntries = Object.entries(obj).filter(([, value]) => {
    return !(value === undefined || value === '');
  });

  return Object.fromEntries(filteredEntries);
}
