/* eslint-disable max-lines */
import { Fragment, useCallback, useMemo, useState } from 'react';
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';
import { ReactComponent as CloseMD } from 'assets/close_MD-1.svg';
import Button from 'components/Buttons/Index';
import Input from 'components/Inputs';
import Select from 'components/Inputs/Select';
import TypeToSelect from 'components/Inputs/TypeToSelect';
import { AVOrderFormField, GenericObject } from 'types/av-form-fields';
import { State } from 'types/av-orders';
import { IFilterOrder } from 'types/filter';
import { AV_ORDER_FILTER_STATUS, AV_ORDER_STATUS } from 'utils/common.enum';
import { convertString } from 'utils/helper';

import { Dialog, Transition } from '@headlessui/react';

import { DynamicFilterFields } from './DynamicFilterFields';

interface ISideOver {
  open: boolean;
  states: State[];
  dashboardFormFields: AVOrderFormField[];
  closeSideOver: () => void;
  onApplyAdvanceFilter: (arg?: IFilterOrder) => void;
}

const Chip = ({ title, onClose }: { title: string; onClose: () => void }) => {
  return (
    <div className='flex items-center justify-between py-2 px-3 rounded-lg bg-gray-200 w-fit gap-2'>
      <p className='text-sm font-normal text-gray-600'>{title}</p>
      <CloseMD
        className='h-4 w-4 cursor-pointer'
        onClick={onClose}
        aria-hidden='true'
      />
    </div>
  );
};

const SideOver: React.FC<ISideOver> = ({
  open,
  states,
  dashboardFormFields,
  closeSideOver,
  onApplyAdvanceFilter,
}) => {
  const [localStartDate, setLocalStartDate] = useState<DateValueType>({
    startDate: null,
    endDate: null,
  });
  const [localEndDate, setLocalEndDate] = useState<DateValueType>({
    startDate: null,
    endDate: null,
  });
  const [enteredByFilter, setEnteredByFilter] = useState<string>('');
  const [venueFilter, setVenueFilter] = useState<string>('');
  const [venueCityFilter, setVenueCityFilter] = useState<string>('');
  const [stateFilter, setStateFilter] = useState<string>('');
  const [orderStatusFilter, setOrderStatusFilter] = useState<
    AV_ORDER_STATUS | ''
  >('');
  const [dynamicFilters, setDynamicFilters] = useState<GenericObject>({});

  const sortedStates = useMemo(
    () => states.sort((a, b) => a.name.localeCompare(b.name)),
    [states],
  );

  const clearDateFilter = (name?: string) => {
    const defaultValue = { startDate: null, endDate: null };
    if (name === 'start') {
      setLocalStartDate(defaultValue);
    } else if (name === 'end') {
      setLocalEndDate(defaultValue);
    } else {
      setLocalStartDate(defaultValue);
      setLocalEndDate(defaultValue);
    }
  };

  const clearDynamicFilter = (key: string) => {
    const __dynamicFilters = { ...dynamicFilters };
    delete __dynamicFilters[key];
    setDynamicFilters(__dynamicFilters);
  };

  const onClear = () => {
    setEnteredByFilter('');
    setVenueFilter('');
    setVenueCityFilter('');
    setStateFilter('');
    setOrderStatusFilter('');
    clearDateFilter();
    setDynamicFilters({});
    onApplyAdvanceFilter(undefined);
    onClose();
  };

  const onClose = () => {
    closeSideOver();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cleanDynamicDate = (value: any) => {
    if (typeof value === 'object' && value?.startDate) {
      return value.startDate.toString();
    }
    return value;
  };

  const onAdvanceFilter = () => {
    const __dynamicFilters: GenericObject = {};

    if (dynamicFilters && Object.keys(dynamicFilters).length) {
      Object.entries(dynamicFilters).forEach(([key, value]) => {
        __dynamicFilters[key] = cleanDynamicDate(value);
      });
    }

    const filterData: IFilterOrder = {
      localStartDate: localStartDate?.startDate?.toString() || '',
      localEndDate: localEndDate?.startDate?.toString() || '', // this is because of date picker local behavior
      enteredBy: enteredByFilter,
      venue: venueFilter,
      venueCity: venueCityFilter,
      state: stateFilter,
      orderStatus: orderStatusFilter,
      dynamicFilters: __dynamicFilters,
    };

    onApplyAdvanceFilter(filterData);
    closeSideOver();
  };

  const handleValueChange = (name: string, value: DateValueType) => {
    if (name === 'start') {
      setLocalStartDate(value);
    } else {
      setLocalEndDate(value);
    }
  };

  const getStateName = useCallback(() => {
    return sortedStates.find((state) => state._id === stateFilter)?.name || '';
  }, [stateFilter, sortedStates]);

  const dateStyleString =
    'border py-2 w-full px-3 rounded-md text-sm focus-visible:outline-green-950';

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={closeSideOver}>
        <Transition.Child
          as={Fragment}
          enter='ease-in-out duration-500'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in-out duration-500'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <Dialog.Panel className='pointer-events-auto relative w-screen max-w-md'>
                  <div className='flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl'>
                    <div className='h-[calc(100vh-140px)]'>
                      <div className='px-4 sm:px-6 flex items-center justify-between'>
                        <Dialog.Title className='text-lg font-semibold leading-6 text-gray-900'>
                          Filter Settings
                        </Dialog.Title>
                        <CloseMD
                          className='h-5 w-5 cursor-pointer'
                          onClick={onClose}
                          aria-hidden='true'
                        />
                      </div>
                      <div className='relative mt-6 flex-1 px-4 sm:px-6 h-[calc(100%-45px)] overflow-auto'>
                        <div className='py-6'>
                          <p className='font-medium text-md'>Applied Filters</p>
                          <div className='flex gap-2 flex-wrap mt-4'>
                            {enteredByFilter ? (
                              <Chip
                                title={`Entered By: ${enteredByFilter}`}
                                onClose={() => setEnteredByFilter('')}
                              />
                            ) : null}
                            {venueFilter ? (
                              <Chip
                                title={`Venue: ${venueFilter}`}
                                onClose={() => setVenueFilter('')}
                              />
                            ) : null}
                            {venueCityFilter ? (
                              <Chip
                                title={`Venue City: ${venueCityFilter}`}
                                onClose={() => setVenueCityFilter('')}
                              />
                            ) : null}
                            {stateFilter ? (
                              <Chip
                                title={`State: ${getStateName()}`}
                                onClose={() => setStateFilter('')}
                              />
                            ) : null}
                            {orderStatusFilter ? (
                              <Chip
                                title={`Status: ${orderStatusFilter}`}
                                onClose={() => setOrderStatusFilter('')}
                              />
                            ) : null}
                            {localStartDate?.startDate?.toString() ? (
                              <Chip
                                title={`Start Date: ${localStartDate?.startDate?.toString()}`}
                                onClose={() => clearDateFilter('start')}
                              />
                            ) : null}
                            {localEndDate?.startDate?.toString() ? (
                              <Chip
                                title={`End Date: ${localEndDate?.startDate?.toString()}`}
                                onClose={() => clearDateFilter('end')}
                              />
                            ) : null}
                            {Object.entries(dynamicFilters).map(
                              ([key, value]) => (
                                <Chip
                                  key={key}
                                  title={`${convertString(
                                    key,
                                  )}: ${cleanDynamicDate(value)}`}
                                  onClose={() => clearDynamicFilter(key)}
                                />
                              ),
                            )}
                          </div>
                        </div>
                        <div>
                          <div className='flex justify-between items-center mb-3 text-md'>
                            <p className='font-medium '>Filtered By</p>
                            <p
                              className='underline text-gray-500 cursor-pointer'
                              onClick={onClear}
                            >
                              Clear all
                            </p>
                          </div>
                          <div className='mb-2'>
                            <Input
                              label='Enter Entered By'
                              value={enteredByFilter}
                              onChange={(e) =>
                                setEnteredByFilter(e.target.value)
                              }
                              error={undefined}
                              name='enteredBy'
                              placeholder='Enter entered by'
                              type='text'
                            />
                          </div>
                          <div className='mb-2'>
                            <label className='block text-sm font-medium leading-6 text-gray-900'>
                              Local Start Date
                            </label>
                            <Datepicker
                              classNames={{ input: () => dateStyleString }}
                              useRange={false}
                              asSingle
                              placeholder='Enter local start date here'
                              value={localStartDate}
                              onChange={(e) => handleValueChange('start', e)}
                              popoverDirection='down'
                            />
                          </div>
                          <div className='mb-2'>
                            <label className='block text-sm font-medium leading-6 text-gray-900'>
                              Local End Date
                            </label>
                            <Datepicker
                              classNames={{ input: () => dateStyleString }}
                              useRange={false}
                              asSingle
                              placeholder='Enter local end date here'
                              value={localEndDate}
                              onChange={(e) => handleValueChange('end', e)}
                              popoverDirection='down'
                            />
                          </div>
                          <div className='mb-2'>
                            <Select
                              error={undefined}
                              name='orderStatus'
                              value={orderStatusFilter}
                              label='Status'
                              onChange={(e) => setOrderStatusFilter(e)}
                              options={Object.keys(AV_ORDER_FILTER_STATUS).map(
                                (key) => ({
                                  key: key,
                                  label:
                                    AV_ORDER_FILTER_STATUS[
                                      key as keyof typeof AV_ORDER_FILTER_STATUS
                                    ],
                                }),
                              )}
                            />
                          </div>
                          <div className='mb-2'>
                            <Input
                              label='Venue Name'
                              value={venueFilter}
                              onChange={(e) => setVenueFilter(e.target.value)}
                              error={undefined}
                              name='venue'
                              placeholder='Enter Venue'
                              type='text'
                            />
                          </div>
                          <div className='mb-2'>
                            <Input
                              label='Venue City'
                              value={venueCityFilter}
                              onChange={(e) =>
                                setVenueCityFilter(e.target.value)
                              }
                              error={undefined}
                              name='venueCity'
                              placeholder='Enter Venue City'
                              type='text'
                            />
                          </div>
                          <div className='mb-2'>
                            <TypeToSelect
                              options={sortedStates.map(
                                ({ _id, name, abbrev }) => ({
                                  value: _id,
                                  label: `${name} (${abbrev})`,
                                }),
                              )}
                              label='State'
                              value={stateFilter}
                              onChange={(e) => setStateFilter(e as string)}
                              name='State'
                            />
                          </div>
                          <DynamicFilterFields
                            dynamicFields={dashboardFormFields}
                            dynamicFilters={dynamicFilters}
                            setDynamicFilters={setDynamicFilters}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='flex justify-end gap-4 px-6 py-4'>
                      <Button
                        className='!border-gray-300 !text-gray-500 !px-6 rounded-xl'
                        onClick={onClose}
                        variant='outlined'
                        label='Cancel'
                        type='button'
                      />
                      <Button
                        className='!bg-green-950 !px-6 rounded-xl'
                        onClick={onAdvanceFilter}
                        label='Apply'
                        type='button'
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SideOver;
