import { FieldError } from 'react-hook-form';
import { ReactComponent as CheckIcon } from 'assets/check.svg';

import { OptionalLabelIndicator } from './OptionalLabelIndicator';

interface ICheckBox {
  options: string[];
  label: string;
  value: string;
  name: string;
  onChange: (...event: string[]) => void;
  error: FieldError | undefined;
  optional?: boolean;
  checkClass?: string;
  checkBoxClass?: string;
}

const MultiCheckbox: React.FC<ICheckBox> = ({
  options,
  label,
  value,
  name,
  error,
  checkClass,
  checkBoxClass,
  onChange,
  optional = false,
}) => {
  return (
    <>
      <fieldset>
        <div className='flex gap-1'>
          <legend className='text-base font-semibold leading-6 text-gray-900'>
            {label}
          </legend>
          {optional ? <OptionalLabelIndicator /> : null}
        </div>
        <div className='space-y-5'>
          {options.map((option, i) => {
            return (
              <div key={option} className='relative flex items-start'>
                <div className='flex h-6 items-center'>
                  <input
                    onChange={() => {
                      onChange(option);
                    }}
                    checked={value?.split(',')?.includes(option)}
                    value={option}
                    id={`${name}-${option}-${i}`}
                    name={`${name}-${option}-${i}`}
                    type='checkbox'
                    className={`peer relative appearance-none rounded-md w-5 h-5 cursor-pointer border border-gray-300 focus-visible:outline-none checked:bg-green-950 ${checkBoxClass}`}
                  />
                  <CheckIcon
                    className={`w-4 h-4 left-[2px] absolute hidden peer-checked:block cursor-pointer ${checkClass}`}
                    onClick={() => onChange(option)}
                  />
                </div>
                <div className='ml-3 min-w-0 flex-1 text-sm leading-6'>
                  <label
                    htmlFor={`${name}-${option}-${i}`}
                    className='select-none font-medium text-gray-900'
                  >
                    {option}
                  </label>
                </div>
              </div>
            );
          })}
        </div>
      </fieldset>
      {error?.message ? (
        <p className='mt-1 ml-1 text-xs text-red-600' id='email-error'>
          {error.message}
        </p>
      ) : null}
    </>
  );
};

export default MultiCheckbox;
