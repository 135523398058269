import React, { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { DEFAULT_TIMEZONE } from 'constants/timezone';
import { AVCustomer } from 'types/av-customer';
import { AVOrderFormField } from 'types/av-form-fields';
import { AVOrder, CreateAVOrder, EndClient, State } from 'types/av-orders';
import { convertEnumToString } from 'utils/avUtils';
import { getFormatedDate, getFormatedTime } from 'utils/dayjs';
import { renderConditionalInput } from 'utils/form-fields';

import { InfoBox, MainHeading } from '../OrderForm/UtilComponents';

interface IWebConfOrderSummary {
  currentUser: AVCustomer | null;
  endClients: EndClient[];
  states: State[];
  formValues: CreateAVOrder;
  contactFields: AVOrderFormField[];
  eventDetailFields: AVOrderFormField[];
  eventProfileFields: AVOrderFormField[];
  editOrder: AVOrder | undefined;
}

interface ISummaryKeyValue {
  keyName: string;
  value: string | number;
}

const WebConfOrderSummary: React.FC<IWebConfOrderSummary> = ({
  editOrder,
  endClients,
  formValues,
  currentUser,
  contactFields,
  eventDetailFields,
  eventProfileFields,
}) => {
  const timezone = formValues?.localTimeZone ?? DEFAULT_TIMEZONE;
  const style = classNames('text-gray-500 text-sm font-normal');

  const SummaryValue: FC<ISummaryKeyValue> = ({ keyName, value }) => {
    return (
      <p className={style}>
        {keyName}: {value}
      </p>
    );
  };

  const editUser = editOrder?.enteredBy as unknown as AVCustomer;
  const userFullName = useMemo(() => {
    return editOrder
      ? `${editUser?.firstName} ${editUser?.lastName}`
      : `${currentUser?.firstName} ${currentUser?.lastName}`;
  }, [currentUser, editOrder, editUser]);

  const endClientName = useMemo(() => {
    return (
      endClients.find(({ _id }) => _id === formValues?.endClient)?.name ?? ''
    );
  }, [endClients, formValues?.endClient]);

  const renderDynamicField = useCallback(
    (field: AVOrderFormField) => {
      const { _id, label, metadataKey, inputType, enableCondition, condition } =
        field;
      const value = formValues?.metadata?.[metadataKey as keyof CreateAVOrder];
      const keyValue = inputType === 'date' ? getFormatedDate(value) : value;

      const isCondition = renderConditionalInput(
        condition,
        enableCondition,
        formValues?.metadata,
      );

      if (isCondition) return null;

      return <SummaryValue key={_id} keyName={label} value={keyValue} />;
    },
    [formValues?.metadata],
  );

  return (
    <div className='flex-[0.4] bg-stepperBg rounded-2xl p-6 overflow-y-auto flex flex-col gap-4'>
      <MainHeading title='Summary' />
      <InfoBox title='Event Profile'>
        <>
          <SummaryValue keyName='Order ID' value={formValues?.orderId} />
          <SummaryValue keyName='Entered By' value={userFullName} />
          <SummaryValue keyName='End Client' value={endClientName} />
          <SummaryValue
            keyName='Attendee Count'
            value={formValues?.attendeeCount}
          />
          <SummaryValue
            keyName='Speaker Name'
            value={formValues?.speakerName}
          />
          <SummaryValue
            keyName='Additional Venues'
            value={convertEnumToString(
              (formValues?.hasAdditionalVenues as string) ?? '',
            )}
          />

          {eventProfileFields.map((field) => renderDynamicField(field))}
        </>
      </InfoBox>
      <InfoBox title='Date & Time'>
        <>
          <SummaryValue
            keyName='Local Timezone'
            value={formValues?.localTimeZone ?? ''}
          />

          <SummaryValue
            keyName='Local Start Date'
            value={
              formValues?.startDate
                ? getFormatedDate(formValues?.startDate)
                : ''
            }
          />

          <SummaryValue
            keyName='Local End Date'
            value={
              formValues?.endDate ? getFormatedDate(formValues?.endDate) : ''
            }
          />

          <SummaryValue
            keyName='Local Start Time'
            value={
              formValues?.startTime
                ? getFormatedTime(formValues?.startTime ?? '', timezone)
                : ''
            }
          />

          <SummaryValue
            keyName='Local End Time:'
            value={
              formValues?.endTime
                ? getFormatedTime(formValues?.endTime, timezone)
                : ''
            }
          />
        </>
      </InfoBox>
      <InfoBox title='Contacts'>
        <>
          <p className='text-gray-600 text-sm font-medium mt-2'>
            Primary Contact
          </p>

          <SummaryValue
            keyName='Full Name'
            value={formValues?.onSiteContact?.name}
          />

          <SummaryValue
            keyName='Phone Number'
            value={formValues?.onSiteContact?.phone}
          />

          {contactFields.map((field) => renderDynamicField(field))}
        </>
      </InfoBox>
      <InfoBox title='Event Details'>
        <>
          {eventDetailFields.map((field) => renderDynamicField(field))}
          <SummaryValue keyName='Comments' value={formValues?.comments ?? ''} />
        </>
      </InfoBox>
    </div>
  );
};

export default WebConfOrderSummary;
