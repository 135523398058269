export enum AV_ORDER_STATUS {
  'SUBMITTED' = 'Submitted',
  'IN_REVIEW' = 'InReview',
  'VENDOR_SELECTION' = 'VendorSelection',
  'BOOKED' = 'Booked',
  'COMPLETED' = 'Completed',
  'ON_HOLD' = 'OnHold',
  'CANCELLED' = 'Cancelled',
  'BILLING_ERROR' = 'BillingError',
}

export enum INVOICE_STATUS {
  'ISSUED' = 'Issued',
  'PAID' = 'Paid',
  'FAILED' = 'Failed',
  'CANCELLED' = 'Cancelled',
  'REFUNDED' = 'Refunded',
}

export enum AV_CUSTOMER_ORDER_STATUS {
  'Submitted' = 'Booked',
  'InReview' = 'Booked',
  'VendorSelection' = 'Booked',
  'Booked' = 'Booked',
  'Completed' = 'Completed',
  'OnHold' = 'On Hold',
  'Cancelled' = 'Cancelled',
  'BillingError' = 'Billing Error',
}

export enum AV_ORDER_FILTER_STATUS {
  'Booked' = 'Booked',
  'Completed' = 'Completed',
  'OnHold' = 'On Hold',
  'Cancelled' = 'Cancelled',
  'BillingError' = 'Billing Error',
}
