/* eslint-disable max-lines */
import { ReactComponent as Pencil } from 'assets/pencil-01.svg';
import classNames from 'classnames';
import {
  AV_CUSTOMER_ORDER_STATUS,
  AV_ORDER_STATUS,
  INVOICE_STATUS,
} from 'utils/common.enum';
import { formatCurrency } from 'utils/helper';

interface IInfoCard {
  children: JSX.Element;
  heading: string;
}

interface IGeneral {
  heading: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  isDirty?: boolean;
}

export const InfoCard = ({ children, heading }: IInfoCard) => {
  return (
    <div className='bg-gray-100 rounded-2xl p-6 flex-[0.5] h-full overflow-auto'>
      <h2 className='text-lg font-medium text-gray-950 text-center mb-2'>
        {heading}
      </h2>
      {children}
    </div>
  );
};

const getHeading = (heading: string) => {
  if (
    heading === 'Start Date' ||
    heading === 'End Date' ||
    heading === 'Start Time' ||
    heading === 'End Time' ||
    heading === 'Setup Time'
  ) {
    return `Local ${heading}`;
  } else if (heading === 'Venue Address') {
    return 'Address';
  } else if (heading === 'Venue Suite Num') {
    return 'Suite #';
  } else if (heading === 'Venue City') {
    return 'City';
  } else if (heading === 'Venue State') {
    return 'State';
  } else if (heading === 'Venue Zip') {
    return 'Zip';
  } else if (heading === 'Venue Room Name') {
    return 'Room Name';
  } else if (heading === 'Venue Reservation Name') {
    return 'Reservation Name';
  } else if (heading === 'Venue Venue Contact Name') {
    return 'Venue Contact';
  } else if (heading === 'Venue Venue Contact Phone') {
    return 'Venue Contact Phone';
  } else if (heading === 'On Site Contact Name') {
    return 'On Site Contact';
  } else if (heading === 'On Site Contact Phone') {
    return 'On Site Contact Phone';
  } else if (heading === 'Order Id') {
    return 'Order ID';
  }
  return heading;
};

export const getOrderStatusName = (status: string) => {
  if (status === 'OnHold') {
    return 'On Hold';
  } else if (status === 'Cancelled') {
    return 'Cancelled';
  }
  return 'Booked';
};

export const EventItemContainer = ({ heading, value, isDirty }: IGeneral) => {
  return (
    <>
      <p
        className={classNames('text-base font-medium text-gray-700', {
          'bg-yellow-200 px-1 rounded-lg mb-1': isDirty,
        })}
      >
        {getHeading(heading)}:&nbsp;
        <span className='font-normal text-gray-500 ml-1'>{value || 'N/A'}</span>
      </p>
    </>
  );
};

export const TotalQuoteItemContainer = ({ heading, value }: IGeneral) => {
  return (
    <div className='flex justify-between mt-2'>
      <h2 className='font-normal text-base text-gray-800 flex-[0.8]'>
        {heading}
      </h2>
      {value ? (
        <h2 className='font-normal text-base text-gray-700 flex-[0.2] text-right'>
          {value}
        </h2>
      ) : null}
    </div>
  );
};

export const MainHeading = ({
  title,
  classNames,
}: {
  title: string;
  classNames?: string;
}) => (
  <h6 className={`font-medium text-gray-950 text-lg ${classNames}`}>{title}</h6>
);

export const SubCategoryHeading = ({
  title,
  classNames,
}: {
  title: string;
  classNames?: string;
}) => (
  <h6 className={`font-medium text-gray-800 text-md !mb-2 ${classNames}`}>
    {title}
  </h6>
);

export const InfoBox = ({
  title,
  value,
  children,
}: {
  title: string;
  value?: string;
  children?: JSX.Element;
}) => {
  return (
    <div className='bg-white rounded-[14px] p-[12px] text-sm'>
      <div className='w-full py-1 px-2 bg-slate mb-3 text-center rounded-[12px] text-base font-medium text-gray-950'>
        {title}
      </div>
      {value ? <p className='mt-2 text-sm font-normal'>{value}</p> : null}
      {children}
    </div>
  );
};

export const InfoBoxContent = ({
  price,
  title,
  quantity,
  subItems,
}: {
  price: number;
  title: string;
  quantity?: number;
  subItems?: { _id: string; name: string }[];
}) => {
  return (
    <>
      <div className='flex flex-row justify-between mb-1'>
        <p className='text-sm font-medium text-gray-850'>{`${
          quantity || 1
        } ${title}`}</p>
        <p className='text-sm font-normal text-gray-500'>
          {formatCurrency(price)}
        </p>
      </div>
      {subItems?.length ? (
        <ul className='text-sm ml-5 font-normal text-gray-500'>
          {subItems.map((subItem) => (
            <li className='list-disc' key={subItem._id}>
              {subItem.name}
            </li>
          ))}
        </ul>
      ) : null}
    </>
  );
};

export const SubHeading = ({
  title,
  onClick,
}: {
  title: string;
  onClick?: () => void;
}) => (
  <div className='flex items-center justify-between mb-2'>
    <h6 className='font-semibold mb-1 text-base text-gray-950'>{title}</h6>
    {onClick ? (
      <Pencil className='cursor-pointer w-18 h-18' onClick={onClick} />
    ) : null}
  </div>
);

export const EventInfoContainer = ({ heading, value }: IGeneral) => {
  return (
    <p className='font-medium text-sm text-gray-950 mb-1'>
      {getHeading(heading)}
      :&nbsp;
      <span className='text-sm font-normal text-gray-500 ml-1'>
        {value || 'N/A'}
      </span>
    </p>
  );
};

export const OrderStatusChip = ({ value }: { value: AV_ORDER_STATUS }) => {
  const isOnHold = value === AV_ORDER_STATUS.ON_HOLD;
  const isDanger =
    AV_ORDER_STATUS.CANCELLED === value ||
    AV_ORDER_STATUS.BILLING_ERROR === value;
  const isSuccess =
    AV_CUSTOMER_ORDER_STATUS[value] === (AV_ORDER_STATUS.BOOKED as string) ||
    value === AV_ORDER_STATUS.COMPLETED;

  return (
    <div
      className={classNames('py-1 px-[6px] rounded-lg w-fit', {
        'bg-warning50': isOnHold,
        'bg-danger50': isDanger,
        'bg-success50': isSuccess,
      })}
    >
      <p
        className={classNames('text-sm font-normal text-center', {
          'text-warning800': isOnHold,
          'text-danger800': isDanger,
          'text-success800': isSuccess,
        })}
      >
        {AV_CUSTOMER_ORDER_STATUS[value]}
      </p>
    </div>
  );
};

export const InvoiceStatusChip = ({
  value,
}: {
  value: INVOICE_STATUS | undefined;
}) => {
  return (
    <div
      className={classNames('py-1 px-[6px] rounded-lg w-fit', {
        'bg-warning50': INVOICE_STATUS.ISSUED === value,
        'bg-danger50':
          INVOICE_STATUS.FAILED === value || INVOICE_STATUS.CANCELLED === value,
        'bg-success50':
          INVOICE_STATUS.PAID === value || INVOICE_STATUS.REFUNDED === value,
      })}
    >
      <p
        className={classNames('text-sm font-normal text-center', {
          'text-warning800': INVOICE_STATUS.ISSUED === value,
          'text-danger800':
            INVOICE_STATUS.FAILED === value ||
            INVOICE_STATUS.CANCELLED === value,
          'text-success800':
            INVOICE_STATUS.PAID === value || INVOICE_STATUS.REFUNDED === value,
        })}
      >
        {value}
      </p>
    </div>
  );
};

export const ErrorComponent = ({ id, msg }: { id: string; msg?: string }) => {
  return (
    <p
      className={classNames('mt-1 ml-1 text-xs text-red-600 absolute', {
        invisible: !msg,
      })}
      id={id}
    >
      {msg ?? '.'}
    </p>
  );
};
