import React, { useMemo } from 'react';
import classNames from 'classnames';
import Menu from 'components/Menu';

interface PaginationProps {
  currentPage: number;
  pageSize: number;
  totalSize: number;
  totalPages: number;
  totalCurrentPageDate: number;
  handlePageChange: (newPage: number) => void;
  handlePageSizeChange: (newPageSize: number) => void;
}

const PAGE_SIZES = [10, 25, 50];

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  pageSize,
  totalSize,
  totalPages,
  totalCurrentPageDate,
  handlePageChange,
  handlePageSizeChange,
}) => {
  const offset = useMemo(
    () => (currentPage - 1) * pageSize,
    [currentPage, pageSize],
  );

  const disabled = useMemo(() => totalSize === 0, [totalSize]);

  return (
    <div className='flex items-center justify-between p-2 bg-white border border-t-0 border-gray-300 rounded-b-xl'>
      <Menu
        items={PAGE_SIZES.map((size) => ({
          title: size.toString(),
          onClick: () => handlePageSizeChange(size),
        }))}
        selectedValue={`${pageSize} per page`}
        disabled={disabled}
      />
      <div className='px-4 py-1 mx-1 text-sm text-gray-950 border rounded-lg'>
        {offset + 1} - {totalCurrentPageDate + offset} of {totalSize}
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          className={classNames(
            'pl-2 pr-1 text-lg font-semibold text-gray-950',
            {
              '!text-gray-400': currentPage === 1,
            },
          )}
          disabled={currentPage === 1}
        >
          {'<'}
        </button>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          className={classNames('text-lg font-semibold text-gray-950', {
            '!text-gray-400': currentPage === totalPages,
          })}
          disabled={currentPage === totalPages}
        >
          {'>'}
        </button>
      </div>
    </div>
  );
};

export default Pagination;
