import React from 'react';
import { FieldError } from 'react-hook-form';
import classNames from 'classnames';
import { ErrorComponent } from 'pages/Orders/OrderForm/UtilComponents';

import { OptionalLabelIndicator } from './OptionalLabelIndicator';

interface ITextArea {
  label: string;
  name: string;
  placeholder: string;
  value: string;
  description?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...event: any[]) => void;
  error: FieldError | undefined;
  optional?: boolean;
}

export const TextArea: React.FC<ITextArea> = ({
  label,
  value,
  name,
  onChange,
  placeholder,
  error,
  description,
  optional = false,
}) => {
  return (
    <div className='relative'>
      <div className='flex justify-between'>
        <label
          htmlFor='comment'
          className='block text-sm font-medium leading-6 text-gray-900 truncate'
        >
          {label}
        </label>
        {optional ? <OptionalLabelIndicator /> : null}
      </div>
      {description ? (
        <p className='text-sm text-gray-500 mb-1'>{description}</p>
      ) : null}
      <div>
        <textarea
          rows={4}
          placeholder={placeholder}
          onChange={onChange}
          name={name}
          id='comment'
          className={classNames(
            'relative block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset !p-3 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus-visible:outline-green-950 sm:text-sm sm:leading-6',
            {
              '!ring-red-300': !!error?.message,
            },
          )}
          value={value}
        />
        <ErrorComponent msg={error?.message} id={`${name}-error`} />
      </div>
    </div>
  );
};
