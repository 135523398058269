export const TimeZoneClasses =
  'absolute sm:left-[5.7rem] lg:left-[6.3rem] text-sm font-light md:hidden lg:block';

export enum HeaderConstants {
  ACCOUNT_ID = 'x-account-id',
}

export enum STEPS_STATUS {
  COMPLETE = 'COMPLETE',
  CURRENT = 'CURRENT',
  UPCOMING = 'UPCOMING',
}

export enum PAYMENT_METHOD_OPTIONS {
  CREDIT_CARD = 'CREDIT_CARD',
  OTHER = 'OTHER',
}

export enum PolarSelect {
  YES = 'YES',
  NO = 'NO',
}
