/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { FieldError } from 'react-hook-form';
import classNames from 'classnames';

import './styles.css';

interface QuantityFieldPropTypes {
  disabled?: boolean;
  className?: string;
  containerClassName?: string;
  label?: string;
  name: string;
  placeholder?: string;
  value: string | number | undefined;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onDecrement: () => void;
  onIncrement: () => void;
  error: FieldError | undefined;
}

const QuantityField: React.FC<QuantityFieldPropTypes> = ({
  label,
  name,
  placeholder,
  value,
  error,
  onChange,
  onDecrement,
  onIncrement,
  className = '',
  containerClassName = '',
  disabled = false,
}) => {
  return (
    <div className={classNames(containerClassName)}>
      {label ? (
        <label
          htmlFor='common-input'
          className='block text-sm font-medium leading-6 text-gray-500'
        >
          {label}
        </label>
      ) : null}
      <div className='flex items-center border border-gray-300 rounded-xl p-1 gap-1'>
        <button
          id='decrement'
          className=' text-gray-400'
          onClick={onDecrement}
          type='button'
        >
          -
        </button>
        <input
          id='quantity'
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          min='1'
          onChange={onChange}
          value={value}
          className={classNames(
            'text-center text-gray-950 text-sm font-medium focus-visible:outline-none w-5 focus:spin',
            className,
          )}
        />
        <button
          id='increment'
          className=' text-gray-400'
          onClick={onIncrement}
          type='button'
        >
          +
        </button>
      </div>
      {error?.message ? (
        <p className='mt-1 ml-1 text-xs text-red-600' id='email-error'>
          {error.message}
        </p>
      ) : null}
    </div>
  );
};

export default QuantityField;
