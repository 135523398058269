import React from 'react';
import Button from 'components/Buttons/Index';

interface IOrderModalFooter {
  disabledNextButton?: boolean;
  submitButton?: boolean;
  isLoading?: boolean;
  goBack?: () => void;
  onHold?: () => void;
  goNext?: () => void;
  onClose?: () => void;
}

const OrderModalFooter: React.FC<IOrderModalFooter> = ({
  disabledNextButton = false,
  submitButton,
  isLoading,
  goBack,
  onHold,
  goNext,
  onClose,
}) => {
  return (
    <div className='mt-5 flex gap-3 justify-end rounded-2xl shadow-[0_4px_16px_0px_rgb(0,0,0,0.25)] m-2 p-2'>
      {goBack ? (
        <Button
          className='!border-gray-300 !text-gray-950 mr-2 !px-12 rounded-xl'
          onClick={goBack}
          variant='outlined'
          label='Back'
          type='button'
          disabled={isLoading}
        />
      ) : null}
      {onHold ? (
        <Button
          className='!border-gray-300 !text-gray-950 mr-2 !px-12 rounded-xl'
          onClick={onHold}
          variant='outlined'
          label='On Hold'
          type='button'
          disabled={isLoading}
        />
      ) : null}
      {onClose ? (
        <Button
          className='!border-red-500 !text-red-500 mr-2 !px-12 rounded-xl'
          variant='outlined'
          onClick={onClose}
          label='Close'
          type='button'
          disabled={isLoading}
        />
      ) : null}
      {goNext ? (
        <Button
          disabled={disabledNextButton}
          className='!bg-green-950 !px-16 rounded-xl'
          onClick={goNext}
          label='Next'
          type='button'
        />
      ) : null}
      {submitButton ? (
        <Button
          className='!bg-green-950 !px-14 rounded-xl'
          label='Submit'
          type='submit'
          disabled={isLoading}
          loading={isLoading}
        />
      ) : null}
    </div>
  );
};

export default OrderModalFooter;
