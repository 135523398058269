import { AVOrderType } from 'constants/orders';
import { AVOrderFormField } from 'types/av-form-fields';
import { AVOrder } from 'types/av-orders';
import { AV_ORDER_STATUS } from 'utils/common.enum';

const OrderStatusesMergedInBooked = [
  AV_ORDER_STATUS.BOOKED,
  AV_ORDER_STATUS.SUBMITTED,
  AV_ORDER_STATUS.IN_REVIEW,
  AV_ORDER_STATUS.VENDOR_SELECTION,
];

const NOT_AVAILABLE = 'n/a';

const isValueMatched = (order: AVOrder, searchQuery: string) => {
  return (
    order.orderId.toLowerCase().includes(searchQuery) ||
    order.endClient?.name?.toLowerCase().includes(searchQuery) ||
    order.enteredBy?.firstName?.toLowerCase().includes(searchQuery) ||
    order.enteredBy?.lastName?.toLowerCase().includes(searchQuery) ||
    order.speakerName?.toLowerCase()?.includes(searchQuery) ||
    order.orderStatus.toLowerCase().includes(searchQuery) ||
    order.venue?.name?.toLowerCase()?.includes(searchQuery) ||
    (order.type === AVOrderType.WEB_CONF && 'web conf'.includes(searchQuery))
  );
};

export const isQueryMatched = (
  order: AVOrder,
  dashboardFormFields: AVOrderFormField[],
  searchQuery: string,
) => {
  if (isValueMatched(order, searchQuery)) {
    return true;
  }

  if (
    // this condition is to handle the search for 'booked' status
    AV_ORDER_STATUS.BOOKED.toLowerCase().includes(searchQuery) &&
    OrderStatusesMergedInBooked.includes(order.orderStatus)
  ) {
    return true;
  }

  const meta = order?.metadata ?? {};
  return dashboardFormFields?.some((val) => {
    if (!meta?.[val.metadataKey]) {
      // to handle the search for 'n/a' status
      return NOT_AVAILABLE.includes(searchQuery);
    }
    return meta[val.metadataKey].toLowerCase().includes(searchQuery);
  });
};
