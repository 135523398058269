import React, { useMemo } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import Input from 'components/Inputs';
import Select from 'components/Inputs/Select';
import { PolarSelect } from 'constants/common';
import { AVCustomer } from 'types/av-customer';
import { AVOrderFormField } from 'types/av-form-fields';
import {
  AVOrder,
  CreateAVOrder,
  EndClient,
  ProgramManagerData,
} from 'types/av-orders';
import { convertEnumToString } from 'utils/avUtils';
import { AV_ORDER_STATUS } from 'utils/common.enum';

import { OrderDynamicForm } from './OrderDynamicForm';

interface IOrderBasicForm {
  isLoading: boolean;
  endClients: EndClient[];
  control: Control<CreateAVOrder>;
  editOrder: AVOrder | undefined;
  currentUser: AVCustomer | null;
  dynamicFormFields: AVOrderFormField[];
  isWebConf?: boolean;
  keysToCopy?: string[];
  programManagerData?: ProgramManagerData | undefined;
  setValue: UseFormSetValue<CreateAVOrder>;
}

const OrderBasicForm: React.FC<IOrderBasicForm> = ({
  control,
  isLoading,
  editOrder,
  endClients,
  currentUser,
  dynamicFormFields,
  isWebConf = false,
  keysToCopy,
  programManagerData,
  setValue,
}) => {
  const editUser = editOrder?.enteredBy as unknown as AVCustomer;

  const programManagers = useMemo(
    () => programManagerData?.programManagers ?? [],
    [programManagerData],
  );

  return (
    <>
      <div className='flex items-center w-full gap-2'>
        <div className='w-1/3'>
          <Controller
            control={control}
            name='orderId'
            rules={{ required: 'Order Id is required' }}
            render={({ fieldState: { error }, field }) => (
              <Input
                disabled
                placeholder='Order ID'
                type='text'
                label='Order ID'
                value={field.value}
                onChange={field.onChange}
                error={error}
                name={field.name}
              />
            )}
          />
        </div>
        <div className='w-1/3'>
          <Controller
            control={control}
            name='enteredBy'
            rules={{ required: 'Entered By is required' }}
            render={({ fieldState: { error }, field }) => (
              <>
                <Input
                  disabled
                  label='Entered By'
                  value={
                    editOrder
                      ? `${editUser?.firstName} ${editUser?.lastName}`
                      : `${currentUser?.firstName} ${currentUser?.lastName}`
                  }
                  onChange={field.onChange}
                  error={error}
                  name='ToPreview'
                  placeholder='Enter By'
                  type='text'
                />

                <Input
                  disabled
                  label='Entered By'
                  value={field.value}
                  onChange={field.onChange}
                  error={error}
                  name={field.name}
                  placeholder='Enter By'
                  type='hidden'
                />
              </>
            )}
          />
        </div>
        <div className='w-1/3'>
          <Controller
            control={control}
            name='endClient'
            rules={{ required: true }}
            render={({ fieldState: { error }, field }) => (
              <Select
                error={error}
                name={field.name}
                value={field.value}
                disabled={
                  (!!editOrder?._id &&
                    editOrder.orderStatus !== AV_ORDER_STATUS.ON_HOLD) ||
                  keysToCopy?.includes('lineItems')
                }
                label='End Client'
                onChange={field.onChange}
                options={endClients.map((endClient) => ({
                  key: endClient._id,
                  label: endClient.name,
                }))}
              />
            )}
          />
        </div>
      </div>

      <div className='flex mt-4 items-center gap-2'>
        <div className='w-1/3'>
          <Controller
            control={control}
            name='attendeeCount'
            rules={{ required: 'Attendee Count is required' }}
            render={({ fieldState: { error }, field }) => (
              <Input
                type='number'
                label='Attendee Count'
                value={field.value}
                onChange={field.onChange}
                error={error}
                name={field.name}
                placeholder='Enter Attendee Count'
              />
            )}
          />
        </div>

        <div className='w-1/3'>
          <Controller
            control={control}
            name='speakerName'
            rules={{ required: true }}
            render={({ fieldState: { error }, field }) => (
              <Input
                type='text'
                label='Speaker Name'
                value={field.value}
                onChange={field.onChange}
                error={error}
                name={field.name}
                placeholder='Enter Speaker Name'
              />
            )}
          />
        </div>

        {programManagerData?.enableProgramManager && !isWebConf ? (
          <div className='w-[34%]'>
            <Controller
              control={control}
              name='programManager'
              rules={{ required: true }}
              render={({ fieldState: { error }, field }) => (
                <Select
                  error={error}
                  name={field.name}
                  value={field.value ? field.value : ''}
                  label='Program Manager'
                  onChange={field.onChange}
                  options={programManagers?.map(
                    ({ firstName, lastName, _id }) => ({
                      key: _id,
                      label: `${firstName} ${lastName}`,
                    }),
                  )}
                />
              )}
            />
          </div>
        ) : null}
      </div>

      {isWebConf ? (
        <div className='w-full mt-4'>
          <Controller
            control={control}
            name='hasAdditionalVenues'
            rules={{ required: true }}
            render={({ fieldState: { error }, field }) => (
              <Select
                error={error}
                name={field.name}
                value={(field.value as string) ?? ''}
                label='Will there be additional venues with live attendees & onsite AV needs?'
                onChange={field.onChange}
                options={Object.values(PolarSelect).map((opt) => ({
                  key: opt,
                  label: convertEnumToString(opt),
                }))}
              />
            )}
          />
        </div>
      ) : null}

      <OrderDynamicForm
        setValue={setValue}
        isLoading={isLoading}
        dynamicFields={dynamicFormFields}
        control={control}
        isWebConf={isWebConf}
      />
    </>
  );
};

export default OrderBasicForm;
