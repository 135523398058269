export const Regex = {
  EMAIL: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^&*-]).{8,}$/,
  URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  NUMERIC: /^[0-9]*$/,
  LETTERS: /^[a-zA-Z\s]*$/,
  ALPHANUMERIC: /^[a-zA-Z0-9 ]*$/,
  CHECK_CAPITAL_LETTERS: /(?<!^)[A-Z]/g,
  VALID_ZIP: /^\d{5}$/,
  PHONE_NUMBER: /^[0-9\s()+-.]+$/,
  LOWER_CASE: /[a-z]/,
  UPPER_CASE: /[A-Z]/,
  INTEGER: /\d/,
  PASSWORD_SYMBOLS: /[#?!@$%^&*-]/,
};
