import { Fragment } from 'react';
import classNames from 'classnames';

import { Menu as HeadlessMenu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

interface IMenu {
  disabled: boolean;
  items: {
    title: string;
    icon?: JSX.Element;
    onClick: (() => void) | undefined;
  }[];
  selectedValue?: string;
}

const Menu = ({ disabled, items, selectedValue }: IMenu) => {
  return (
    <div>
      <HeadlessMenu as='div' className='relative inline-block text-left'>
        <HeadlessMenu.Button disabled={disabled}>
          <div className='flex items-center px-4 py-2 border rounded-lg gap-1 text-sm text-gray-950'>
            {selectedValue}{' '}
            <ChevronDownIcon
              className='-mr-1 ml-2 h-5 w-5 text-gray-950'
              aria-hidden='true'
            />
          </div>
        </HeadlessMenu.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <HeadlessMenu.Items className='absolute left-0 -top-24 w-fit z-10 p-2 rounded-lg origin-top-right divide-y divide-gray-100 bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
            <div className='px-1 py-1 '>
              {items.map((item) =>
                item.onClick ? (
                  <HeadlessMenu.Item key={item.title}>
                    {({ active }) => (
                      <button
                        className={classNames(
                          'group flex w-full items-center rounded-md px-2 py-2 text-sm text-gray-900 gap-2',
                          {
                            'bg-gray-100': active,
                          },
                        )}
                        onClick={item.onClick}
                      >
                        {item.icon}
                        {item.title}
                      </button>
                    )}
                  </HeadlessMenu.Item>
                ) : null,
              )}
            </div>
          </HeadlessMenu.Items>
        </Transition>
      </HeadlessMenu>
    </div>
  );
};

export default Menu;
