/* eslint-disable @typescript-eslint/no-explicit-any */
import { DateType } from 'react-tailwindcss-datepicker';
import { AVOrderType, WEBCONF_BASE_PRICE } from 'constants/orders';
import { API_ROUTES } from 'constants/routes';
import { DEFAULT_TIMEZONE } from 'constants/timezone';
import dayjs from 'dayjs';
import * as ExcelJS from 'exceljs';
import { CustomerBase } from 'types/av-customer';
import { AVOrder } from 'types/av-orders';
import {
  calculateWebConfOrderPrices,
  convertEnumToString,
} from 'utils/avUtils';
import { AV_CUSTOMER_ORDER_STATUS, AV_ORDER_STATUS } from 'utils/common.enum';
import { timezonedDayjs } from 'utils/dayjs';

import { API } from './api';

export const downloadPDF = async (order: AVOrder) => {
  if (!order?._id) return;
  const { data } = await API.get(
    `${API_ROUTES.AV_CUSTOMER}${API_ROUTES.AV_ORDER_INVOICE}/${order._id}`,
    { responseType: 'blob' },
  );

  const blob = new Blob([data]);
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${order?.orderId.replace('ORD', 'INV')}.pdf`);
  document.body.appendChild(link);
  link.click();
};

export const downloadExcel = async (data: AVOrder[], fileName: string) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet 1');

  const headers: any = [];
  const headerKeys = [
    'Order Id',
    'Created At',
    'Order Type',
    'Entered By',
    'End Client',
    'Attendee Count',
    'Speaker Name',
    'Venue Name',
    'Venue Address',
    'Venue City',
    'Venue State',
    'Venue Zip',
    'Venue Room Name',
    'Venue Reservation Name',
    'Local Time Zone',
    'Local Start Date',
    'Local Start Time',
    'Local End Date',
    'Local End Time',
    'Local Setup Time',
    'Venue Contact Name',
    'Venue Contact Phone',
    'On Site Contact Name',
    'On Site Contact Phone',
    'Line Items',
    'Invoice Total',
    'Invoice Status',
    'Order Status',
    'Comments',
  ];

  headerKeys.forEach((key) => {
    headers.push({
      header: key,
      key: key,
      width: 30,
    });
  });

  const metadataHeaderKeys = new Set();
  data.forEach((order) => {
    if (order.metadata) {
      Object.keys(order.metadata).forEach((key) => {
        metadataHeaderKeys.add(key);
      });
    }
  });

  let metadataHeaders: any[] = [];
  const metadataKeysLabelMap: Record<string, string> = {};
  if (metadataHeaderKeys.size > 0) {
    const metadataHeaderArray = Array.from(metadataHeaderKeys);
    const { data } = await API.get(
      `${API_ROUTES.AV_CUSTOMER}${
        API_ROUTES.METADATA_KEYS_LABELS
      }?metadata-keys=${metadataHeaderArray.join(',')}`,
    );

    if (data) {
      Object.entries(data.data).forEach(([key, value]) => {
        metadataKeysLabelMap[key as string] = (value as any).includes(
          'Slide Deck',
        )
          ? 'Slide Deck'
          : (value as string);
      });
    }
    metadataHeaders = metadataHeaderArray
      .map((key) => ({
        header: metadataKeysLabelMap[key as string],
        key: key,
        width: 30,
      }))
      .filter((header) => header.header && header.header !== 'metadata');

    // make sure the metadata headers are unique
    metadataHeaders.forEach((header) => {
      const found = headers.find(
        (h: { header: any }) => h.header === header.header,
      );
      if (!found) {
        headers.push(header);
      }
    });
  }

  worksheet.columns = headers;

  let nextRow = 2;
  for (let i = 0; i < data.length; i++) {
    if (!data[i]) {
      continue;
    }

    const row = worksheet.getRow(nextRow);
    row.getCell(1).value = data[i]?.orderId;
    row.getCell(2).value = dayjs(data[i]?.createdAt).format('MM/DD/YYYY');
    row.getCell(3).value = convertEnumToString(data[i]?.type as string);
    row.getCell(
      4,
    ).value = `${data[i]?.enteredBy.firstName} ${data[i]?.enteredBy.lastName}`;
    row.getCell(5).value = data[i]?.endClient.name;
    row.getCell(6).value = data[i]?.attendeeCount;
    row.getCell(7).value = data[i]?.speakerName;

    let startDate = '';
    let endDate = '';
    let startTime = '';
    let endTime = '';
    let setupTime = '';

    if (data[i]?.startDate) {
      startDate = dayjs(data[i]?.startDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      if (data[i]?.startTime) {
        const startTimeArr = data[i]?.startTime.toString().split(':') as any;
        startTime = dayjs(data[i]?.startDate, 'YYYY-MM-DD')
          .set('hour', Number(startTimeArr[0]))
          .set('minute', Number(startTimeArr[1]))
          .format('hh:mm a');
      }
    }

    if (data[i]?.endDate) {
      endDate = dayjs(data[i]?.endDate, 'YYYY-MM-DD').format('MM/DD/YYYY');

      if (data[i]?.endTime) {
        const endTimeArr = data[i]?.endTime.toString().split(':') as any;
        endTime = dayjs(data[i]?.endDate, 'YYYY-MM-DD')
          .set('hour', Number(endTimeArr[0]))
          .set('minute', Number(endTimeArr[1]))
          .format('hh:mm a');
      }
    }

    if (data[i]?.setupTime) {
      const setupTimeArr = data[i]?.setupTime?.toString?.().split(':') as any;
      setupTime = setupTimeArr
        ? dayjs(data[i]?.startDate, 'YYYY-MM-DD')
            .set('hour', Number(setupTimeArr[0]))
            .set('minute', Number(setupTimeArr[1]))
            .format('hh:mm a')
        : '';
    }

    row.getCell(8).value = data[i]?.venue?.name;
    row.getCell(9).value = data[i]?.venue?.address;
    row.getCell(10).value = data[i]?.venue?.city;
    row.getCell(11).value = (data[i]?.venue?.state as any)?.name;
    row.getCell(12).value = data[i]?.venue?.zip;
    row.getCell(13).value = data[i]?.venue?.roomName;
    row.getCell(14).value = data[i]?.venue?.reservationName;
    row.getCell(15).value = data[i]?.localTimeZone;
    row.getCell(16).value = startDate;
    row.getCell(17).value = startTime;
    row.getCell(18).value = endDate;
    row.getCell(19).value = endTime;
    row.getCell(20).value = setupTime;
    row.getCell(21).value = data[i]?.venue?.venueContact?.name;
    row.getCell(22).value = data[i]?.venue?.venueContact?.phone;

    row.getCell(23).value = data[i]?.onSiteContact?.name;
    row.getCell(24).value = data[i]?.onSiteContact?.phone;

    let lineItemsStr = '';
    let invoiceTotal = 0;
    let itemsTotal = 0;
    if (data[i]?.type === AVOrderType.AV_RENTAL) {
      data[i]?.lineItems.forEach((lineItem) => {
        if (lineItem.bundle) {
          const bundleName = `${lineItem.quantity} x ${
            lineItem.bundle?.bundle.name
          } $ ${(lineItem.price * lineItem.quantity).toFixed(2)}`;
          const bundleItems =
            lineItem.bundle?.bundle.items
              .map((item) => `\t- ${item.name}`)
              .join('\r\n ') || '';
          lineItemsStr += `${bundleName}\r\n${bundleItems}\r\n`;
        } else {
          lineItemsStr += `${lineItem.quantity} x ${
            lineItem.item?.item.name
          } $${lineItem.price * lineItem.quantity}\r\n`;
        }
        itemsTotal += lineItem.price * lineItem.quantity;
      });
    } else {
      const order = data[i];
      const orderBasePrice = order?.basePrice || WEBCONF_BASE_PRICE;
      lineItemsStr += `Base Price $${orderBasePrice.toFixed(2)}\r\n`;
      itemsTotal += orderBasePrice;
      if (order?.attendeeCount && order?.startTime && order?.endTime) {
        const {
          additionalAttendeesPrice,
          additionalAttendees,
          additionalDuration,
          additionalDurationPrice,
        } = calculateWebConfOrderPrices(
          order.basePrice || WEBCONF_BASE_PRICE,
          order.attendeeCount,
          order.startDate,
          order.startTime ?? '',
          order.endDate,
          order.endTime ?? '',
        );
        lineItemsStr += `Additional Duration $${additionalDurationPrice.toFixed(
          2,
        )} (${additionalDuration} hours)\r\n`;
        lineItemsStr += `Additional Attendees $${additionalAttendeesPrice.toFixed(
          2,
        )} (${additionalAttendees} attendees)\r\n`;
        itemsTotal += additionalAttendeesPrice + additionalDurationPrice;
      }
    }
    if (data[i]?.discount) {
      lineItemsStr += `Discount $${data[i]?.discount}\r\n`;
      itemsTotal -= data[i]?.discount ?? 0;
    }

    invoiceTotal += itemsTotal;

    if (data[i]?.rushFee) {
      lineItemsStr += `Rush Fee $${data[i]?.rushFee}\r\n`;
      invoiceTotal += data[i]?.rushFee || 0;
    }
    if (data[i]?.taxRate) {
      const taxRate = data[i]?.taxRate || 0;
      const tax = itemsTotal * taxRate;
      lineItemsStr += `Tax(${(taxRate * 100).toFixed(2)}%) $${tax.toFixed(
        2,
      )}\r\n`;
      invoiceTotal += tax;
    }
    row.getCell(25).value = lineItemsStr;
    row.getCell(25).alignment = { wrapText: true };
    row.getCell(26).value = invoiceTotal ? invoiceTotal.toFixed(2) : 0;
    row.getCell(27).value = data[i]?.invoiceStatus;

    let orderStatus = AV_CUSTOMER_ORDER_STATUS.Booked;
    switch (data[i]?.orderStatus) {
      case AV_ORDER_STATUS.ON_HOLD:
        orderStatus = AV_CUSTOMER_ORDER_STATUS.OnHold;
        break;
      case AV_ORDER_STATUS.CANCELLED:
        orderStatus = AV_CUSTOMER_ORDER_STATUS.Cancelled;
        break;
      case AV_ORDER_STATUS.COMPLETED:
        orderStatus = AV_CUSTOMER_ORDER_STATUS.Completed;
        break;
      case AV_ORDER_STATUS.BILLING_ERROR:
        orderStatus = AV_CUSTOMER_ORDER_STATUS.BillingError;
        break;

      default:
        break;
    }

    row.getCell(28).value = orderStatus;
    row.getCell(29).value = data[i]?.comments;

    const orderMetadataKeys = Object.keys(data[i]?.metadata || {});
    metadataHeaders.forEach((header) => {
      // check if the header key is in the order metadata keys
      if (orderMetadataKeys.includes(header.key)) {
        // find the header in the headers array
        const found = headers.find(
          (h: { header: any }) => h.header === header.header,
        );
        if (found) {
          row.getCell(found.key).value = data[i]?.metadata?.[header.key] ?? '';
        }
      }
    });

    row.commit();
    nextRow++;
  }

  const row = worksheet.getRow(1);
  row.eachCell((cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFD9D9D9' },
    };
    cell.font = {
      color: { argb: 'FF000000' },
      bold: true,
      size: 12,
    };
  });
  row.commit();

  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 10000,
      height: 20000,
      firstSheet: 0,
      activeTab: 1,
      visibility: 'visible',
    },
  ];

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: 'application/octet-stream' });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  });
};

export const msgBasedOnCancellationPolicy = (
  beforeMsg: string,
  withInMsg: string,
  customer: CustomerBase | null,
  order?: AVOrder,
  userTz?: string | undefined,
) => {
  let msg = beforeMsg;
  if (customer?.enableAvPolicies) {
    const { daysPrior, at, tz } = customer.cancellationPolicy;
    const now = timezonedDayjs(userTz || DEFAULT_TIMEZONE);
    const eventStartTime = dayjs(order?.startDate, 'YYYY-MM-DD');
    const timeArr = at.split(':');
    const cancellationCutOff = timezonedDayjs(tz, eventStartTime)
      .subtract(daysPrior, 'day')
      .set('hour', Number(timeArr[0] || 0))
      .set('minute', Number(timeArr[1] || 0));
    if (now.isAfter(cancellationCutOff)) {
      msg = withInMsg;
    }
  }
  return msg;
};

export const isDateSmallerThanCurrent = (
  date?: DateType,
  comparingDate?: DateType,
) => {
  const inputDate = dayjs(date);
  const currentDate = comparingDate ? dayjs(comparingDate) : dayjs();

  return inputDate.isBefore(currentDate);
};
