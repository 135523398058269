import React from 'react';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import OrderBasicForm from 'pages/Orders/OrderForm/OrderBasicForm';
import { MainHeading } from 'pages/Orders/OrderForm/UtilComponents';
import { AVCustomer } from 'types/av-customer';
import { AVOrderFormField } from 'types/av-form-fields';
import {
  AVOrder,
  CreateAVOrder,
  EndClient,
  GoogleTimeZone,
  State,
} from 'types/av-orders';

import WebConfEventDetailForm from './WebConfEventDetailForm';
import WebConfOrderContactForm from './WebConfOrderContactForm';
import WebConfOrderDateTimeForm from './WebConfOrderDateTimeForm';

interface IWebConfOrderForm {
  states: State[];
  endClients: EndClient[];
  formValues: CreateAVOrder;
  dynamicFieldsLoader: boolean;
  currentUser: AVCustomer | null;
  editOrder: AVOrder | undefined;
  control: Control<CreateAVOrder>;
  contactFields: AVOrderFormField[];
  eventDetailFields: AVOrderFormField[];
  eventProfileFields: AVOrderFormField[];
  googleTimezone: GoogleTimeZone | null | undefined;
  setValue: UseFormSetValue<CreateAVOrder>;
  watch: UseFormWatch<CreateAVOrder>;
  isWithinCancellationPolicy: boolean;
}

const WebConfOrderForm: React.FC<IWebConfOrderForm> = ({
  editOrder,
  control,
  endClients,
  formValues,
  currentUser,
  googleTimezone,
  contactFields,
  eventDetailFields,
  eventProfileFields,
  dynamicFieldsLoader,
  setValue,
  watch,
  isWithinCancellationPolicy,
}) => {
  return (
    <div className='flex-[0.6] overflow-y-auto'>
      <h3 className='text-2xl font-bold mb-3'>
        {editOrder ? 'Edit Web Conf Order' : 'New Web Conf Order'}
      </h3>

      <hr className='mb-3' />
      <MainHeading title='Event Profile' classNames='mb-1' />
      <OrderBasicForm
        isWebConf
        setValue={setValue}
        control={control}
        editOrder={editOrder}
        endClients={endClients}
        currentUser={currentUser}
        isLoading={dynamicFieldsLoader}
        dynamicFormFields={eventProfileFields}
      />

      <MainHeading title='Date & Time' classNames='mt-8' />
      <WebConfOrderDateTimeForm
        editOrder={editOrder}
        formValues={formValues}
        googleTimezone={googleTimezone}
        setValue={setValue}
        control={control}
        watch={watch}
        isWithinCancellationPolicy={isWithinCancellationPolicy}
      />

      <MainHeading title='Primary Contact' classNames='mt-8' />
      <WebConfOrderContactForm
        control={control}
        setValue={setValue}
        isLoading={dynamicFieldsLoader}
        contactDynamicFields={contactFields}
      />

      <MainHeading title='Event Details' classNames='mt-8' />
      <WebConfEventDetailForm
        setValue={setValue}
        isLoading={dynamicFieldsLoader}
        eventDetailFields={eventDetailFields}
        control={control}
      />
    </div>
  );
};

export default WebConfOrderForm;
