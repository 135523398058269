import { STEPS_STATUS } from './common';

export enum AVOrderType {
  AV_RENTAL = 'AV_RENTAL',
  WEB_CONF = 'WEB_CONF',
}

export const ORDER_STEPS = [
  { id: 1, name: 'Event Info', status: STEPS_STATUS.CURRENT },
  { id: 2, name: 'Product Selection', status: STEPS_STATUS.UPCOMING },
  { id: 3, name: 'Checkout', status: STEPS_STATUS.UPCOMING },
];

export const ORDER_ALERT_MESSAGES = {
  ON_HOLD:
    'By putting your order on hold your progress will be saved. You will be able access it and resume from the home page.',
  CLOSE:
    'Are you sure you want to close this order without saving? This action cannot be undone, and you will lose all progress.',
  FORM_VALIDATION_ERROR: 'Please fill in required fields before proceeding',
  WITH_IN_WINDOW:
    'This order is within the cancellation policy window and will be invoiced in full if you proceed. Please click confirm to submit the cancellation.',
  BEFORE_WINDOW:
    'You are about to cancel an order that has already been processed. Please click confirm to submit the cancellation.',
  CANCELLED_SUCCESS_WITH_IN_WINDOW:
    'Your order has been cancelled. You are within your cancellation window and will be charged in full for this order.',
  CANCELLED_SUCCESS_BEFORE_WINDOW:
    'Your order has been cancelled.  You will not be charged for this order.',
  RUSH_ORDER:
    'The meeting date is within the Rush Order Policy window. Please proceed with placing the order and call 888-628-3678 to alert your Program Manager. Rush order fee will be automatically applied.',
  CARD_DECLINED:
    'Your card was declined. Please check the details and try again.',
  CARD_DETAILS_REQUIRED: 'Please enter valid credit card details.',
  BAD_ZIP_STATE: 'Zip code is not valid for state',
  INCOMPLETE_ADDRESS: 'Please select a complete address',
};

export const WEBCONF_BASE_PRICE = 1375;
export const WEBCONF_HOURS_INCLUDED = 1;
export const WEBCONF_ADDIONAL_DURATION_PRICE = 250; // per 30 minutes
export const WEBCONF_ATTENDEES_INCLUDED = 100;
export const WEBCONF_ADDIONAL_ATTENDEE_PRICE = 2; // per attendee
export const WEBCONF_TELECONF_MINUTE_PRICE = 0.15;
