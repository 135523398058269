import { ConditionalOperators } from 'constants/order-form';
import { FormFieldCondition, GenericObject } from 'types/av-form-fields';

export const renderConditionalInput = (
  condition: FormFieldCondition | undefined,
  enableCondition: boolean,
  metadata: GenericObject,
) => {
  if (!enableCondition) return false;

  if (condition?.operator === ConditionalOperators.EQUALS) {
    if (condition && condition.value !== metadata[condition.field]) {
      return true;
    }
  } else if (condition?.operator === ConditionalOperators.NOT_EQUALS) {
    if (condition && condition.value === metadata[condition.field]) {
      return true;
    }
  }

  return false;
};
