import { LOCAL_CONSTANT } from 'constants/auth';
import { API } from 'services/api';

const PREVENT_RELOAD_ROUTES = ['verify-reset-password', 'av-customer/login'];

export const useInterceptor = () => {
  API.interceptors.request.use((config) => {
    if (config.url?.includes('av-customer/reset-password')) {
      return config;
    }
    const token = localStorage.getItem(LOCAL_CONSTANT.USER_TOKEN);
    if (config?.headers) {
      config.headers.Authorization = token ? `Bearer ${token}` : '';
    }
    return config;
  });

  // TODO: Handle errors and success and set toast messages
  API.interceptors.response.use(
    (response) => response,
    (error) => {
      switch (error.response?.status) {
        case 401: {
          const isReloadRequired = !PREVENT_RELOAD_ROUTES.some((route) =>
            error.config.url.includes(route),
          );

          if (isReloadRequired) {
            localStorage.clear();
            localStorage.setItem(
              'sessionMessage',
              'Your session has expired. Please sign-in again.',
            );
            window.location.href = '/';
          }
          return Promise.reject(error);
        }
        case 403: {
          return Promise.reject(error);
        }
        default: {
          return Promise.reject(error);
        }
      }
    },
  );
};
