/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback, useState } from 'react';
import {
  Control,
  Controller,
  ControllerRenderProps,
  UseFormSetValue,
} from 'react-hook-form';
import AlertMessage from 'components/AlertMessage';
import Checkbox from 'components/Inputs/Checkbox';
import QuantityField from 'components/Inputs/QuantityField';
import Loader from 'components/Loaders';
import {
  MainHeading,
  SubCategoryHeading,
} from 'pages/Orders/OrderForm/UtilComponents';
import { CreateAVOrder } from 'types/av-orders';
import { AvailableBundles, AvailableItems } from 'types/item-bundle';
import { convertEnumToString } from 'utils/avUtils';
import { formatCurrency } from 'utils/helper';

type CreateEndClientItemsProps = {
  bundles: AvailableBundles[];
  items: AvailableItems[];
  control: Control<CreateAVOrder>;
  setValue: UseFormSetValue<any>;
  isLoading?: boolean;
  formValues?: CreateAVOrder;
};
type QuantitySectionProps = {
  control: Control<CreateAVOrder>;
  price: number;
  name: any;
  disabled?: boolean;
};

const QuantitySection = ({
  control,
  price,
  name,
  disabled,
}: QuantitySectionProps) => {
  const onChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: ControllerRenderProps<CreateAVOrder, any>,
  ) => {
    const val = e.target.value;
    if (!disabled && /^[1-9][0-9]*$/.test(val)) {
      field.onChange(val);
    }
  };

  return (
    <div className='flex items-center flex-[0.3] justify-between'>
      <Controller
        control={control}
        name={name}
        disabled={disabled}
        render={({ fieldState: { error }, field }) => {
          return (
            <QuantityField
              placeholder=''
              name={field.name}
              label=''
              value={field.value}
              onChange={(e) => onChange(e, field)}
              onIncrement={() =>
                !disabled ? field.onChange((+field.value || 0) + 1) : null
              }
              onDecrement={() => {
                if (!disabled) {
                  const value = +field.value - 1;
                  field.onChange(value > 0 ? value : 1);
                }
              }}
              error={error}
            />
          );
        }}
      />
      <p className='font-medium text-lg text-gray-700 ml-1'>
        {formatCurrency(price)}
      </p>
    </div>
  );
};

export const ItemBundleForm: FC<CreateEndClientItemsProps> = ({
  bundles,
  items,
  control,
  setValue,
  isLoading = false,
  formValues,
}) => {
  const [openAlert, setOpenAlert] = useState(false);

  const handleBundleChange = useCallback(
    (bundle: AvailableBundles, checked: boolean, idx: number) => {
      const value = bundle._id;
      const isAlreadySelected = !!formValues?.lineItems?.bundles?.find(
        (bundle) => bundle.bundle === value,
      );
      const hasSelectedBundle = formValues?.lineItems?.bundles?.some(
        (bundle) => !!bundle.bundle,
      );
      if (hasSelectedBundle && !isAlreadySelected) {
        setOpenAlert(true);
        return;
      }
      setValue(`lineItems.bundles.${idx}`, {
        bundle: checked ? value : undefined,
        price: checked ? bundle.price : undefined,
      });
      setValue(`lineItems.bundles.${idx}.quantity`, checked ? 1 : 0);
    },
    [formValues, setValue],
  );

  const handleItemChange = useCallback(
    (item: AvailableItems, checked: boolean, idx: number) => {
      setValue(`lineItems.items.${idx}`, {
        item: checked ? item?._id : undefined,
        price: checked ? item.price : undefined,
      });
      setValue(`lineItems.items.${idx}.quantity`, checked ? 1 : 0);
    },
    [setValue],
  );

  const renderItems = () => {
    const itemsList = items.filter((item) => item.item).map((item) => item);
    const groupedItems = itemsList.reduce((acc, item) => {
      const category = acc[item.item.category] || {};
      const idx = item.item.subCategory || 0;
      const subCategory = category[idx] || [];
      subCategory.push(item);
      category[idx] = subCategory;
      acc[item.item.category] = category;
      return acc;
    }, {} as Record<string, Record<string, AvailableItems[]>>);

    let itemIndex = 0;
    return Object.entries(groupedItems).map(([key, value], idx) => {
      const categoryLabel = convertEnumToString(key);
      return (
        <div key={idx} className='border border-gray-300 rounded-2xl p-3 mb-3'>
          <MainHeading title={categoryLabel} classNames='mb-4' />
          {Object.entries(value).map(([subCategory, items], idx) => {
            const subCategoryLabel = convertEnumToString(subCategory);
            return (
              <div key={`subcategofy-${idx}`}>
                <SubCategoryHeading
                  title={subCategoryLabel}
                  classNames='mb-4'
                />
                {items.map((item) => {
                  const currentItemIndex = itemIndex++;
                  return (
                    <div
                      key={item?._id}
                      className='w-full flex justify-between items-start mb-2'
                    >
                      <div className='flex-[0.7] pl-2'>
                        <Controller
                          control={control}
                          name={`lineItems.items.${currentItemIndex}`}
                          render={({ field }) => {
                            return (
                              <Checkbox
                                label={item.item?.name}
                                containerClass='pt-0'
                                name={field.name}
                                checked={field.value?.item === item?._id}
                                onChange={(e) =>
                                  handleItemChange(
                                    item,
                                    e.target.checked,
                                    currentItemIndex,
                                  )
                                }
                              />
                            );
                          }}
                        />
                      </div>
                      <QuantitySection
                        control={control}
                        name={`lineItems.items.${currentItemIndex}.quantity`}
                        price={item.price}
                        disabled={
                          !formValues?.lineItems?.items?.[currentItemIndex]
                            ?.item
                        }
                      />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      );
    });
  };

  return (
    <>
      <AlertMessage
        title='Error'
        message='You can only select one package per order.'
        buttons={[
          {
            label: 'Close',
            className: 'text-white !bg-red-600 focus-visible:ring-red-600',
            onClick: () => setOpenAlert(false),
          },
        ]}
        isOpen={openAlert}
        onClose={() => setOpenAlert(false)}
      />
      <div className='overflow-y-auto'>
        <div className='border border-gray-300 rounded-2xl p-3 mb-3'>
          <MainHeading title='Packages' classNames='mb-4' />
          {bundles?.length ? (
            bundles.map((b, idx) => (
              <div
                key={`b-${idx}`}
                className='w-full flex justify-between items-start mb-2'
              >
                <div className='flex-[0.7]'>
                  <Controller
                    control={control}
                    name={`lineItems.bundles.${idx}`}
                    render={({ field }) => {
                      return (
                        <Checkbox
                          name={field.name}
                          label={b.bundle.name}
                          labelClass='text-gray-950'
                          containerClass='pt-0'
                          checked={field.value?.bundle === b._id}
                          onChange={(e) =>
                            handleBundleChange(b, e?.target?.checked, idx)
                          }
                        />
                      );
                    }}
                  />
                  <ul className='text-sm font-normal ml-10 -mt-3 text-gray-400'>
                    {b.bundle?.items.map((bundleItem) => (
                      <li className='list-disc' key={bundleItem._id}>
                        {bundleItem.name}
                      </li>
                    ))}
                  </ul>
                </div>
                <QuantitySection
                  control={control}
                  name={`lineItems.bundles.${idx}.quantity`}
                  price={b.price}
                  disabled={!formValues?.lineItems?.bundles?.[idx]?.bundle}
                />
              </div>
            ))
          ) : (
            <div>
              {isLoading ? (
                <Loader />
              ) : (
                <p>No Bundles available for this client</p>
              )}
            </div>
          )}
        </div>

        <div className=''>
          {items?.length ? (
            renderItems()
          ) : (
            <div className='border border-gray-300 rounded-2xl p-3 mb-3'>
              <MainHeading title='Items' classNames='mb-4' />
              <div>
                {isLoading ? (
                  <Loader />
                ) : (
                  <p>No Items available for this client</p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
