import { FC, useMemo } from 'react';
import { ReadOnlyKeyValue } from 'components/AVOrder/ReadOnlyKeyValue';
import SectionHeading from 'components/AVOrder/SectionHeading';
import { AVOrder } from 'types/av-orders';

import { InvoiceStatusChip, OrderStatusChip } from './UtilComponents';

interface ReadOnlyEventProfileProps {
  avOrderDetails: AVOrder;
}

export const ReadOnlyEventProfile: FC<ReadOnlyEventProfileProps> = ({
  avOrderDetails,
}) => {
  const programManagerName = useMemo(() => {
    if (!avOrderDetails?.programManager) return 'N/A';

    const { firstName, lastName } = avOrderDetails.programManager;
    return `${firstName} ${lastName}`;
  }, [avOrderDetails?.programManager]);

  const fields = useMemo(
    () => [
      {
        label: 'Order ID',
        value: avOrderDetails.orderId,
        modified: false,
      },
      {
        label: 'Order Status',
        children: <OrderStatusChip value={avOrderDetails.orderStatus} />,
        modified: false,
      },
      {
        label: 'Invoice Status',
        children: <InvoiceStatusChip value={avOrderDetails.invoiceStatus} />,
        modified: false,
      },
      {
        label: 'Entered By',
        value: `${avOrderDetails.enteredBy.firstName} ${avOrderDetails.enteredBy.lastName}`,
        modified: false,
      },
      {
        label: 'End Client',
        value: avOrderDetails.endClient?.name,
        modified: avOrderDetails.modifiedFields?.endClient,
      },
      {
        label: 'Attendee Count',
        value: avOrderDetails.attendeeCount,
        modified: avOrderDetails.modifiedFields?.attendeeCount,
      },
      {
        label: 'Speaker Name',
        value: avOrderDetails.speakerName,
        modified: avOrderDetails.modifiedFields?.speakerName,
      },
      {
        label: 'Program Manager',
        value: programManagerName,
        modified: avOrderDetails?.modifiedFields?.programManager,
      },
    ],
    [
      avOrderDetails.attendeeCount,
      avOrderDetails.endClient?.name,
      avOrderDetails.enteredBy.firstName,
      avOrderDetails.enteredBy.lastName,
      avOrderDetails.invoiceStatus,
      avOrderDetails.modifiedFields?.attendeeCount,
      avOrderDetails.modifiedFields?.endClient,
      avOrderDetails.modifiedFields?.programManager,
      avOrderDetails.modifiedFields?.speakerName,
      avOrderDetails.orderId,
      avOrderDetails.orderStatus,
      avOrderDetails.speakerName,
      programManagerName,
    ],
  );
  return (
    <>
      <SectionHeading heading='Event Profile' />
      <div className='my-2'>
        {fields.map((field) => (
          <ReadOnlyKeyValue key={field?.label} field={field} />
        ))}
      </div>
    </>
  );
};
