import { useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LOCAL_CONSTANT } from 'constants/auth';
import { API_ROUTES, PUBLIC_ROUTES } from 'constants/routes';
import { useRequest } from 'hooks/useRequest';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { OtpPayload, TokenPayload } from 'types/auth';

export default function VerifyOtp() {
  const { control, handleSubmit } = useForm<OtpPayload>();

  const { post } = useRequest();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const email = useMemo(() => {
    const emailParams = searchParams.get('email');
    return emailParams ? decodeURIComponent(emailParams) : '';
  }, [searchParams]);

  const onSubmit: SubmitHandler<OtpPayload> = async ({ otp }) => {
    const res = await post<TokenPayload, OtpPayload>(
      `${API_ROUTES.AV_CUSTOMER}/verify-reset-password`,
      { email, otp: Number(otp) },
      {},
      { showToast: true, errorMessage: 'Invalid OTP' },
    );

    if (res.error) return;

    localStorage.setItem(
      LOCAL_CONSTANT.RESET_TOKEN,
      res.data?.accessToken ?? '',
    );

    navigate(
      `${PUBLIC_ROUTES.RESET_PASSWORD}?email=${encodeURIComponent(email)}`,
    );
  };

  return (
    <div className='md:w-1/2 mx-auto mt-40'>
      <div className='flex flex-col items-center'>
        <div className='sm:flex sm:items-center mb-6'>
          <div className='sm:flex-auto'>
            <h1 className='text-xl font-semibold leading-6 text-gray-900'>
              Verify
            </h1>
            <p className='mt-2 text-sm text-gray-800'>
              Please check your email for the one-time pin to reset your
              password.
            </p>
          </div>
        </div>
      </div>
      <form className='w-[80%] md:w-[60%] mx-auto mt-5'>
        <Controller
          control={control}
          name='otp'
          defaultValue=''
          rules={{
            required: {
              message: 'Please enter a otp',
              value: true,
            },
          }}
          render={({ field }) => (
            <div className='flex justify-center'>
              <MuiOtpInput
                {...field}
                value={field.value as string}
                sx={{ gap: 1 }}
                length={5}
                width={300}
                onComplete={() => handleSubmit(onSubmit)()}
                TextFieldsProps={{
                  size: 'medium',
                  placeholder: '-',
                  type: 'text',
                }}
              />
            </div>
          )}
        />
      </form>
    </div>
  );
}
