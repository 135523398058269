import * as yup from 'yup';

import { Regex } from './regex';

export enum InputValidationTypes {
  NUMERIC = 'numeric',
  LETTERS = 'letters',
  ALPHANUMERIC = 'alphanumeric',
  PHONE = 'phone',
  EMAIL = 'email',
}

export enum AVEntityOrderFormSections {
  EVENT_PROFILE = 'EVENT_PROFILE',
  CONTACTS = 'CONTACTS',
  EVENT_DETAILS = 'EVENT_DETAILS',
}

export const DYNAMIC_FIELD_VALIDATION = {
  [InputValidationTypes.ALPHANUMERIC]: yup.string().matches(Regex.ALPHANUMERIC),
  [InputValidationTypes.EMAIL]: yup
    .string()
    .test('isMatched', 'Should be a valid email address', (value) => {
      if (!value) return true;
      return Regex.EMAIL.test(value);
    }),
  [InputValidationTypes.NUMERIC]: yup
    .string()
    .matches(Regex.NUMERIC, 'Should be a numeric value'),
  [InputValidationTypes.LETTERS]: yup
    .string()
    .matches(Regex.LETTERS, 'Should be letters only'),
  [InputValidationTypes.PHONE]: yup
    .string()
    .test('isMatched', 'Should be a valid phone number', (value) => {
      if (!value) return true;
      return Regex.PHONE.test(value);
    }),
};

export enum STEPS_COUNT {
  FIRST = 0,
  SECOND = 1,
  THIRD = 2,
}

export const FOURTH_STEP_FORM_KEYS = [
  'lineItems.bundles',
  'lineItems.items',
  'lineItems',
];

export const WEB_CONF_EVENT_INFO_FORM_KEYS = [
  'orderId',
  'enteredBy',
  'endClient',
  'attendeeCount',
  'speakerName',
];

export const AV_RENTAL_EVENT_INFO_FORM_KEYS = [
  'orderId',
  'enteredBy',
  'endClient',
  'attendeeCount',
  'speakerName',
  'programManager',
];

export const VENUE_INFO_FORM_KEYS = [
  'venue.name',
  'venue.address',
  'venue.suiteNum',
  'venue.city',
  'venue.state.name',
  'venue.zip',
  'venue.roomName',
  'venue.reservationName',
];

export const WEB_CONF_VENUE_INFO_FORM_KEYS = [
  'venue.city',
  'venue.state.name',
  'venue.zip',
];

export const DATE_TIME_FORM_KEYS = [
  'startDate',
  'endDate',
  'startTime',
  'endTime',
  'setupTime',
];

export const WEB_CONF_DATE_TIME_FORM_KEYS = [
  'startDate',
  'endDate',
  'startTime',
  'endTime',
];

export const VENUE_CONTACTS_FORM_KEYS = [
  'venue.venueContact.name',
  'venue.venueContact.phone',
];

export const ON_SITE_CONTACTS_FORM_KEYS = [
  'onSiteContact.name',
  'onSiteContact.phone',
];

export const CONTACTS_FORM_KEYS = [
  ...VENUE_CONTACTS_FORM_KEYS,
  ...ON_SITE_CONTACTS_FORM_KEYS,
];

export const LINE_ITEMS_FORM_KEYS = [
  'lineItems.bundles',
  'lineItems.items',
  'lineItems',
];

export const FIRST_STEP_ALL_KEYS = [
  ...AV_RENTAL_EVENT_INFO_FORM_KEYS,
  ...VENUE_INFO_FORM_KEYS,
  ...DATE_TIME_FORM_KEYS,
];

export const EVENT_INFO_COPY_KEY_MAP: { [K: string]: string } = {
  enteredBy: 'enteredBy',
  attendeeCount: 'attendeeCount',
  endClient: 'endClient',
};

export const VENUE_INFO_COPY_KEY_MAP: { [K: string]: string } = {
  'venue.name': 'name',
  'venue.address': 'address',
  'venue.city': 'city',
  'venue.state': 'state',
  'venue.zip': 'zip',
};

export const WEB_CONF_ORDER_VENUE_INFO_COPY_KEY_MAP: { [K: string]: string } = {
  'venue.city': 'city',
  'venue.state': 'state',
  'venue.zip': 'zip',
};

export const VENUE_STATE_NAME_KEY = 'venue.state.name';

export enum ConditionalOperators {
  EQUALS = 'equals',
  NOT_EQUALS = 'not equals',
}
