// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleInjectParams = (route: string, params: any) => {
  const paramKeys = Object.keys(params);
  let updatedRoute = route;

  paramKeys.forEach((paramKey) => {
    const paramValue = params[paramKey];
    const paramPlaceholder = `:${paramKey}`;
    updatedRoute = updatedRoute.replace(paramPlaceholder, paramValue ?? '');
  });
  return updatedRoute;
};
