import React from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import { TextArea } from 'components/Inputs/TextArea';
import { OrderDynamicForm } from 'pages/Orders/OrderForm/OrderDynamicForm';
import { AVOrderFormField } from 'types/av-form-fields';
import { CreateAVOrder } from 'types/av-orders';

interface IWebConfEventDetailForm {
  isLoading: boolean;
  control: Control<CreateAVOrder>;
  eventDetailFields: AVOrderFormField[];
  setValue: UseFormSetValue<CreateAVOrder>;
}

const WebConfEventDetailForm: React.FC<IWebConfEventDetailForm> = ({
  setValue,
  control,
  isLoading,
  eventDetailFields,
}) => {
  return (
    <div className='w-full'>
      <OrderDynamicForm
        isWebConf
        setValue={setValue}
        control={control}
        dynamicFields={eventDetailFields}
        isLoading={isLoading}
      />

      <div className='mt-3'>
        <Controller
          control={control}
          name={'comments'}
          render={({ fieldState: { error }, field }) => (
            <TextArea
              description='If this webconference event is being viewed at venue locations requiring Inception-provided AV equipment, please list the City & State and/or AV Order # here.'
              placeholder=''
              value={field.value?.toString() ?? ''}
              error={error}
              onChange={field.onChange}
              label={'Order Comments'}
              name={field.name}
              optional
            />
          )}
        />
      </div>
    </div>
  );
};

export default WebConfEventDetailForm;
