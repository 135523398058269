import { useState } from 'react';

interface UsePaginationProps {
  totalSize: number;
  initialPageSize?: number;
}

const usePagination = ({
  totalSize,
  initialPageSize = 10,
}: UsePaginationProps) => {
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(totalSize / pageSize);

  const handlePageChange = (newPage: number) => {
    if (newPage < 1 || newPage > totalPages) return;
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    setCurrentPage(1);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getCurrentPageData = (data: any[]) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    if (data.length > startIndex) {
      return data.slice(startIndex, endIndex);
    }
    return data;
  };

  return {
    currentPage,
    pageSize,
    totalPages,
    handlePageChange,
    handlePageSizeChange,
    getCurrentPageData,
  };
};

export default usePagination;
