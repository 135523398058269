import * as React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { MdCheck, MdClose } from 'react-icons/md';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Button from 'components/Buttons/Index';
import Input from 'components/Inputs';
import { LOCAL_CONSTANT } from 'constants/auth';
import { Regex } from 'constants/regex';
import { API_ROUTES, PROTECTED_ROUTES } from 'constants/routes';
import { ValidationErrors } from 'constants/validations/errors.validation';
import { useRequest } from 'hooks/useRequest';
import { useSetRecoilState } from 'recoil';
import { authState, currentUserState } from 'state/atoms';
import { NewPasswordPayload, PasswordsPayload } from 'types/auth';
import {
  AVCustomerLoginPayload,
  AVCustomerLoginResponse,
} from 'types/av-customer';
import { handleInjectParams } from 'utils/route.utils';

export default function ResetPassword() {
  const { control, handleSubmit, watch } = useForm<PasswordsPayload>();
  const { post, isLoading } = useRequest();
  const navigate = useNavigate();
  const { accountId } = useParams();
  const [searchParams] = useSearchParams();
  const setAuth = useSetRecoilState(authState);
  const setCurrentUser = useSetRecoilState(currentUserState);

  const { email, token } = React.useMemo(() => {
    const emailParams = searchParams.get('email');
    const token = searchParams.get('token');
    return {
      email: emailParams ? decodeURIComponent(emailParams) : '',
      token,
    };
  }, [searchParams]);

  React.useEffect(() => {
    if (token) {
      localStorage?.setItem(LOCAL_CONSTANT.RESET_TOKEN, token ?? '');
    }
  }, [token]);

  const onSubmit: SubmitHandler<PasswordsPayload> = async ({ newpassword }) => {
    const res = await post<boolean, NewPasswordPayload>(
      `${API_ROUTES.AV_CUSTOMER}/reset-password`,
      { newpassword },
      {
        Authorization: `Bearer ${
          localStorage?.getItem(LOCAL_CONSTANT.RESET_TOKEN) ?? ''
        }`,
      },
      {
        showToast: true,
        successMessage: 'Your password is successfully updated.',
      },
    );
    if (res.error) {
      return;
    }

    localStorage.removeItem(LOCAL_CONSTANT.RESET_TOKEN);

    if (email) {
      const { error, data } = await post<
        AVCustomerLoginResponse,
        AVCustomerLoginPayload
      >(`${API_ROUTES.AV_CUSTOMER}/login`, {
        email,
        password: newpassword,
        accountId: accountId ?? '',
      });

      if (!error && data) {
        setAuth(data?.accessToken);
        setCurrentUser(data.avCustomer);
      }
    }

    navigate(
      handleInjectParams(`${PROTECTED_ROUTES.ORDRES}`, {
        accountId,
      }),
    );
  };

  const password = watch('newpassword');

  const renderListItem = React.useCallback(
    (text: string, condition?: boolean) => {
      return (
        <li
          style={{
            color: condition ? 'green' : 'red',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span>
            {condition ? <MdCheck size={16} /> : <MdClose size={16} />}
          </span>
          {text}
        </li>
      );
    },
    [],
  );

  const [hasLength, hasLowercase, hasUppercase, hasNumber, hasSymbol] =
    React.useMemo(() => {
      const passwordValue = password ?? '';
      const hasLength = passwordValue?.length >= 8;
      const hasLowercase = Regex.LOWER_CASE.test(passwordValue);
      const hasUppercase = Regex.UPPER_CASE.test(passwordValue);
      const hasNumber = Regex.INTEGER.test(passwordValue);
      const hasSymbol = Regex.PASSWORD_SYMBOLS.test(passwordValue);

      return [hasLength, hasLowercase, hasUppercase, hasNumber, hasSymbol];
    }, [password]);

  const isButtonDisabled = React.useMemo(
    () =>
      !hasLength || !hasLowercase || !hasUppercase || !hasNumber || !hasSymbol,
    [hasLength, hasLowercase, hasNumber, hasSymbol, hasUppercase],
  );

  return (
    <div className='md:w-[400px] mx-auto mt-40'>
      <div className='sm:flex sm:items-center mb-6'>
        <div className='sm:flex-auto'>
          <h1 className='text-xl font-semibold leading-6 text-gray-900'>
            Reset Password
          </h1>
          <p className='mt-2 text-sm text-gray-800'>
            Please enter your new password to continue.
          </p>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-3'>
        <Controller
          control={control}
          name='newpassword'
          defaultValue=''
          rules={{
            required: {
              message: 'Password is required',
              value: true,
            },
            pattern: {
              value: Regex.PASSWORD,
              message: ValidationErrors.PASSWORD,
            },
          }}
          render={({ fieldState: { error }, field }) => (
            <div>
              <Input
                value={field.value}
                onChange={field.onChange}
                label='New Password'
                type='password'
                name={field.name}
                placeholder='Enter Password'
                autoComplete='off'
                disabled={isLoading}
              />

              {error?.message ? (
                <p className='ml-1 text-xs text-red-600'>{error?.message}</p>
              ) : null}
            </div>
          )}
        />
        <div className='text-xs'>
          <p className='font-bold'>Password must contain at-least:</p>
          <ul className='list-inside'>
            {renderListItem('8 characters', hasLength)}
            {renderListItem('1 lowercase', hasLowercase)}
            {renderListItem('1 uppercase', hasUppercase)}
            {renderListItem('1 number', hasNumber)}
            {renderListItem('1 symbol', hasSymbol)}
          </ul>
        </div>
        <Controller
          control={control}
          name='confirmPassword'
          defaultValue=''
          rules={{
            validate: (confirmPassword, { newpassword }) =>
              confirmPassword === newpassword
                ? true
                : 'New password should match confirm password',
          }}
          render={({ fieldState: { error }, field }) => (
            <Input
              value={field.value}
              error={error}
              onChange={field.onChange}
              name={field.name}
              label='Confirm Password'
              type='password'
              disabled={isLoading || isButtonDisabled}
              placeholder='Enter Confirm Password'
            />
          )}
        />
        <div className='flex justify-end mt-3'>
          <Button
            className='w-full !bg-green-950 !px-14 !py-3 rounded-xl mt-1 mb-6'
            loading={isLoading}
            disabled={isButtonDisabled || isLoading}
            type='submit'
            label='Reset Password'
          />
        </div>
      </form>
    </div>
  );
}
