/* eslint-disable max-lines */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ReactComponent as CloseIcon } from 'assets/close_MD-1.svg';
import classNames from 'classnames';
import SectionHeading from 'components/AVOrder/SectionHeading';
import Button from 'components/Buttons/Index';
import Loader from 'components/Loaders';
import Modal from 'components/Modal';
import {
  DATE_TIME_FORM_KEYS,
  ON_SITE_CONTACTS_FORM_KEYS,
  VENUE_CONTACTS_FORM_KEYS,
} from 'constants/order-form';
import { AVOrderType } from 'constants/orders';
import { API_ROUTES } from 'constants/routes';
import { COLORS } from 'constants/theme';
import { DEFAULT_TIMEZONE } from 'constants/timezone';
import dayjs from 'dayjs';
import { useRequest } from 'hooks/useRequest';
import _ from 'lodash';
import { AVEntityOrderFormField, AVOrderFormField } from 'types/av-form-fields';
import { AVOrder, State } from 'types/av-orders';
import { AvailableBundles, AvailableItems } from 'types/item-bundle';
import { getSectionWiseFormFields } from 'utils/avUtils';
import { getFormatedTime } from 'utils/dayjs';
import { breakAndCapitalize, formatCurrency } from 'utils/helper';

import WebConfOrderLineItems from '../WebConfOrder/WebconfOrderLineItems';

import { ReadOnlyEventProfile } from './ReadOnlyEventInformation';
import { ReadOnlyVenueInformation } from './ReadOnlyVenueInformation';
import {
  EventItemContainer,
  InfoCard,
  TotalQuoteItemContainer,
} from './UtilComponents';

interface IOrderDetailModal {
  items: AvailableItems[];
  states: State[];
  bundles: AvailableBundles[];
  orderToCopy: AVOrder;
  apiLoading: boolean;
  onClose: () => void;
}

export const OrderDetailModal = ({
  items,
  states,
  bundles,
  orderToCopy,
  apiLoading,
  onClose,
}: IOrderDetailModal) => {
  const [taxRate, setTaxRate] = useState<number>(0);
  const [selectedItems, setSelectedItems] = useState<AvailableItems[]>();
  const [selectedBundles, setSelectedBundles] = useState<AvailableBundles[]>();

  const [avEntityOrdersFormFields, setAVEntityOrdersFormFields] = useState<
    AVEntityOrderFormField[]
  >([]);
  const [, setAVOrdersFormFields] = useState<AVOrderFormField[]>([]);

  useEffect(() => {
    if (orderToCopy) {
      setTaxRate(orderToCopy.taxRate || 0);
    }
  }, [orderToCopy]);

  useEffect(() => {
    if (orderToCopy?.lineItems.length) {
      const temp: AvailableItems[] = [];
      items.map((item) => {
        orderToCopy?.lineItems.map((selectedItem) => {
          const alreadyExists = temp.find((i) => i?._id === item?._id);
          if (
            selectedItem &&
            selectedItem.item?._id === item?._id &&
            !alreadyExists
          ) {
            temp.push({
              ...item,
              quantity: selectedItem.quantity,
              price: selectedItem.price,
            });
          }
        });
      });
      setSelectedItems(temp);
    }
  }, [orderToCopy?.lineItems, items]);

  useEffect(() => {
    if (orderToCopy?.lineItems.length) {
      const temp: AvailableBundles[] = [];
      bundles.map((bundle) => {
        orderToCopy?.lineItems.map((selectedBundle) => {
          const alreadyExists = temp.find((b) => b?._id === bundle?._id);
          if (
            selectedBundle &&
            selectedBundle.bundle?._id === bundle?._id &&
            !alreadyExists
          ) {
            temp.push({
              ...bundle,
              quantity: selectedBundle.quantity,
              price: selectedBundle.price,
            });
          }
        });
      });
      setSelectedBundles(temp);
    }
  }, [bundles, orderToCopy?.lineItems]);

  const subTotal = useMemo(() => {
    const bundleValue = selectedBundles?.reduce(
      (acc, bundle) => acc + bundle.price * bundle.quantity,
      0,
    );
    const itemValue = selectedItems?.reduce((acc, item) => {
      if (!item.item) return acc;
      return acc + item.price * item.quantity;
    }, 0);

    let subTotal = 0;
    if (bundleValue) {
      subTotal += bundleValue;
    }
    if (itemValue) {
      subTotal += itemValue;
    }

    return subTotal;
  }, [selectedBundles, selectedItems]);

  const cartValue = useMemo(() => {
    let total = subTotal;
    if (orderToCopy?.discount) {
      total -= orderToCopy?.discount;
    }
    return total;
  }, [subTotal, orderToCopy?.discount]);

  const taxAmount = useMemo(() => {
    return Number((cartValue * taxRate).toFixed(2));
  }, [taxRate, cartValue]);

  const getFormattedDate = (key: string, date: Date, timezone: string) => {
    if (!date) return '';
    if (key === 'startDate' || key === 'endDate') {
      return dayjs(date).format('MM/DD/YYYY');
    }
    return getFormatedTime(date, timezone);
  };

  const isAVRentalOrder = orderToCopy.type === AVOrderType.AV_RENTAL;

  const { get } = useRequest();
  const getFormFields = useCallback(
    async (clientId: string) => {
      const avOrderFormFieldsUrl = `${API_ROUTES.AV_CUSTOMER}/client-order-form-fields/${clientId}`;
      const webConfOrderFormFieldsUrl = `${API_ROUTES.AV_CUSTOMER}/web-conf-order-form-field`;

      const { data } = await get<AVEntityOrderFormField[]>(
        orderToCopy.type === AVOrderType.AV_RENTAL
          ? avOrderFormFieldsUrl
          : webConfOrderFormFieldsUrl,
      );
      if (data) {
        setAVEntityOrdersFormFields(data);
        setAVOrdersFormFields(data.map(({ formField }) => formField));
      }
    },
    [get, orderToCopy.type],
  );

  useEffect(() => {
    if (orderToCopy?.endClient._id) {
      getFormFields(orderToCopy.endClient._id);
    }
  }, [orderToCopy?.endClient._id, getFormFields]);

  const { contactFields, eventDetailFields, eventProfileFields } =
    useMemo(() => {
      return getSectionWiseFormFields(avEntityOrdersFormFields);
    }, [avEntityOrdersFormFields]);

  const removedItems = orderToCopy?.cartRemovals.filter((item) => !!item.item);
  const removedBundle = orderToCopy?.cartRemovals.filter(
    (item) => !!item.bundle,
  );
  return (
    <Modal className='w-[60%] h-[80vh] !pt-3' open onClose={onClose}>
      <div className='flex justify-between items-center mb-3'>
        <p className='text-2xl font-normal text-gray-950'>Order Quick View</p>
        <CloseIcon className='cursor-pointer' onClick={onClose} />
      </div>
      <div className='flex gap-4 h-[calc(100%-80px)]'>
        <InfoCard heading=''>
          <div className='h-[calc(100%-36px)] overflow-auto'>
            <ReadOnlyEventProfile avOrderDetails={orderToCopy} />
            {eventProfileFields.map(({ _id, label, metadataKey }) => {
              return (
                <EventItemContainer
                  key={_id}
                  heading={breakAndCapitalize(label)}
                  isDirty={_.get(
                    orderToCopy.modifiedFields,
                    `metadata.${metadataKey}`,
                  )}
                  value={_.get(orderToCopy, `metadata.${metadataKey}`)}
                />
              );
            })}

            {isAVRentalOrder ? (
              <ReadOnlyVenueInformation
                avOrderDetails={orderToCopy}
                states={states}
              />
            ) : null}

            <div className='mb-3'>
              <SectionHeading heading='Date & Time' />

              <EventItemContainer
                heading={breakAndCapitalize('Local Timezone')}
                value={_.get(orderToCopy, 'localTimeZone')}
              />
              {DATE_TIME_FORM_KEYS.map((key) => {
                const value = getFormattedDate(
                  key,
                  _.get(orderToCopy, key),
                  _.get(orderToCopy, 'localTimeZone') || DEFAULT_TIMEZONE,
                );

                const heading = breakAndCapitalize(key);

                const shouldHide =
                  !isAVRentalOrder && ['Setup Time'].includes(heading);

                return shouldHide ? null : (
                  <EventItemContainer
                    key={key}
                    isDirty={_.get(orderToCopy.modifiedFields, key)}
                    heading={heading}
                    value={value || 'N/A'}
                  />
                );
              })}
            </div>
            <div className='mb-3'>
              <SectionHeading heading='Contact' />

              {isAVRentalOrder ? (
                <>
                  <h2 className='text-base font-semibold text-gray-950 mb-1'>
                    Venue Contact
                  </h2>

                  {VENUE_CONTACTS_FORM_KEYS.map((key) => (
                    <EventItemContainer
                      key={key}
                      isDirty={_.get(orderToCopy.modifiedFields, key)}
                      heading={breakAndCapitalize(key)}
                      value={_.get(orderToCopy, key)}
                    />
                  ))}
                </>
              ) : null}

              <h2 className='text-base font-semibold text-gray-950 mb-2 mt-2'>
                {isAVRentalOrder ? 'On Site Contact' : 'Primary Contact'}
              </h2>

              {isAVRentalOrder ? (
                ON_SITE_CONTACTS_FORM_KEYS.map((key) => (
                  <EventItemContainer
                    key={key}
                    isDirty={_.get(orderToCopy.modifiedFields, key)}
                    heading={breakAndCapitalize(key)}
                    value={_.get(orderToCopy, key)}
                  />
                ))
              ) : (
                <>
                  <EventItemContainer
                    heading={breakAndCapitalize('Full Name')}
                    isDirty={_.get(
                      orderToCopy.modifiedFields,
                      'onSiteContact.name',
                    )}
                    value={_.get(orderToCopy, 'onSiteContact.name')}
                  />
                  <EventItemContainer
                    heading={breakAndCapitalize('Phone Number')}
                    isDirty={_.get(
                      orderToCopy.modifiedFields,
                      'onSiteContact.phone',
                    )}
                    value={_.get(orderToCopy, 'onSiteContact.phone')}
                  />
                </>
              )}
              {contactFields.map(({ _id, label, metadataKey }) => {
                return (
                  <EventItemContainer
                    key={_id}
                    heading={breakAndCapitalize(label)}
                    isDirty={_.get(
                      orderToCopy.modifiedFields,
                      `metadata.${metadataKey}`,
                    )}
                    value={_.get(orderToCopy, `metadata.${metadataKey}`)}
                  />
                );
              })}
            </div>
            <div className='mb-3'>
              <SectionHeading heading='Event Details' />
              <EventItemContainer
                heading={breakAndCapitalize('Comments')}
                isDirty={_.get(orderToCopy.modifiedFields, 'comments')}
                value={_.get(orderToCopy, 'comments')}
              />
              {eventDetailFields.map(({ _id, label, metadataKey }) => {
                return (
                  <EventItemContainer
                    key={_id}
                    heading={breakAndCapitalize(label)}
                    isDirty={_.get(
                      orderToCopy.modifiedFields,
                      `metadata.${metadataKey}`,
                    )}
                    value={_.get(orderToCopy, `metadata.${metadataKey}`)}
                  />
                );
              })}
            </div>
            <h2 className='text-base font-semibold text-gray-950 mb-2 mt-2'>
              Additional Information
            </h2>
            <EventItemContainer
              heading={'Last Updated At'}
              isDirty={false}
              value={
                _.get(orderToCopy, 'lastUpdatedAt')
                  ? dayjs(_.get(orderToCopy, 'lastUpdatedAt')).format(
                      'MM/DD/YYYY hh:mm A z',
                    )
                  : 'N/A'
              }
            />
            <EventItemContainer
              heading={'Last Updated By'}
              isDirty={false}
              value={
                _.get(orderToCopy, 'lastUpdatedBy')
                  ? `${_.get(orderToCopy, 'lastUpdatedBy.firstName')}  ${_.get(
                      orderToCopy,
                      'lastUpdatedBy.lastName',
                    )}`
                  : 'N/A'
              }
            />
          </div>
        </InfoCard>
        <InfoCard heading='Product Selection'>
          {isAVRentalOrder ? (
            <>
              <div>
                {apiLoading && !selectedBundles?.length ? (
                  <Loader />
                ) : (
                  selectedBundles?.map((bundle, idx) => {
                    const isModified =
                      orderToCopy?.modifiedFields?.lineItems?.bundles?.find(
                        (item: Record<string, string>) =>
                          item.bundle === bundle._id,
                      );
                    return (
                      <div
                        key={`${bundle.bundle?._id}-${idx}`}
                        className='flex justify-between mb-4'
                      >
                        <div className='flex-[0.8]'>
                          <h2
                            className={classNames(
                              'text-base font-medium text-gray-950',
                              { 'bg-yellow-200': isModified },
                            )}
                          >{`${bundle.quantity} ${bundle.bundle?.name}`}</h2>
                          <ul>
                            {bundle?.bundle?.items.map((bundleItem) => (
                              <li
                                key={bundleItem._id}
                                className='text-sm font-light text-gray-500'
                              >
                                {bundleItem.name}
                              </li>
                            ))}
                          </ul>
                        </div>
                        <p
                          className={classNames(
                            'flex-[0.2] text-base font-normal text-gray-950 text-right h-fit',
                            { 'bg-yellow-200': isModified },
                          )}
                        >
                          {formatCurrency(
                            (bundle?.price || 0) * bundle.quantity,
                          )}
                        </p>
                      </div>
                    );
                  })
                )}
                {selectedItems?.map((item, idx) => {
                  const isModified =
                    orderToCopy?.modifiedFields?.lineItems?.items?.find(
                      (modifiedItem: Record<string, string>) =>
                        modifiedItem.item === item._id,
                    );
                  return (
                    <div
                      key={`${item.item?._id}-${idx}`}
                      className='flex justify-between mb-4'
                    >
                      <div className='flex-[0.8]'>
                        <h2
                          className={classNames(
                            'text-base font-medium text-gray-950',
                            { 'bg-yellow-200': isModified },
                          )}
                        >{`${item.quantity} ${item.item?.name}`}</h2>
                      </div>
                      <p
                        className={classNames(
                          'flex-[0.2] text-base font-normal text-gray-950 text-right h-fit',
                          { 'bg-yellow-200': isModified },
                        )}
                      >
                        {formatCurrency((item?.price || 0) * item.quantity)}
                      </p>
                    </div>
                  );
                })}
              </div>
              {orderToCopy?.cartRemovals &&
              orderToCopy.cartRemovals.length > 0 ? (
                <div>
                  <h2 className='text-lg font-medium text-gray-950 text-center mb-2'>
                    Removed Items
                  </h2>
                  <div>
                    {removedBundle?.map((bundle, idx) => (
                      <div
                        key={`${bundle.bundle?._id}-${idx}`}
                        className='flex justify-between mb-4'
                      >
                        <div className='w-full'>
                          <div
                            style={{ backgroundColor: COLORS.highlightRed }}
                            className={classNames(
                              'flex justify-between items-center',
                            )}
                          >
                            <h2 className='text-base font-medium text-gray-950'>{`${bundle.quantity} ${bundle.bundle?.bundle?.name}`}</h2>
                            <p className='flex-[0.2] text-base font-normal text-gray-950 text-right'>
                              {formatCurrency(bundle.price * bundle.quantity)}
                            </p>
                          </div>
                        </div>
                        {/* <p className='flex-[0.2] text-base font-normal text-gray-950 text-right'>
                    {formatCurrency(bundle.price || 0)}
                  </p> */}
                      </div>
                    ))}
                    {removedItems?.map((item, idx) => {
                      if (!item.item) return null;
                      return (
                        <div
                          key={`${item.item?._id}-${idx}`}
                          style={{ backgroundColor: COLORS.highlightRed }}
                          className={classNames(
                            'flex justify-between mb-4',
                            {},
                          )}
                        >
                          <div className='flex-[0.8]'>
                            <h2 className='text-base font-medium text-gray-950'>{`${item.quantity} ${item.item?.item?.name}`}</h2>
                          </div>
                          <p className='flex-[0.2] text-base font-normal text-gray-950 text-right'>
                            {formatCurrency(item.price * item.quantity)}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
              <div className='flex justify-between mt-2 pt-1 border-t border-t-gray-300' />
              {orderToCopy?.discount ? (
                <TotalQuoteItemContainer
                  heading='Discount'
                  value={formatCurrency(orderToCopy?.discount)}
                />
              ) : null}
              <div className='flex justify-between mt-2'>
                <h2 className='font-bold text-base text-gray-950 flex-[0.8]'>
                  Sub-total
                </h2>
                <h2 className='font-bold text-base text-gray-950 flex-[0.2] text-right'>
                  {formatCurrency(subTotal)}
                </h2>
              </div>
              {taxRate ? (
                <TotalQuoteItemContainer
                  heading={`Tax (${(taxRate * 100).toFixed(2)}%)`}
                  value={formatCurrency(taxAmount)}
                />
              ) : null}
              {orderToCopy?.rushFee ? (
                <TotalQuoteItemContainer
                  heading='Rush Fee'
                  value={formatCurrency(orderToCopy?.rushFee)}
                />
              ) : null}
              <div className='flex justify-between mt-8'>
                <h2 className='font-bold text-base text-gray-950 uppercase flex-[0.8]'>
                  Order Total
                </h2>
                <h2 className='font-bold text-base text-gray-950 flex-[0.2] text-right'>
                  {formatCurrency(
                    taxAmount + cartValue + (orderToCopy?.rushFee ?? 0),
                  )}
                </h2>
              </div>
            </>
          ) : (
            <WebConfOrderLineItems
              order={orderToCopy}
              checkIfFieldDirty={(key: string) =>
                _.get(orderToCopy.modifiedFields, key)
              }
            />
          )}
        </InfoCard>
      </div>
      <div className='w-full flex items-center justify-end pt-3'>
        <Button
          className='!border-gray-300 !text-gray-500 mr-2 !px-12 rounded-xl focus-visible:outline-none'
          onClick={onClose}
          variant='outlined'
          label='Close'
          type='button'
        />
      </div>
    </Modal>
  );
};
