/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback } from 'react';
import {
  Control,
  Controller,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import Datepicker, { DateType } from 'react-tailwindcss-datepicker';
import Input from 'components/Inputs';
import MultiCheckbox from 'components/Inputs/MultiCheckbox';
import { OptionalLabelIndicator } from 'components/Inputs/OptionalLabelIndicator';
import Radio from 'components/Inputs/Radio';
import Select from 'components/Inputs/Select';
import { TextArea } from 'components/Inputs/TextArea';
import Loader from 'components/Loaders';
import { ConditionalOperators } from 'constants/order-form';
import {
  AVOrderFormField,
  FormFieldCondition,
  GenericObject,
} from 'types/av-form-fields';
import { CreateAVOrder } from 'types/av-orders';
import { InputTypes } from 'types/form';

import { ErrorComponent } from './UtilComponents';

type RegisterDynamicFormProps = {
  isLoading: boolean;
  isWebConf?: boolean;
  dynamicFields: AVOrderFormField[];
  control: Control<any>;
  setValue?: UseFormSetValue<CreateAVOrder>;
};

export const OrderDynamicForm: FC<RegisterDynamicFormProps> = ({
  dynamicFields,
  isWebConf = false,
  isLoading,
  control,
  setValue,
}) => {
  const columnWidth = isWebConf ? 'w-full' : 'w-[32%]';
  const formData = useWatch({ control });

  const renderConditionalInput = useCallback(
    (
      condition: FormFieldCondition,
      enableCondition: boolean,
      metadata: GenericObject,
      metaDataKey: string,
    ) => {
      if (!enableCondition) return false;

      if (condition?.operator === ConditionalOperators.EQUALS) {
        if (condition && condition.value !== metadata[condition.field]) {
          if (metadata[metaDataKey]) {
            setValue?.(`metadata.${metaDataKey}`, '');
          }
          return true;
        }
      } else if (condition?.operator === ConditionalOperators.NOT_EQUALS) {
        if (condition && condition.value === metadata[condition.field]) {
          if (metadata[metaDataKey]) {
            setValue?.(`metadata.${metaDataKey}`, '');
          }
          return true;
        }
      }

      return false;
    },
    [setValue],
  );

  if (isLoading) return <Loader />;

  return (
    <div className='mt-3 flex gap-2 flex-wrap'>
      {dynamicFields?.map((val, index) => {
        const isNotMatched = renderConditionalInput(
          val?.condition,
          val.enableCondition,
          formData?.metadata,
          val.metadataKey,
        );

        if (val.inputType === InputTypes.SECTION) {
          return (
            <div className='w-full' key={`${index}`}>
              <p className='text-base font-medium mt-1'>{val.label}</p>
            </div>
          );
        }

        if (isNotMatched) {
          return;
        }

        if (val.inputType === InputTypes.TEXT) {
          return (
            <div key={`${index}`} className={`flex mt-3 mb-3 ${columnWidth}`}>
              <Controller
                control={control}
                name={`metadata.${val.metadataKey}`}
                render={({ fieldState: { error }, field }) => (
                  <Input
                    type='text'
                    placeholder={`Enter ${val.label}`}
                    value={field.value}
                    error={error}
                    onChange={field.onChange}
                    label={val.label}
                    name={val.metadataKey}
                    optional={!val.required}
                  />
                )}
              />
            </div>
          );
        }

        if (val.inputType === InputTypes.TEXT_AREA) {
          return (
            <div key={`${index}`} className='mt-3 mb-3 w-full'>
              <Controller
                control={control}
                name={`metadata.${val.metadataKey}`}
                render={({ fieldState: { error }, field }) => (
                  <TextArea
                    placeholder={`Enter ${val.label}`}
                    value={field.value}
                    error={error}
                    onChange={field.onChange}
                    label={val.label}
                    name={val.metadataKey}
                    optional={!val.required}
                  />
                )}
              />
            </div>
          );
        }

        if (val.inputType === InputTypes.DROPDOWN) {
          return (
            <div key={index} className={`mt-3 mb-3 ${columnWidth}`}>
              <Controller
                control={control}
                name={`metadata.${val.metadataKey}`}
                render={({ fieldState: { error }, field }) => (
                  <Select
                    error={error}
                    value={field.value}
                    onChange={field.onChange}
                    options={val.inputValues.split(',').map((op) => ({
                      label: op.trim(),
                      key: op.trim(),
                    }))}
                    label={val.label}
                    name={val.metadataKey}
                    optional={!val.required}
                    controlStyle={{ marginTop: 0 }}
                  />
                )}
              />
            </div>
          );
        }

        if (val.inputType === InputTypes.CHECKBOX) {
          return (
            <div key={index} className='mt-3 mb-3 w-full'>
              <Controller
                control={control}
                name={`metadata.${val.metadataKey}`}
                render={({ fieldState: { error }, field }) => (
                  <MultiCheckbox
                    error={error}
                    options={val.inputValues
                      .split(',')
                      .map((option) => option.trim())}
                    label={val.label}
                    onChange={(val) => {
                      const oldVal = field.value ? field.value?.split(',') : [];
                      if (oldVal.includes(val)) {
                        field.onChange(
                          oldVal.filter((e: string) => e !== val).join(','),
                        );
                      } else {
                        field.onChange([...(oldVal || []), val].join(','));
                      }
                    }}
                    value={field.value}
                    name={val.metadataKey}
                    optional={!val.required}
                  />
                )}
              />
            </div>
          );
        }

        if (val.inputType === InputTypes.RADIO) {
          return (
            <div key={index} className='mt-3 mb-3 w-full'>
              <Controller
                control={control}
                name={`metadata.${val.metadataKey}`}
                render={({ fieldState: { error }, field }) => (
                  <Radio
                    error={error}
                    value={field.value}
                    onChange={field.onChange}
                    label={val.label}
                    name={val.metadataKey}
                    options={val.inputValues.split(',').map((e) => e)}
                    optional={!val.required}
                  />
                )}
              />
            </div>
          );
        }

        if (val.inputType === InputTypes.DATE) {
          return (
            <div key={index} className={`mt-3 mb-3 ${columnWidth}`}>
              <Controller
                control={control}
                name={`metadata.${val.metadataKey}`}
                rules={{
                  required: true,
                }}
                render={({ fieldState: { error }, field }) => {
                  return (
                    <div className='relative mb-4'>
                      <div className='flex justify-between'>
                        <label
                          htmlFor='comment'
                          className='block text-sm font-medium leading-6 text-gray-900'
                        >
                          {val.label}
                        </label>
                        {!val.required ? <OptionalLabelIndicator /> : null}
                      </div>
                      <Datepicker
                        classNames={{
                          input: () =>
                            'border py-2 w-full px-3 rounded-md text-sm focus-visible:outline-green-950',
                        }}
                        asSingle
                        useRange={false}
                        onChange={(e) => field.onChange(e?.startDate)}
                        value={{
                          startDate: field.value as DateType,
                          endDate: field.value as DateType,
                        }}
                        displayFormat={'MM/DD/YYYY'}
                      />
                      <ErrorComponent msg={error?.message} id='date-error' />
                    </div>
                  );
                }}
              />
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};
