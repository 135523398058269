import React, { useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import ReactSelect, {
  components,
  DropdownIndicatorProps,
  OptionProps,
  StylesConfig,
} from 'react-select';
import classNames from 'classnames';
import { COLORS } from 'constants/theme';
import { ErrorComponent } from 'pages/Orders/OrderForm/UtilComponents';

import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

import { OptionalLabelIndicator } from './OptionalLabelIndicator';

type OptionType = { label: string; value: string };

interface ISelect {
  options: OptionType[];
  label: string;
  name: string;
  value: string;
  onChange: (...event: unknown[]) => void;
  error?: FieldError;
  optional?: boolean;
  disabled?: boolean;
}

const CustomDropdownIndicator = (props: DropdownIndicatorProps<OptionType>) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronUpDownIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
    </components.DropdownIndicator>
  );
};

const CustomOption = (props: OptionProps<OptionType>) => {
  return (
    <components.Option {...props}>
      <div
        className={classNames(
          'relative cursor-default select-none pl-8 pr-4 text-sm text-gray-950',
          { 'bg-stepperBg cursor-pointer': props.isFocused },
        )}
      >
        <span
          className={classNames('block text-wrap', {
            'font-semibold': props.isSelected,
            'font-normal': !props.isSelected,
          })}
        >
          {props.children}
        </span>

        {props.isSelected ? (
          <span
            className={
              'absolute inset-y-0 left-0 flex items-center pl-1.5 text-gray-950'
            }
          >
            <CheckIcon className='h-5 w-5' aria-hidden='true' />
          </span>
        ) : null}
      </div>
    </components.Option>
  );
};

export const TypeToSelect: React.FC<ISelect> = ({
  options,
  label,
  name,
  value,
  error,
  onChange,
  optional = false,
  disabled = false,
}) => {
  const customStyles: StylesConfig<OptionType, false> = {
    control: (provided, state) => ({
      ...provided,
      'minHeight': '2.5rem',
      'borderColor': state.isFocused ? COLORS.green[950] : COLORS.gray[300],
      'boxShadow': state.isFocused ? `0 0 0 1px ${COLORS.green[950]}` : '',
      '&:hover': {
        borderColor: state.isFocused ? '' : COLORS.gray[300],
      },
      'borderRadius': '0.375rem',
      'backgroundColor': disabled ? COLORS.offWhite : COLORS.white,
      'fontSize': '0.875rem',
      'lineHeight': '1.25rem',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: COLORS.gray[400],
    }),
    singleValue: (provided) => ({
      ...provided,
      color: COLORS.gray[950],
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 10,
      borderRadius: '0.375rem',
    }),
    option: (provided, state) => ({
      ...provided,
      'backgroundColor': state.isFocused ? COLORS.stepperBg : COLORS.white,
      '&:active': {
        backgroundColor: state.isFocused ? COLORS.stepperBg : COLORS.white,
      },
    }),
  };

  const __value = useMemo(
    () => options.find((o) => o.value === value),
    [options, value],
  );

  return (
    <div className='relative mb-2'>
      <div className='flex justify-between'>
        <legend className='block text-sm font-medium leading-6 text-gray-900 truncate'>
          {label}
        </legend>
        {optional ? <OptionalLabelIndicator /> : null}
      </div>
      <ReactSelect
        value={__value ?? null}
        options={options}
        onChange={(e) => onChange(e?.value)}
        placeholder={`Select ${label}`}
        required={!optional}
        isDisabled={disabled}
        isClearable
        styles={customStyles}
        name={name}
        components={{
          DropdownIndicator: CustomDropdownIndicator,
          Option: CustomOption,
        }}
      />
      <ErrorComponent id='state-error' msg={error?.message} />
    </div>
  );
};

export default TypeToSelect;
