import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Button from 'components/Buttons/Index';
import Modal from 'components/Modal';
import { Config } from 'constants/config';
import { useInterceptor } from 'hooks/useInterceptor';
import { RecoilRoot } from 'recoil';
import Routers from 'routes/Routers';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function App() {
  useInterceptor();
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  useEffect(() => {
    const { REACT_APP_GIT_HASH, NODE_ENV } = process.env;
    if ((NODE_ENV as unknown as string) === 'production') {
      const _tick = () => {
        fetch(`${Config.API_BASE_URL}/core/app-version`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ appName: 'av-portal' }),
        })
          .then((res) => res.json())
          .then((json) => {
            if (REACT_APP_GIT_HASH !== json.data) {
              // eslint-disable-next-line no-alert
              setIsModalOpen(true);
            }
            setTimeout(_tick, 60000);
          });
      };
      _tick();
    }
  }, []);

  return (
    <BrowserRouter>
      <RecoilRoot>
        <Routers />
        <ToastContainer className='text-sm bottom-20' stacked={true} />

        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div className='flex flex-col'>
            <h4 className='text-xl font-bold'>Version Update</h4>
            <p className='text-sm'>
              The application has been updated. Please refresh the page to get
              the latest changes.
            </p>

            <div className='flex flex-row ml-auto gap-1 mt-4'>
              <Button
                className='text-red-500 border-red-500'
                label='Cancel'
                variant='outlined'
                onClick={() => {
                  setIsModalOpen(false);
                }}
              />
              <Button
                label='Refresh'
                onClick={() => {
                  window.location.reload();
                }}
              />
            </div>
          </div>
        </Modal>
      </RecoilRoot>
    </BrowserRouter>
  );
}

export default App;
