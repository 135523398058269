/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { ReactComponent as CloseIcon } from 'assets/close_MD-1.svg';
import Button from 'components/Buttons/Index';
import Checkbox from 'components/Inputs/Checkbox';
import Modal from 'components/Modal';
import { EVENT_INFO_COPY_KEY_MAP } from 'constants/order-form';
import { AVOrder } from 'types/av-orders';
import { getFormBasicValues } from 'utils/avUtils';
import { breakAndCapitalize } from 'utils/helper';

interface IWebconfCopyOrderModal {
  orderToCopy: AVOrder;
  onClose: () => void;
  onCreate: (copyKeys: string[]) => void;
}

interface ISectionContainer {
  label: string;
  name: string;
  checked: boolean;
  children: JSX.Element;
  onSelect: (id: string) => void;
}

const SectionContainer = ({
  label,
  name,
  checked,
  children,
  onSelect,
}: ISectionContainer) => {
  return (
    <div className='mb-8'>
      <Checkbox
        label={label}
        name={name}
        checked={checked}
        containerClass='!py-0'
        labelClass='text-base font-medium text-gray-950'
        onChange={() => onSelect(name)}
      />
      {children}
    </div>
  );
};

export const WebconfCopyOrderModal = ({
  orderToCopy,
  onClose,
  onCreate,
}: IWebconfCopyOrderModal) => {
  const [sectionIds, setSectionIds] = useState<string[]>([]);

  const onSelect = (id: string) => {
    if (sectionIds.includes(id)) {
      setSectionIds(sectionIds.filter((sectionId) => sectionId !== id));
    } else {
      setSectionIds([...sectionIds, id]);
    }
  };

  return (
    <Modal className='w-[50%] h-[60vh]' open onClose={onClose}>
      <div className='flex flex-col h-full '>
        <div className='flex justify-between'>
          <div>
            <h3 className='text-2xl font-normal text-gray-800'>
              Select what you want to copy
            </h3>
            <p>
              Select the fields that you want to import from the original order
              to the new one.
            </p>
          </div>
          <CloseIcon className='w-6 h-6 cursor-pointer' onClick={onClose} />
        </div>
        <div className='py-5 px-6 mt-4 flex flex-1 h-[calc(100%-100px)] overflow-auto'>
          <div className='flex-[0.5]'>
            <SectionContainer
              label='Event Information'
              name='eventInfo'
              checked={sectionIds.includes('eventInfo')}
              onSelect={onSelect}
            >
              <div className='ml-8 gap-1'>
                {Object.keys(EVENT_INFO_COPY_KEY_MAP).map((key) => (
                  <div className='flex mt-1' key={key}>
                    <p className='font-normal text-sm text-gray-500'>
                      {breakAndCapitalize(`${EVENT_INFO_COPY_KEY_MAP[key]}`)}:
                      &nbsp;
                    </p>
                    <p className='text-sm font-normal text-gray-500'>
                      {getFormBasicValues(key, orderToCopy)}
                    </p>
                  </div>
                ))}
              </div>
            </SectionContainer>
          </div>
        </div>
        <div className='flex gap-3 justify-end'>
          <Button
            className='!border-gray-300 !text-gray-500 mr-2 !px-12 !py-2 rounded-xl'
            variant='outlined'
            onClick={onClose}
            label='Cancel'
            type='button'
          />
          <Button
            className='!bg-green-950 !px-8 rounded-xl'
            onClick={() => onCreate(sectionIds)}
            label='Create Order'
            type='button'
          />
        </div>
      </div>
    </Modal>
  );
};
