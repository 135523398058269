/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Navigate } from 'react-router-dom';
import { ReactComponent as LogoIcon } from 'assets/logo_Icon.svg';
import Footer from 'components/Footer';
import Header from 'components/Header';
import { LoginForm } from 'components/LoginForm';
import { LOCAL_CONSTANT } from 'constants/auth';
import { PROTECTED_ROUTES } from 'constants/routes';
import { useRecoilValue } from 'recoil';
import { authState } from 'state/atoms';
import { showToast } from 'utils/toast';

const LoginPage = () => {
  const auth = useRecoilValue(authState);
  const resetToken = localStorage?.getItem(LOCAL_CONSTANT.RESET_TOKEN);

  setTimeout(() => {
    const sessionMessage = localStorage.getItem('sessionMessage');
    if (sessionMessage) {
      showToast({ message: sessionMessage, type: 'info' });
      localStorage.clear();
    }
  }, 100);

  if (auth && !resetToken) {
    return <Navigate to={PROTECTED_ROUTES.ORDRES} />;
  }

  return (
    <div className='h-screen'>
      <Header />
      <div className='container mx-auto flex flex-col h-[calc(100vh-135px)] p-6 gap-4 items-center'>
        <LogoIcon />
        <h2 className='text-3xl font-bold text-gray-950'>Client Login</h2>
        <p className='text-base font-normal text-gray-600'>
          This login is for the online AV ordering portal.
        </p>
        <div className='w-1/2 lg:w-1/3 xl:w-1/4'>
          <LoginForm />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LoginPage;
