import { FC, useMemo } from 'react';
import { ReadOnlyKeyValue } from 'components/AVOrder/ReadOnlyKeyValue';
import SectionHeading from 'components/AVOrder/SectionHeading';
import { AVOrder, State } from 'types/av-orders';

interface ReadOnlyVenueInformationProps {
  states: State[];
  avOrderDetails: AVOrder;
}

export const ReadOnlyVenueInformation: FC<ReadOnlyVenueInformationProps> = ({
  states,
  avOrderDetails,
}) => {
  const state = useMemo(
    () => states.find((s) => s._id === avOrderDetails.venue?.state),
    [states, avOrderDetails.venue?.state],
  );
  const fields = useMemo(
    () => [
      {
        label: 'Venue Name',
        value: avOrderDetails.venue?.name,
        modified: avOrderDetails.modifiedFields?.venue?.name,
      },
      {
        label: 'Address',
        value: avOrderDetails.venue?.address,
        modified: avOrderDetails.modifiedFields?.venue?.address,
      },
      {
        label: 'Suite #',
        value: avOrderDetails.venue?.suiteNum ?? '',
        modified: avOrderDetails.modifiedFields?.venue?.suiteNum ?? false,
      },
      {
        label: 'City',
        value: avOrderDetails.venue?.city,
        modified: avOrderDetails.modifiedFields?.venue?.city,
      },
      {
        label: 'State',
        value: state?.name,
        modified: avOrderDetails.modifiedFields?.venue?.state,
      },
      {
        label: 'Country',
        value: state?.country,
        modified: false,
      },
      {
        label: 'Zip',
        value: avOrderDetails.venue?.zip,
        modified: avOrderDetails.modifiedFields?.venue?.zip,
      },

      {
        label: 'Room Name',
        value: avOrderDetails.venue?.roomName,
        modified: avOrderDetails.modifiedFields?.venue?.roomName,
      },
      {
        label: 'Reservation Name',
        value: avOrderDetails.venue?.reservationName,
        modified: avOrderDetails.modifiedFields?.venue?.reservationName,
      },
    ],
    [
      avOrderDetails.modifiedFields?.venue?.address,
      avOrderDetails.modifiedFields?.venue?.city,
      avOrderDetails.modifiedFields?.venue?.name,
      avOrderDetails.modifiedFields?.venue?.reservationName,
      avOrderDetails.modifiedFields?.venue?.roomName,
      avOrderDetails.modifiedFields?.venue?.state,
      avOrderDetails.modifiedFields?.venue?.suiteNum,
      avOrderDetails.modifiedFields?.venue?.zip,
      avOrderDetails.venue?.address,
      avOrderDetails.venue?.city,
      avOrderDetails.venue?.name,
      avOrderDetails.venue?.reservationName,
      avOrderDetails.venue?.roomName,
      avOrderDetails.venue?.suiteNum,
      avOrderDetails.venue?.zip,
      state?.country,
      state?.name,
    ],
  );
  return (
    <>
      <SectionHeading heading='Venue Information' />
      <div className='my-1'>
        {fields.map((field) => (
          <ReadOnlyKeyValue key={field.label} field={field} />
        ))}
      </div>
    </>
  );
};
