export enum OrderValidationErrors {
  ITEM_REQUIRED = 'Item is required',
  BUNDLE_REQUIRED = 'Bundle is required',
  ORDER_ID_REQUIRED = 'Id is required',
  ENTERED_BY_REQUIRED = 'Entered By is required',
  DATE_REQUIRED = 'Date is required',
  ADDRESS_REQUIRED = 'Address is required',
  ATTENDEE_COUNT_REQUIRED = 'Attendee count is required',
  LINE_ITEMS_REQUIRED = 'Select at least 1 bundle or item',
  CLIENT_REQUIRED = 'Client is required',
  FIELD_REQUIRED = 'This field is required',
  INVALID_EMAIL = 'Invalid email pattern',
  PHONE_PATTERN = 'Invalid phone number',
  ZIP_PATTERN = 'Please enter a valid zip code',
  NUMBER_GREATER_THAN_ZERO = 'Number must be greater than 0',
  NOT_A_NUMBER = 'The value must be a number',
}
