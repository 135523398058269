import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Button from 'components/Buttons/Index';
import Input from 'components/Inputs';
import { LOCAL_CONSTANT } from 'constants/auth';
import { Regex } from 'constants/regex';
import { API_ROUTES, PROTECTED_ROUTES, PUBLIC_ROUTES } from 'constants/routes';
import { useRequest } from 'hooks/useRequest';
import { useSetRecoilState } from 'recoil';
import { authState, currentUserState } from 'state/atoms';
import {
  AVCustomerLoginForm,
  AVCustomerLoginPayload,
  AVCustomerLoginResponse,
} from 'types/av-customer';
import { handleInjectParams } from 'utils/route.utils';

export const LoginForm: React.FC = () => {
  const { control, handleSubmit, watch } = useForm<AVCustomerLoginForm>({
    defaultValues: { email: '' },
  });
  const { post, isLoading: formLoading } = useRequest();
  const navigate = useNavigate();
  const setCurrentUser = useSetRecoilState(currentUserState);

  const setAuth = useSetRecoilState(authState);
  const { accountId } = useParams();

  const email = watch('email');

  const onSubmit: SubmitHandler<AVCustomerLoginForm> = async ({
    email,
    password,
  }) => {
    const { error, data } = await post<
      AVCustomerLoginResponse,
      AVCustomerLoginPayload
    >(`${API_ROUTES.AV_CUSTOMER}/login`, {
      email,
      password,
      accountId: accountId ?? '',
    });

    if (error || !data) return;
    setAuth(data.accessToken);
    setCurrentUser(data.avCustomer);

    if (data.resetToken) {
      localStorage.setItem(LOCAL_CONSTANT.RESET_TOKEN, data.resetToken);
      navigate(PUBLIC_ROUTES.RESET_PASSWORD);
      return;
    }

    navigate(
      handleInjectParams(`${PROTECTED_ROUTES.ORDRES}/`, {
        accountId,
      }),
    );
  };

  return (
    <form
      className='mt-5 flex flex-col gap-3'
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <Controller
        control={control}
        name='email'
        defaultValue=''
        rules={{
          required: {
            message: 'Email is required',
            value: true,
          },
          pattern: {
            value: Regex.EMAIL,
            message: 'Invalid Email Address',
          },
        }}
        render={({ fieldState: { error }, field }) => (
          <Input
            error={error}
            label='Email'
            name={field.name}
            onChange={field.onChange}
            placeholder=''
            type='text'
            value={field.value}
          />
        )}
      />

      <Controller
        control={control}
        name='password'
        defaultValue=''
        rules={{
          required: {
            message: 'Password is required',
            value: true,
          },
        }}
        render={({ fieldState: { error }, field }) => (
          <Input
            error={error}
            label='Password'
            name={field.name}
            onChange={field.onChange}
            placeholder=''
            type='password'
            value={field.value}
          />
        )}
      />

      <div className='flex items-start justify-end'>
        <Link
          className='no-underline text-sm font-medium text-green-950'
          to={`/forgot-password?email=${encodeURIComponent(email)}`}
        >
          Forgot Password
        </Link>
      </div>
      <Button
        className='w-full !bg-green-950 !px-14 !py-3 rounded-xl mt-1 mb-6'
        variant='contained'
        loading={formLoading}
        disabled={formLoading}
        type='submit'
        label='Sign In'
      />
    </form>
  );
};
