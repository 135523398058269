import { useCallback, useMemo, useState } from 'react';

export const useCancel = () => {
  const [deleteRecord, setDeleteRecord] = useState('');

  const onCancel = useCallback((id: string) => setDeleteRecord(id), []);

  const onCloseConfirmation = useCallback(() => setDeleteRecord(''), []);

  return useMemo(
    () => ({
      openConfirm: !!deleteRecord,
      onCancel,
      onCloseConfirmation,
      recordId: deleteRecord,
    }),
    [deleteRecord, onCancel, onCloseConfirmation],
  );
};
