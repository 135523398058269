/* eslint-disable max-lines */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import Datepicker, { DateType } from 'react-tailwindcss-datepicker';
import TimePicker from 'react-time-picker';
import Select from 'components/Inputs/Select';
import { TimeZoneClasses } from 'constants/common';
import dayjs from 'dayjs';
import { ErrorComponent } from 'pages/Orders/OrderForm/UtilComponents';
import { AVOrder, CreateAVOrder, GoogleTimeZone } from 'types/av-orders';
import { AV_ORDER_STATUS } from 'utils/common.enum';

interface IWebConfOrderDateTimeForm {
  editOrder: AVOrder | undefined;
  formValues: CreateAVOrder;
  control: Control<CreateAVOrder>;
  setValue: UseFormSetValue<CreateAVOrder>;
  googleTimezone: GoogleTimeZone | null | undefined;
  watch: UseFormWatch<CreateAVOrder>;
  isWithinCancellationPolicy: boolean;
}

const WebConfOrderDateTimeForm: React.FC<IWebConfOrderDateTimeForm> = ({
  editOrder,
  control,
  formValues,
  setValue,
  watch,
  isWithinCancellationPolicy,
}) => {
  const [tzCode, setTzCode] = useState('');

  useEffect(() => {
    if (formValues?.localTimeZone) {
      const tzCode = dayjs().tz(formValues.localTimeZone).format('z');
      setTzCode(tzCode);
    }
  }, [formValues?.localTimeZone]);

  const startDate = watch('startDate');
  const endDate = watch('endDate');

  useEffect(() => {
    if (formValues?.startDate && formValues?.startTime) {
      const startTime = dayjs(formValues.startDate)
        .set('hour', formValues.startTime.getHours())
        .set('minute', formValues.startTime.getMinutes());
      if (!formValues.endTime) {
        const endTime = startTime.add(1, 'hour');
        setValue('endTime', endTime.toDate(), {
          shouldDirty: true,
          shouldValidate: true,
        });
        const startDate = dayjs(formValues.startDate);
        const endDate = dayjs(formValues.endDate);
        if (startDate.isSame(endDate, 'day') && endTime.isAfter(endDate)) {
          setValue('endDate', endTime.toDate(), {
            shouldValidate: true,
          });
        }
      }

      const diff = startTime.diff(formValues?.startTime, 'days');
      if (diff !== 0) {
        const startTime = dayjs(formValues.startDate)
          .set('hour', formValues.startTime.getHours())
          .set('minute', formValues.startTime.getMinutes());
        setValue('startTime', startTime.toDate(), {
          shouldValidate: true,
        });
      }
    }
    if (formValues?.endDate && formValues?.endTime) {
      const endDate = dayjs(formValues.endDate)
        .set('hour', formValues.endTime.getHours())
        .set('minute', formValues.endTime.getMinutes());
      const diff = endDate.diff(formValues.endTime, 'day');
      if (diff !== 0) {
        const endTime = endDate
          .set('hour', formValues.endTime.getHours())
          .set('minute', formValues.endTime.getMinutes());
        setValue('endTime', endTime.toDate(), {
          shouldValidate: true,
        });
      }
    }
  }, [
    setValue,
    formValues.startDate,
    formValues.startTime,
    formValues.endTime,
    formValues.endDate,
  ]);

  const disableDateTimeFields = useMemo(() => {
    return editOrder?.orderStatus === AV_ORDER_STATUS.ON_HOLD
      ? false
      : (!!editOrder && isWithinCancellationPolicy) ||
          (!!editOrder?.rushFee && editOrder?.rushFee > 0);
  }, [editOrder, isWithinCancellationPolicy]);

  return (
    <div>
      <div className='flex gap-2'>
        <div className='sm:w-2/5 xl:w-1/3 relative'>
          <Controller
            control={control}
            name={'localTimeZone'}
            rules={{ required: true }}
            render={({ fieldState: { error }, field }) => (
              <Select
                error={error}
                name={field.name}
                value={field.value as string}
                onChange={field.onChange}
                options={[
                  {
                    label: 'America/Anchorage',
                    key: 'America/Anchorage',
                  },
                  {
                    label: 'America/Phoenix',
                    key: 'America/Phoenix',
                  },
                  {
                    label: 'America/Los_Angeles',
                    key: 'America/Los_Angeles',
                  },
                  {
                    label: 'America/Denver',
                    key: 'America/Denver',
                  },
                  {
                    label: 'America/Chicago',
                    key: 'America/Chicago',
                  },
                  {
                    label: 'America/New_York',
                    key: 'America/New_York',
                  },
                ]}
                label={'Local Time Zone'}
                controlStyle={{ marginTop: 0 }}
              />
            )}
          />
        </div>
      </div>

      <div className='flex justify-start items-center sm:flex-wrap xl:flex-nowrap gap-2 mt-2'>
        <div className='sm:w-2/5 xl:w-1/3 relative'>
          <label className='block text-sm font-medium leading-6 text-gray-900'>
            Local Start Date
          </label>
          <Controller
            control={control}
            name='startDate'
            rules={{
              required: 'Start Date is required',
            }}
            render={({ fieldState: { error }, field }) => (
              <>
                <Datepicker
                  classNames={{
                    input: () =>
                      'border py-2 w-full px-3 rounded-md text-sm focus-visible:outline-green-950',
                  }}
                  asSingle
                  useRange={false}
                  onChange={(e) => {
                    field.onChange(e?.startDate);
                    if (!formValues.endDate && e?.startDate) {
                      setValue('endDate', e.startDate as Date, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    }
                  }}
                  minDate={new Date()}
                  value={{
                    startDate: field.value as DateType,
                    endDate: field.value as DateType,
                  }}
                  disabled={disableDateTimeFields}
                  displayFormat={'MM/DD/YYYY'}
                />
                <ErrorComponent id='startDate-error' msg={error?.message} />
              </>
            )}
          />
        </div>
        <div className='sm:w-2/5 xl:w-1/3 relative'>
          <label className='block text-sm font-medium leading-6 text-gray-900'>
            Local End Date
          </label>
          <Controller
            control={control}
            name='endDate'
            rules={{
              required: 'End Date is required',
            }}
            render={({ fieldState: { error }, field }) => (
              <>
                <Datepicker
                  classNames={{
                    input: () =>
                      'border py-2 w-full px-3 rounded-md text-sm focus-visible:outline-green-950',
                  }}
                  asSingle
                  useRange={false}
                  onChange={(e) => {
                    field.onChange(e?.startDate);
                  }}
                  minDate={formValues?.startDate}
                  value={{
                    startDate: field.value as DateType,
                    endDate: field.value as DateType,
                  }}
                  disabled={disableDateTimeFields}
                  displayFormat={'MM/DD/YYYY'}
                />
                <ErrorComponent id='startDate-error' msg={error?.message} />
              </>
            )}
          />
        </div>
      </div>
      <div className='flex justify-start items-center gap-2 mt-4'>
        <div className='sm:w-2/5 xl:w-1/3 relative'>
          <label className='block text-sm font-medium leading-6 text-gray-900'>
            Local Start Time
          </label>
          <Controller
            control={control}
            name='startTime'
            rules={{ required: 'Start Time is required' }}
            render={({ fieldState: { error }, field }) => (
              <div>
                <div className='flex items-center relative'>
                  <TimePicker
                    disableClock
                    format='hh:mm a'
                    name={field.name}
                    onChange={(e) => {
                      const timeArr = e?.toString().split(':');
                      if (!timeArr) return;
                      const hours = parseInt(timeArr[0] || '0');
                      const minutes = parseInt(timeArr[1] || '0');
                      const date = dayjs(startDate)
                        .set('hour', hours)
                        .set('minute', minutes)
                        .toDate();
                      field.onChange(date);
                    }}
                    value={field.value}
                    disabled={disableDateTimeFields || !startDate}
                  />
                  <div className={TimeZoneClasses}> {tzCode}</div>
                </div>
                <ErrorComponent id='startTime-error' msg={error?.message} />
              </div>
            )}
          />
        </div>
        <div className='sm:w-2/5 xl:w-1/3 relative'>
          <label className='block text-sm font-medium leading-6 text-gray-900'>
            Local End Time
          </label>
          <Controller
            control={control}
            name='endTime'
            rules={{ required: 'End Time is required' }}
            render={({ fieldState: { error }, field }) => (
              <div>
                <div className='flex items-center relative'>
                  <TimePicker
                    disableClock
                    format='hh:mm a'
                    name={field.name}
                    onChange={(e) => {
                      const timeArr = e?.toString().split(':');
                      if (!timeArr) return;
                      const hours = parseInt(timeArr[0] || '0');
                      const minutes = parseInt(timeArr[1] || '0');
                      const date = dayjs(endDate)
                        .set('hour', hours)
                        .set('minute', minutes)
                        .toDate();
                      field.onChange(date);
                    }}
                    value={field.value}
                    disabled={disableDateTimeFields || !endDate}
                  />
                  <div className={TimeZoneClasses}> {tzCode}</div>
                </div>
                <ErrorComponent id='startTime-error' msg={error?.message} />
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default WebConfOrderDateTimeForm;
