/* eslint-disable @typescript-eslint/no-explicit-any */
import { PolarSelect } from 'constants/common';
import {
  AVEntityOrderFormSections,
  ConditionalOperators,
  DYNAMIC_FIELD_VALIDATION,
} from 'constants/order-form';
import {
  AVOrderType,
  WEBCONF_ADDIONAL_ATTENDEE_PRICE,
  WEBCONF_ADDIONAL_DURATION_PRICE,
  WEBCONF_ATTENDEES_INCLUDED,
  WEBCONF_BASE_PRICE,
  WEBCONF_HOURS_INCLUDED,
} from 'constants/orders';
import { DEFAULT_TIMEZONE } from 'constants/timezone';
import dayjs from 'dayjs';
import _ from 'lodash';
import { AVCustomer, CreateAVLineItem, CustomerBase } from 'types/av-customer';
import {
  AVEntityOrderFormField,
  AVOrderFormField,
  AVOrderFormFieldCommonPayload,
} from 'types/av-form-fields';
import { AVOrder } from 'types/av-orders';
import { InputTypes } from 'types/form';
import {
  AvailableBundles,
  AvailableItems,
  AVLineItem,
} from 'types/item-bundle';
import { OrderValidationErrors } from 'validations/vaidation-errors';
import * as yup from 'yup';

import { timezonedDayjs } from './dayjs';

export const getDefaultBundles = (
  bundles: AvailableBundles[],
  lineItems?: AVLineItem[],
): CreateAVLineItem[] => {
  const bundlesToReturn: unknown[] = [];
  for (let i = 0; i < bundles.length; i++) {
    const bundle = bundles[i];
    if (bundle) {
      const singleLineItem = lineItems?.find(
        (li) => li?.bundle?._id === bundle._id,
      );
      bundlesToReturn.push({
        bundle: singleLineItem?.bundle?._id || undefined,
        quantity: singleLineItem?.quantity || 0,
        price: bundle.price ?? undefined,
      });
    }
  }
  return bundlesToReturn as CreateAVLineItem[];
};

export const getDefaultItems = (
  items: AvailableItems[],
  lineItems?: AVLineItem[],
): CreateAVLineItem[] => {
  const itemsToReturn: unknown[] = [];
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (item) {
      const singleLineItem = lineItems?.find(
        (li) => li?.item?._id === item._id,
      );
      itemsToReturn.push({
        item: singleLineItem?.item?._id || undefined,
        quantity: singleLineItem?.quantity || 0,
        price: item.price ?? undefined,
      });
    }
  }
  return itemsToReturn as CreateAVLineItem[];
};

export const getAVOrderDefaultValues = (
  nextOrderId: string,
  avOrderFormFields: AVOrderFormField[],
  user: AVCustomer | null,
  bundles: AvailableBundles[],
  items: AvailableItems[],
  editOrder?: AVOrder,
) => {
  const editUser = editOrder?.enteredBy as unknown as AVCustomer;

  let startDate = undefined;
  let endDate: Date | undefined = undefined;
  let startTime = undefined;
  let endTime = undefined;
  let setupTime = undefined;
  if (editOrder?.startDate) {
    startDate = dayjs(editOrder?.startDate, 'YYYY-MM-DD').toDate();

    if (editOrder?.startTime) {
      const startTimeArr = editOrder?.startTime.toString().split(':');
      startTime = dayjs(editOrder?.startDate, 'YYYY-MM-DD')
        .set('hour', Number(startTimeArr[0]))
        .set('minute', Number(startTimeArr[1]))
        .toDate();
    }

    if (editOrder?.setupTime) {
      const setupTimeArr = editOrder?.setupTime?.toString()?.split(':');
      setupTime = editOrder?.setupTime
        ? dayjs(editOrder?.startDate, 'YYYY-MM-DD')
            .set('hour', Number(setupTimeArr[0]))
            .set('minute', Number(setupTimeArr[1]))
            .toDate()
        : undefined;
    }
  }
  if (editOrder?.endDate) {
    endDate = editOrder?.endDate
      ? dayjs(editOrder?.endDate, 'YYYY-MM-DD').toDate()
      : undefined;

    if (editOrder?.endTime) {
      const endTimeArr = editOrder?.endTime.toString().split(':');
      endTime = dayjs(editOrder?.endDate, 'YYYY-MM-DD')
        .set('hour', Number(endTimeArr[0]))
        .set('minute', Number(endTimeArr[1]))
        .toDate();
    }
  }

  return {
    type: AVOrderType.AV_RENTAL,
    lineItems: {
      bundles: getDefaultBundles(
        bundles,
        editOrder?.lineItems,
      ) as CreateAVLineItem[],
      items: getDefaultItems(items, editOrder?.lineItems) as CreateAVLineItem[],
    },
    orderId: editOrder?.orderId ?? nextOrderId,
    programManager: editOrder?.programManager?._id ?? undefined,
    enteredBy: editUser?._id ?? user?._id,
    startDate,
    startTime,
    endDate,
    setupTime,
    endTime,
    localTimeZone: editOrder?.localTimeZone,
    venue: {
      name: editOrder?.venue?.name ?? '',
      address: editOrder?.venue?.address ?? '',
      suiteNum: editOrder?.venue?.suiteNum ?? '',
      city: editOrder?.venue?.city ?? '',
      state: editOrder?.venue?.state ?? '',
      zip: editOrder?.venue?.zip ?? '',
      roomName: editOrder?.venue?.roomName ?? '',
      reservationName: editOrder?.venue?.reservationName ?? '',
      venueContact: {
        phone: editOrder?.venue?.venueContact?.phone ?? '',
        name: editOrder?.venue?.venueContact?.name ?? '',
      },
    },
    onSiteContact: {
      phone: editOrder?.onSiteContact?.phone ?? '',
      name: editOrder?.onSiteContact?.name ?? '',
    },
    comments: editOrder?.comments ?? '',
    attendeeCount: editOrder?.attendeeCount ?? undefined,
    speakerName: editOrder?.speakerName ?? '',
    customer: user?.customer,
    endClient: editOrder?.endClient._id ?? '',
    rushFee: editOrder?.rushFee ?? 0,
    metadata: avOrderFormFields.reduce((prev, current) => {
      if (!current.metadataKey) {
        return { ...prev };
      }
      if (current.inputType === InputTypes.DATE) {
        const dateValue = dayjs(editOrder?.metadata?.[current.metadataKey])
          .set('hour', 0)
          .set('minute', 0)
          .format('YYYY-MM-DD');

        return {
          ...prev,
          [current.metadataKey]: dateValue,
        };
      }
      if (!editOrder?.metadata) {
        return { ...prev, [current.metadataKey]: '' };
      }

      return {
        ...prev,
        [current.metadataKey]: editOrder.metadata[current.metadataKey],
      };
    }, {}),
  };
};

export const getWebConfOrderDefaultValues = (
  nextOrderId: string,
  avOrderFormFields: AVOrderFormField[],
  user: AVCustomer | null,
  editOrder?: AVOrder,
) => {
  const editUser = editOrder?.enteredBy as unknown as AVCustomer;

  let startDate = undefined;
  let startTime = undefined;
  let endDate = undefined;
  let endTime = undefined;
  if (editOrder?.startDate) {
    startDate = dayjs(editOrder?.startDate, 'YYYY-MM-DD').toDate();

    if (editOrder?.startTime) {
      const startTimeArr = editOrder?.startTime.toString().split(':');
      startTime = dayjs(editOrder?.startDate, 'YYYY-MM-DD')
        .set('hour', Number(startTimeArr[0]))
        .set('minute', Number(startTimeArr[1]))
        .toDate();
    }
  }

  if (editOrder?.endDate) {
    endDate = editOrder?.endDate
      ? dayjs(editOrder?.endDate, 'YYYY-MM-DD').toDate()
      : undefined;

    if (editOrder?.endTime) {
      const endTimeArr = editOrder?.endTime.toString().split(':');
      endTime = dayjs(editOrder?.endDate, 'YYYY-MM-DD')
        .set('hour', Number(endTimeArr[0]))
        .set('minute', Number(endTimeArr[1]))
        .toDate();
    }
  }

  return {
    type: AVOrderType.WEB_CONF,
    orderId: editOrder?.orderId ?? nextOrderId,
    enteredBy: editUser?._id ?? user?._id,
    lineItems: undefined,
    hasAdditionalVenues: editOrder?.hasAdditionalVenues
      ? PolarSelect.YES
      : PolarSelect.NO,
    startDate,
    startTime,
    endDate,
    endTime,
    localTimeZone: editOrder?.localTimeZone,
    venue: {
      city: editOrder?.venue?.city ?? '',
      state: editOrder?.venue?.state ?? '',
      zip: editOrder?.venue?.zip ?? '',
    },
    onSiteContact: {
      phone: editOrder?.onSiteContact?.phone ?? '',
      name: editOrder?.onSiteContact?.name ?? '',
    },
    comments: editOrder?.comments ?? '',
    attendeeCount: editOrder?.attendeeCount ?? undefined,
    speakerName: editOrder?.speakerName ?? '',
    customer: user?.customer,
    endClient: editOrder?.endClient._id ?? '',
    rushFee: editOrder?.rushFee ?? 0,
    basePrice: editOrder?.basePrice ?? WEBCONF_BASE_PRICE,
    metadata: avOrderFormFields.reduce((prev, current) => {
      if (!current.metadataKey) {
        return { ...prev };
      }
      if (current.inputType === InputTypes.DATE) {
        const dateValue = dayjs(
          editOrder?.metadata?.[current.metadataKey],
          'YYYY-MM-DD',
        )
          .set('hour', 0)
          .set('minute', 0)
          .toString();
        return {
          ...prev,
          [current.metadataKey]: dateValue,
        };
      }
      if (!editOrder?.metadata) {
        return { ...prev, [current.metadataKey]: undefined };
      }

      return {
        ...prev,
        [current.metadataKey]: editOrder.metadata[current.metadataKey],
      };
    }, {}),
  };
};

export const generateAVOrderFormValidation = (
  fields: AVOrderFormFieldCommonPayload[],
  isProgramManagerEnabled?: boolean,
) => {
  const validationsFields: { [key: string]: any } = {};
  fields?.forEach((val) => {
    const validation = val.validation as keyof typeof DYNAMIC_FIELD_VALIDATION;
    let scheme = DYNAMIC_FIELD_VALIDATION[validation] ?? yup.string();

    if (val.enableCondition && val.condition) {
      scheme = scheme.when(val.condition.field, {
        is: (value: any) => {
          switch (val?.condition?.operator) {
            case ConditionalOperators.EQUALS:
              return value === val.condition.value;
            case ConditionalOperators.NOT_EQUALS:
              return value !== val.condition.value;
            default:
              return false;
          }
        },
        then: (e) =>
          val.required
            ? e.required(OrderValidationErrors.FIELD_REQUIRED)
            : e.optional(),
        otherwise: (scheme) => {
          return scheme.optional();
        },
      });
    } else {
      scheme = val.required
        ? scheme.required(OrderValidationErrors.FIELD_REQUIRED)
        : scheme.optional();
    }

    validationsFields[val.metadataKey] = scheme;
  });

  return yup.object({
    metadata: yup.object(validationsFields),
    programManager: yup.string().when([], {
      is: () => isProgramManagerEnabled,
      then: (schema) => schema.required(OrderValidationErrors.FIELD_REQUIRED),
      otherwise: (schema) => schema.optional(),
    }),
  }) as any;
};

export const filterItemsAndBundles = (lineItems: AVLineItem[]) => {
  const filteredItems: CreateAVLineItem[] = [];
  const filteredBundles: CreateAVLineItem[] = [];
  lineItems.forEach((lineItem) => {
    if (lineItem.item?._id) {
      filteredItems.push({
        item: lineItem.item?._id ?? '',
        quantity: lineItem.item?._id ? lineItem.quantity : 0,
        price: lineItem.item?._id ? lineItem.price : 0,
      });
    }

    if (lineItem.bundle?._id) {
      filteredBundles.push({
        bundle: lineItem.bundle?._id ?? '',
        quantity: lineItem.bundle?._id ? lineItem.quantity : 0,
        price: lineItem.bundle?._id ? lineItem.price : 0,
      });
    }
  });
  return { filteredItems, filteredBundles };
};

export const getFormBasicValues = (key: string, order: AVOrder) => {
  if (key === 'enteredBy') {
    const enteredBy = order?.enteredBy as unknown as AVCustomer;
    return `${enteredBy?.firstName} ${enteredBy?.lastName}`;
  }
  if (key === 'endClient') {
    return _.get(order, 'endClient.name');
  }
  return _.get(order, key);
};

export const convertEnumToString = (enumValue: string) => {
  return enumValue
    .toLowerCase()
    .split('_')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

export const getSectionWiseFormFields = (
  avEntityOrdersFormFields: AVEntityOrderFormField[],
) => {
  const { EVENT_PROFILE, CONTACTS, EVENT_DETAILS } = AVEntityOrderFormSections;
  const eventProfileFields = avEntityOrdersFormFields
    .filter(({ section }) => section === EVENT_PROFILE)
    .map(({ formField }) => formField);

  const contactFields = avEntityOrdersFormFields
    .filter(({ section }) => section === CONTACTS)
    .map(({ formField }) => formField);

  const eventDetailFields = avEntityOrdersFormFields
    .filter(({ section }) => section === EVENT_DETAILS)
    .map(({ formField }) => formField);

  return {
    eventProfileFields,
    contactFields,
    eventDetailFields,
  };
};

export const isRushOrder = (
  customer: CustomerBase,
  startDate: Date,
  startTime: Date,
  localTimeZone: string,
) => {
  const { rushOrderCutOff } = customer;

  let orderStartDateTime = dayjs.tz(startDate, 'YYYY-MM-DD', localTimeZone);
  const hours = startTime.getHours();
  const minutes = startTime.getMinutes();
  orderStartDateTime = orderStartDateTime
    .set('hour', hours)
    .set('minute', minutes);

  let rushOrderHours = 24;
  if (orderStartDateTime.day() === 1) {
    // Monday
    rushOrderHours = 72;
  } else if (orderStartDateTime.day() === 0) {
    // Sunday
    rushOrderHours = 48;
  }

  let isRushOrder = false;
  if (rushOrderCutOff.type === '24_HOURS_PRIOR') {
    const cutOffTime = dayjs().add(rushOrderHours, 'hours');
    isRushOrder = orderStartDateTime.isBefore(cutOffTime);
  } else {
    const rushFeeTime = rushOrderCutOff.at.split(':');
    const cutOffTime = orderStartDateTime
      .clone()
      .subtract(rushOrderHours, 'hours')
      .set('hour', Number(rushFeeTime[0] || 0))
      .set('minute', Number(rushFeeTime[1] || 0));
    isRushOrder = timezonedDayjs(
      rushOrderCutOff.tz || DEFAULT_TIMEZONE,
    ).isAfter(cutOffTime);
  }
  return isRushOrder;
};

export const calculateWebConfOrderPrices = (
  basePrice: number,
  attendeeCount: number,
  startDate: string | Date,
  startTime: string | Date,
  endDate: string | Date,
  endTime: string | Date,
) => {
  let start;
  if (typeof startTime === 'string') {
    const timeArr = startTime.split(':');
    start = dayjs(startDate)
      .set('hour', Number(timeArr[0]))
      .set('minute', Number(timeArr[1]));
  } else {
    start = dayjs(startDate)
      .set('hour', startTime.getHours())
      .set('minute', startTime.getMinutes());
  }

  let end;
  if (typeof endTime === 'string') {
    const timeArr = endTime.split(':');
    end = dayjs(endDate)
      .set('hour', Number(timeArr[0]))
      .set('minute', Number(timeArr[1]));
  } else {
    end = dayjs(endDate)
      .set('hour', endTime.getHours())
      .set('minute', endTime.getMinutes());
  }

  let total = basePrice;
  const duration = end.diff(start, 'minute') / 60;
  let additionalHours = Math.max(0, duration - WEBCONF_HOURS_INCLUDED);
  if (additionalHours > 0 && additionalHours % 0.5 !== 0) {
    additionalHours += 0.5 - (additionalHours % 0.5);
  }
  let additionalDuration = 0;
  let additionalDurationPrice = 0;
  let additionalAttendees = 0;
  let additionalAttendeesPrice = 0;

  if (additionalHours > 0) {
    additionalDuration = additionalHours;
    additionalDurationPrice =
      additionalHours * (WEBCONF_ADDIONAL_DURATION_PRICE * 2);
    total += additionalDurationPrice;
  }

  const extraAttendees = attendeeCount - WEBCONF_ATTENDEES_INCLUDED;
  if (extraAttendees > 0) {
    additionalAttendees = extraAttendees;
    additionalAttendeesPrice =
      extraAttendees * WEBCONF_ADDIONAL_ATTENDEE_PRICE * duration;
    total += additionalAttendeesPrice;
  }

  return {
    total,
    additionalDurationPrice,
    additionalDuration,
    additionalAttendees,
    additionalAttendeesPrice,
  };
};

export const formVenueValues = (
  key: string,
  states: any[],
  formValues: any,
) => {
  if (key === 'venue.state' || key === 'venue.state.name') {
    const state = states.find(
      (s) => s._id === _.get(formValues, 'venue.state'),
    );
    return state?.name;
  }
  return _.get(formValues, key);
};

export const removeTrailingSlash = (input: string) => {
  return input.endsWith('/') ? input.slice(0, -1) : input;
};
