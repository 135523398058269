/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState } from 'react';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import { MdDeleteOutline } from 'react-icons/md';
import { ReactComponent as CopyIcon } from 'assets/copy.svg';
import { ReactComponent as EditPencilIcon } from 'assets/edit_Pencil_02.svg';
import { ReactComponent as DownloadIcon } from 'assets/orderSubmission/Interface/Download.svg';
import classNames from 'classnames';
import HMenu from 'components/HMenu';
import Checkbox from 'components/Inputs/Checkbox';
import Loader from 'components/Loaders';
import Pagination from 'components/Pagination';
import { DEFAULT_TIMEZONE } from 'constants/timezone';
import dayjs from 'dayjs';
import { getProperty } from 'dot-prop';
import usePagination from 'hooks/usePagination';
import { AVOrder } from 'types/av-orders';
import { AV_ORDER_STATUS } from 'utils/common.enum';

import './styles.css';

interface IOrderTable {
  data: any[];
  isLoading: boolean;
  label: string;
  description: string;
  isChecked?: boolean;
  enableSorting?: boolean;
  onCreate?: () => void;
  onEdit?: (orderId: string) => void;
  onCopy?: (orderId: string) => void;
  downloadPDF?: (order: AVOrder) => void;
  onCancel?: (orderId: string) => void;
  onClickCheckbox?: (flag: boolean) => void;
  state?: { sorting?: { id: string; desc: boolean }[] };
  columns: {
    sortingFn?: string;
    renderDiv?: (row: any) => any;
    label: string;
    fieldName: string;
    minWidth?: number;
  }[];
}

const DataTable: React.FC<IOrderTable> = ({
  data,
  isLoading,
  columns,
  isChecked,
  state,
  enableSorting = true,
  onEdit,
  downloadPDF,
  onCopy,
  onCancel,
  onClickCheckbox,
}) => {
  const {
    currentPage,
    pageSize,
    totalPages,
    handlePageChange,
    handlePageSizeChange,
    getCurrentPageData,
  } = usePagination({ totalSize: data.length, initialPageSize: 10 });

  const [sorting, setSorting] = useState(state?.sorting ?? undefined);

  const sortedData = React.useMemo(() => {
    if (sorting) {
      // sorting is an array of objects with id and desc properties
      // we need to sort the data array based on these properties
      return data.sort((a, b) => {
        for (const sort of sorting) {
          const columnDef = columns.find((col) => col.fieldName === sort.id);

          // get the value of the property from the data object
          // getProperty will handle nested properties
          const aVal = getProperty(a, sort.id) as string;
          const bVal = getProperty(b, sort.id) as string;

          // handle sorting based on the type of the column
          // if the column has a sortingFn property, use that to sort
          if (columnDef?.sortingFn === 'datetime') {
            if (aVal === undefined && bVal === undefined) return 0;
            if (bVal === undefined || new Date(aVal) < new Date(bVal)) {
              return sort.desc ? 1 : -1;
            }
            if (aVal === undefined || new Date(aVal) > new Date(bVal)) {
              return sort.desc ? -1 : 1;
            }
          }

          if (aVal < bVal) {
            return sort.desc ? 1 : -1;
          }
          if (aVal > bVal) {
            return sort.desc ? -1 : 1;
          }
        }
        return 0;
      });
    }
    return currentPageData;
  }, [data, sorting]);

  const currentPageData = getCurrentPageData(sortedData);

  const menuItems = useCallback(
    (item: any) => {
      const items = [];
      if (item.orderStatus !== AV_ORDER_STATUS.ON_HOLD) {
        items.push({
          title: 'New from copy',
          icon: <CopyIcon className='cursor-pointer w-6 h-6' />,
          onClick: onCopy ? () => onCopy(item?._id) : undefined,
        });
      }

      const tz =
        item?.localTimeZone !== '' ? item?.localTimeZone : DEFAULT_TIMEZONE;
      const now = Date.now();
      const orderDate = dayjs(
        `${item?.startDate} ${item?.startTime}`,
        'YYYY-MM-DD HH:mm',
      )
        .tz(tz)
        .toDate()
        .getTime();
      const isPast = orderDate < now;

      if (
        ([
          AV_ORDER_STATUS.COMPLETED,
          AV_ORDER_STATUS.CANCELLED,
          AV_ORDER_STATUS.BILLING_ERROR,
        ].includes(item.orderStatus) === false &&
          !isPast) ||
        item.orderStatus === AV_ORDER_STATUS.ON_HOLD
      ) {
        items.push({
          title: 'Edit',
          icon: <EditPencilIcon className='cursor-pointer w-6 h-6' />,
          onClick: onEdit ? () => onEdit(item?._id) : undefined,
        });
      }

      if (
        [
          AV_ORDER_STATUS.COMPLETED,
          AV_ORDER_STATUS.CANCELLED,
          AV_ORDER_STATUS.BILLING_ERROR,
        ].includes(item.orderStatus) === false &&
        !isPast
      ) {
        items.push({
          title: 'Cancel Order',
          icon: <MdDeleteOutline size={23} className='cursor-pointer ' />,
          onClick: onCancel ? () => onCancel(item?._id) : undefined,
        });
      }

      if (
        [AV_ORDER_STATUS.ON_HOLD, AV_ORDER_STATUS.BILLING_ERROR].includes(
          item.orderStatus,
        ) === false
      ) {
        if (item.localTimeZone !== '') {
          items.push({
            title: 'Download Invoice',
            icon: <DownloadIcon className='cursor-pointer w-6 h-6' />,
            onClick: downloadPDF ? () => downloadPDF(item) : undefined,
          });
        }
      }
      return items;
    },
    [downloadPDF, onCancel, onCopy, onEdit],
  );

  const handleSorting = useCallback(
    (id: string) => {
      const existingSort = sorting?.find((sort) => sort.id === id);
      if (!existingSort) {
        setSorting([{ id, desc: false }]);
      } else {
        setSorting([{ id, desc: !existingSort.desc }]);
      }
    },
    [sorting],
  );

  const renderTableBody = useCallback(() => {
    return (
      <tbody className='bg-white'>
        {currentPageData.map((item) => {
          return (
            <tr key={item._id} className=' border-y border-gray-300'>
              {columns.map((col, idx) => (
                <td
                  style={{ minWidth: col.minWidth }}
                  key={col.fieldName}
                  className={classNames(
                    'whitespace-nowrap pl-4 pr-3 text-sm font-normal text-gray-950 h-fit sm:pl-3',
                    {
                      ' text-green-950 !font-bold': idx === 0,
                    },
                  )}
                >
                  {col.renderDiv ? col.renderDiv(item) : item[col.fieldName]}
                </td>
              ))}

              <td className='whitespace-nowrap flex justify-center pt-5 pl-4 pr-3 text-sm font-medium gap-x-1 text-gray-900 sm:pl-3'>
                {menuItems(item).length ? (
                  <HMenu items={menuItems(item)} />
                ) : null}
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }, [columns, currentPageData, menuItems]);

  if (isLoading) {
    return (
      <div className='h-full w-screen flex justify-center items-center'>
        <Loader />
      </div>
    );
  }

  return (
    <div className='px-4 sm:px-6 lg:px-8 h-[calc(100%-90px)]'>
      <div className='mt-4 h-[calc(100%-80px)] overflow-y-auto min-w-full border border-gray-300 rounded-t-xl flex flex-col justify-between'>
        <table className='min-w-full'>
          <thead>
            <tr className='bg-slate border-gray-300'>
              {columns.map(({ label, fieldName }, index) => {
                const sort = sorting?.find((sort) => sort.id === fieldName);
                return (
                  <th
                    key={fieldName}
                    scope='col'
                    className='py-1 pl-4 pr-3 text-left text-xs font-medium text-gray-600 sm:pl-3'
                  >
                    <div className='flex items-center gap-2'>
                      {onClickCheckbox && index === 0 ? (
                        <Checkbox
                          name='all-rows-selector'
                          checked={isChecked ?? false}
                          onChange={(e) => onClickCheckbox(e.target.checked)}
                        />
                      ) : null}
                      <button
                        onClick={() => handleSorting(fieldName)}
                        className='group flex gap-2 items-center'
                      >
                        <span>{label}</span>
                        {enableSorting ? (
                          <span
                            className={classNames(
                              'text-gray-400 opacity-0 group-hover:opacity-100 transition-all duration-200 ease-in-out',
                              { 'opacity-100 text-gray-900': !!sort },
                            )}
                          >
                            {sort?.desc ? <FiArrowDown /> : <FiArrowUp />}
                          </span>
                        ) : null}
                      </button>
                    </div>
                  </th>
                );
              })}

              {onEdit || onCancel || onCopy || downloadPDF ? (
                <th className='py-1 pl-4 pr-3 text-left text-xs font-medium text-gray-600 sm:pl-3'>
                  Actions
                </th>
              ) : null}
            </tr>
          </thead>
          {data?.length ? renderTableBody() : null}
        </table>
        {!data?.length ? (
          <div className='flex items-center mt-10 gap-x-1 justify-center w-full h-[calc(100%-150px)]'>
            <p className='text-center font-medium'>No Orders Found</p>
          </div>
        ) : null}
      </div>
      <Pagination
        totalSize={data.length}
        currentPage={currentPage}
        pageSize={pageSize}
        totalPages={totalPages}
        totalCurrentPageDate={currentPageData.length}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
      />
    </div>
  );
};

export default DataTable;
