export enum ValidationErrors {
  FISRT_NAME_REQUIRED = 'First name is required',
  LAST_NAME_REQUIRED = 'Last name is required',
  INVALID_EMAIL = 'Invalid Email Address',
  EMAIL_REQUIRED = 'Email is required',
  ROLE_REQUIRED = 'Please select a role',
  ACCOUNT_REQUIRED = 'Please select an account',
  PASSWORD = 'Password length must be at least 8 characters long and it must contain at-least one lowercase, uppercase, number and symbol.',
  CONFIRM_PASSWORD_REQUIRED = 'Confirm password is required',
  PASSWORD_NOT_MATCH = 'Your passwords does not matched',
}
