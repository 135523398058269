/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { ChangeEvent } from 'react';
import { IoAdd, IoTvOutline } from 'react-icons/io5';
import { ReactComponent as FunnelIcon } from 'assets/filter.svg';
import { ReactComponent as ExportIcon } from 'assets/share_iOS_Export.svg';
import classNames from 'classnames';
import Button from 'components/Buttons/Index';
import { SearchBar } from 'components/Inputs/SearchBar';
import { useRecoilValue } from 'recoil';
import { currentUserState } from 'state/atoms';
import { CustomerBase } from 'types/av-customer';

import './styles.css';

interface IOrderToolbar {
  searchQuery: string | undefined;
  isFilterApplied: boolean;
  label?: string;
  customer: CustomerBase | null;
  allowExportOrders?: boolean;
  onFilter?: () => void;
  onExport?: () => void;
  onCreateOrder?: () => void;
  onCreateWebConfOrder: () => Promise<void>;
  debounceResults: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Item = ({
  title,
  isDisabled,
  showIndicator,
  func,
}: {
  title: string;
  isDisabled: boolean;
  showIndicator?: boolean;
  func?: () => void;
}) => {
  const CustomIcon = title === 'Filter' ? FunnelIcon : ExportIcon;
  return (
    <div
      className={classNames(
        'flex gap-2 items-center cursor-pointer text-base font-medium text-gray-950 relative',
        {
          '!text-gray-400 !cursor-default': isDisabled,
        },
      )}
      onClick={func && !isDisabled ? func : undefined}
    >
      <CustomIcon
        className='custom-icon-style w-5 h-5'
        aria-disabled={isDisabled}
      />
      <p>{title}</p>
      {showIndicator && (
        <span className='w-2 h-2 bg-green-950 rounded-full absolute top-0 -left-1' />
      )}
    </div>
  );
};

const OrderToolbar: React.FC<IOrderToolbar> = ({
  searchQuery,
  label,
  isFilterApplied,
  allowExportOrders,
  customer,
  onFilter,
  onExport,
  onCreateOrder,
  debounceResults,
  onCreateWebConfOrder,
}) => {
  const currentUser = useRecoilValue(currentUserState);
  return (
    <div className='flex gap-4 flex-col px-8'>
      <h1 className='text-2xl font-semibold'>{`Welcome, ${
        currentUser?.firstName ?? 'User'
      }`}</h1>
      {label ? (
        <h2 className='text-2xl font-medium text-gray-950'>{label}</h2>
      ) : null}
      <div className='sm:flex sm:items-center justify-between'>
        <SearchBar
          defaultValue={searchQuery}
          placeholder='Search by Order ID, Status, Venue...'
          inputClass='w-2/4'
          containerClass='w-2/4'
          onChange={debounceResults}
        />
        <div className='flex gap-5 items-center'>
          <Item
            title='Filter'
            func={onFilter}
            isDisabled={!onFilter}
            showIndicator={isFilterApplied}
          />
          <Item
            title='Export'
            func={onExport}
            isDisabled={allowExportOrders === false}
          />
          <Button
            disabled={!onCreateOrder}
            onClick={onCreateOrder}
            label='Create Order'
            className='!bg-green-950 !py-2 rounded-xl'
            startIcon={IoAdd}
          />

          {customer?.enableWebConfOrders && (
            <Button
              variant='outlined'
              disabled={!onCreateWebConfOrder}
              onClick={onCreateWebConfOrder}
              label='Create Web Conf Order'
              className='!text-green-950 !py-2 rounded-xl -ml-3'
              startIcon={IoAdd}
            />
          )}

          <Button
            variant='outlined'
            onClick={() =>
              window?.open('https://vimeo.com/958510265', '_blank')?.focus()
            }
            label='Portal Training Video'
            className='!text-green-950 !py-2 rounded-xl -ml-3'
            startIcon={IoTvOutline}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderToolbar;
