/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines */
import React, { useCallback, useMemo } from 'react';
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import classNames from 'classnames';
import { TextArea } from 'components/Inputs/TextArea';
import { DEFAULT_TIMEZONE } from 'constants/timezone';
import { useRecoilValue } from 'recoil';
import { currentUserState } from 'state/atoms';
import { AVCustomer } from 'types/av-customer';
import { AVOrderFormField } from 'types/av-form-fields';
import {
  AVOrder,
  CreateAVOrder,
  EndClient,
  GoogleTimeZone,
  ProgramManagerData,
  State,
} from 'types/av-orders';
import { getFormatedDate, getFormatedTime } from 'utils/dayjs';
import { renderConditionalInput } from 'utils/form-fields';

import OrderBasicForm from '../OrderBasicForm';
import OrderContactForm from '../OrderContactForm';
import OrderDateTimeForm from '../OrderDateTimeForm';
import OrderEventDetailForm from '../OrderEventDetailForm';
import OrderVenueForm from '../OrderVenueForm';
import { InfoBox, MainHeading } from '../UtilComponents';

interface IFirstStep {
  states: State[];
  isLoading: boolean;
  endClients: EndClient[];
  editOrder: AVOrder | undefined;
  keysToCopy?: string[];
  control: Control<CreateAVOrder>;
  googleTimezone: GoogleTimeZone | null;
  avOrdersFormFields: AVOrderFormField[];
  eventProfileDynamicFields: AVOrderFormField[];
  contactDynamicFields: AVOrderFormField[];
  eventDetailFormFields: AVOrderFormField[];
  isWithinCancellationPolicy: boolean;
  watch: UseFormWatch<CreateAVOrder>;
  setValue: UseFormSetValue<CreateAVOrder>;
  programManagerData: ProgramManagerData | undefined;
}

const FirstStep: React.FC<IFirstStep> = ({
  states,
  control,
  isLoading,
  keysToCopy,
  editOrder,
  endClients,
  googleTimezone,
  contactDynamicFields,
  eventDetailFormFields,
  eventProfileDynamicFields,
  isWithinCancellationPolicy,
  programManagerData,
  watch,
  setValue,
}) => {
  const formValues = watch();
  const timezone = formValues?.localTimeZone ?? DEFAULT_TIMEZONE;
  const currentUser = useRecoilValue(currentUserState);
  const infoClasses = classNames('text-gray-500 text-sm font-normal');

  const renderDynamicField = useCallback(
    (field: AVOrderFormField) => {
      const { _id, label, metadataKey, inputType } = field;
      const value = formValues?.metadata?.[metadataKey as keyof CreateAVOrder];

      const isNotMatched = renderConditionalInput(
        field.condition,
        field.enableCondition,
        formValues?.metadata,
      );

      if (isNotMatched) return null;

      if (inputType === 'date') {
        return (
          <p key={_id} className={infoClasses}>
            {label}: {getFormatedDate(value)}
          </p>
        );
      }
      return (
        <p key={_id} className={infoClasses}>
          {label}: {value}
        </p>
      );
    },
    [formValues?.metadata, infoClasses],
  );

  const editUser = editOrder?.enteredBy as unknown as AVCustomer;

  const selectedProgramManager = useMemo(() => {
    const managers = programManagerData?.programManagers ?? [];
    const data = managers?.find((manager) => {
      return formValues?.programManager === manager?._id;
    });

    return data ? `${data?.firstName} ${data?.lastName}` : 'N/A';
  }, [formValues?.programManager, programManagerData]);

  const state = useMemo(
    () => states.find((s) => s._id === formValues?.venue?.state),
    [formValues?.venue?.state, states],
  );

  return (
    <>
      <div className='flex-[0.6] overflow-y-auto'>
        <MainHeading title='Event Profile' classNames='mb-1' />
        <OrderBasicForm
          control={control as any}
          isLoading={isLoading}
          editOrder={editOrder}
          keysToCopy={keysToCopy}
          endClients={endClients}
          currentUser={currentUser}
          programManagerData={programManagerData}
          dynamicFormFields={eventProfileDynamicFields}
          setValue={setValue}
        />
        <OrderVenueForm control={control} states={states} setValue={setValue} />
        <OrderDateTimeForm
          editOrder={editOrder}
          googleTimezone={googleTimezone}
          control={control}
          watch={watch}
          setValue={setValue}
          formValues={formValues}
          isWithinCancellationPolicy={isWithinCancellationPolicy}
        />

        <OrderContactForm
          control={control}
          setValue={setValue}
          isLoading={isLoading}
          dynamicFormFields={contactDynamicFields}
        />

        {eventDetailFormFields?.length ? (
          <OrderEventDetailForm
            control={control}
            setValue={setValue}
            isLoading={isLoading}
            dynamicFormFields={eventDetailFormFields}
          />
        ) : null}

        <div className='w-full mt-4'>
          <Controller
            control={control}
            name='comments'
            render={({ fieldState: { error }, field }) => (
              <TextArea
                description='Please provide comments or special instructions for the on-site planner or AV technician.'
                placeholder='Unique room set-up details, meeting type, tech arrival instructions, venue-provided AV equipment, access limitations, etc.'
                value={field.value?.toString() ?? ''}
                error={error}
                onChange={field.onChange}
                label={'Order Comments'}
                name={field.name}
                optional
              />
            )}
          />
        </div>
      </div>
      <div className='flex-[0.4] bg-stepperBg rounded-2xl p-6 overflow-y-auto flex flex-col gap-4'>
        <MainHeading title='Summary' />
        <InfoBox title='Event Profile'>
          <>
            <p className={infoClasses}>Order ID: {formValues?.orderId}</p>
            <p className={infoClasses}>
              Entered By:{' '}
              {editOrder
                ? `${editUser?.firstName} ${editUser?.lastName}`
                : `${currentUser?.firstName} ${currentUser?.lastName}`}
            </p>
            <p className={infoClasses}>
              End Client:{' '}
              {formValues?.endClient
                ? endClients.find((ec) => ec._id === formValues?.endClient)
                    ?.name
                : ''}
            </p>
            <p className={infoClasses}>
              Attendee Count: {formValues?.attendeeCount}
            </p>
            <p className={infoClasses}>
              Speaker Name: {formValues?.speakerName}
            </p>

            <p className={infoClasses}>
              Program Manager: {selectedProgramManager}
            </p>

            {eventProfileDynamicFields.map((field) =>
              renderDynamicField(field),
            )}
          </>
        </InfoBox>
        <InfoBox title='Venue Information'>
          <>
            <p className={infoClasses}>Venue Name: {formValues?.venue?.name}</p>
            <p className={infoClasses}>Address: {formValues?.venue?.address}</p>
            <p className={infoClasses}>
              Suite #: {formValues?.venue?.suiteNum}
            </p>
            <p className={infoClasses}>City: {formValues?.venue?.city}</p>
            <p className={infoClasses}>State: {state?.name ?? ''}</p>
            <p className={infoClasses}>Country: {state?.country ?? ''}</p>
            <p className={infoClasses}>Zip: {formValues?.venue?.zip}</p>
            <p className={infoClasses}>
              Room Name: {formValues?.venue?.roomName}
            </p>
            <p className={infoClasses}>
              Reservation Name: {formValues?.venue?.reservationName}
            </p>
          </>
        </InfoBox>
        <InfoBox title='Date & Time'>
          <>
            <p className={infoClasses}>
              Local Timezone: {formValues?.localTimeZone}
            </p>
            <p className={infoClasses}>
              Local Start Date: {getFormatedDate(formValues?.startDate)}
            </p>
            <p className={infoClasses}>
              Local End Date: {getFormatedDate(formValues?.endDate)}
            </p>
            <p className={infoClasses}>
              Local Start Time:{' '}
              {formValues?.startTime
                ? getFormatedTime(formValues?.startTime, timezone)
                : ''}
            </p>
            <p className={infoClasses}>
              Local End Time:{' '}
              {formValues?.endTime
                ? getFormatedTime(formValues?.endTime, timezone)
                : ''}
            </p>
            <p className={infoClasses}>
              Local Setup Time:{' '}
              {formValues?.setupTime
                ? getFormatedTime(formValues?.setupTime, timezone)
                : ''}
            </p>
          </>
        </InfoBox>
        <InfoBox title='Contacts'>
          <>
            <p className='text-gray-600 text-sm font-medium '>Venue Contact</p>
            <p className={infoClasses}>
              Full Name: {formValues?.venue?.venueContact.name}
            </p>
            <p className={infoClasses}>
              Phone Number: {formValues?.venue?.venueContact.phone}
            </p>
            <p className='text-gray-600 text-sm font-medium mt-2'>
              On-Site Contact
            </p>
            <p className={infoClasses}>
              Full Name: {formValues?.onSiteContact.name}
            </p>
            <p className={infoClasses}>
              Phone Number: {formValues?.onSiteContact.phone}
            </p>

            {contactDynamicFields.map((field) => renderDynamicField(field))}
          </>
        </InfoBox>
        <InfoBox title='Event Details'>
          <>
            {eventDetailFormFields.map((field) => renderDynamicField(field))}
            <p className={infoClasses}>
              Comments: {formValues?.comments || ''}
            </p>
          </>
        </InfoBox>
      </div>
    </>
  );
};

export default FirstStep;
