import { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { isQueryMatched } from 'services/filter';
import { AVOrderFormField } from 'types/av-form-fields';
import { AVOrder } from 'types/av-orders';
import { IFilterOrder } from 'types/filter';

export const useOrderFilter = () => {
  const [advanceFilters, setAdvanceFilters] = useState<IFilterOrder>({});
  const [localSearchQuery, setLocalSearchQuery] = useState('');
  const [avOrders, setAVOrders] = useState<AVOrder[]>([]);
  const [avFilteredOrders, setFilteredAVOrders] = useState<AVOrder[]>([]);
  const [dashboardFormFields, setDashboardFormFields] = useState<
    AVOrderFormField[]
  >([]);

  const isFilterApplied = useMemo(() => {
    const filters = { ...advanceFilters };
    return !Object.values(filters).every(isEmpty);
  }, [advanceFilters]);

  const onSetAdvanceFilters = useCallback((newFilters?: IFilterOrder) => {
    setAdvanceFilters(newFilters ?? {});
  }, []);

  const onSetSearchFilter = useCallback((search: string) => {
    setLocalSearchQuery(search);
  }, []);

  const searchByInputTextLocally = useCallback(() => {
    if (!localSearchQuery) return avOrders;

    return avOrders.filter((order) =>
      isQueryMatched(
        order,
        dashboardFormFields,
        localSearchQuery?.toLowerCase(),
      ),
    );
  }, [avOrders, dashboardFormFields, localSearchQuery]);

  useEffect(() => {
    const filtered = searchByInputTextLocally();
    setFilteredAVOrders(filtered);
  }, [searchByInputTextLocally]);

  return useMemo(
    () => ({
      advanceFilters,
      localSearchQuery,
      isFilterApplied,
      avFilteredOrders,
      dashboardFormFields,
      setAVOrders,
      onSetSearchFilter,
      onSetAdvanceFilters,
      setFilteredAVOrders,
      setDashboardFormFields,
    }),
    [
      advanceFilters,
      avFilteredOrders,
      dashboardFormFields,
      isFilterApplied,
      localSearchQuery,
      onSetAdvanceFilters,
      onSetSearchFilter,
    ],
  );
};
