import { LOCAL_CONSTANT } from 'constants/auth';
import { atom, AtomEffect } from 'recoil';
import { AVCustomer, CustomerBase } from 'types/av-customer';

export const localStorageEffect: <T>(
  key: string,
  stringify?: boolean,
) => AtomEffect<T> =
  (key: string, stringify = true) =>
  ({ onSet, setSelf }) => {
    const savedValue = localStorage.getItem(key);
    // eslint-disable-next-line eqeqeq
    if (savedValue != null) {
      setSelf(stringify ? JSON.parse(savedValue) : savedValue);
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(
            key,
            stringify
              ? JSON.stringify(newValue)
              : (newValue as unknown as string),
          );
    });
  };

export const currentUserState = atom<AVCustomer | null>({
  key: LOCAL_CONSTANT.AV_CUSTOMER,
  default: null,
  effects: [],
});

export const currentOrderTaxRate = atom<number>({
  key: 'CURRENT_ORDER_TAX_RATE',
  default: 0,
  effects: [],
});

export const currentCustomerState = atom<CustomerBase | null>({
  key: 'CURRENT_CUSTOMER',
  default: null,
  effects: [],
});

export const authState = atom<string | null>({
  key: LOCAL_CONSTANT.USER_TOKEN,
  default: '',
  effects: [
    localStorageEffect<string | null>(LOCAL_CONSTANT.USER_TOKEN, false),
  ],
});
