export enum Timezones {
  EASTERN = 'Eastern',
  PACIFIC = 'Pacific',
  LOCAL = 'Local',
}
export const TimezoneCodes = {
  Eastern: 'America/New_York',
  Pacific: 'America/Los_Angeles',
  Local: 'Local',
};

export const TimezoneShortForm = {
  [TimezoneCodes.Eastern]: 'EST',
  [TimezoneCodes.Pacific]: 'PST',
};

export const DEFAULT_TIMEZONE = TimezoneCodes[Timezones.EASTERN];
