import { Fragment } from 'react';
import { ReactComponent as HEllipsisIcon } from 'assets/horiz_ellipsis.svg';
import classNames from 'classnames';

import { Menu, Transition } from '@headlessui/react';

interface IHMenu {
  items: {
    title: string;
    icon: JSX.Element;
    onClick: (() => void) | undefined;
  }[];
}

const HMenu = ({ items }: IHMenu) => {
  return (
    <div className='w-full'>
      <Menu as='div' className='relative inline-block text-left'>
        <div>
          <Menu.Button>
            <HEllipsisIcon
              className='-mr-1 ml-2 h-5 w-5 text-gray-950'
              aria-hidden='true'
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='absolute right-0 mt-2 w-fit z-10 p-2 rounded-lg origin-top-right divide-y divide-gray-100 bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
            <div className='px-1 py-1 '>
              {items.map((item) =>
                item.onClick ? (
                  <Menu.Item key={item.title}>
                    {({ active }) => (
                      <button
                        className={classNames(
                          'group flex w-full items-center rounded-md px-2 py-2 text-sm text-gray-900 gap-2',
                          {
                            'bg-gray-100': active,
                          },
                        )}
                        onClick={item.onClick}
                      >
                        {item.icon}
                        {item.title}
                      </button>
                    )}
                  </Menu.Item>
                ) : null,
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default HMenu;
