import React from 'react';
import { ReactComponent as InformationCircleIcon } from 'assets/footer/information_circle_outline.svg';

const Footer: React.FC = () => {
  return (
    <div className='flex justify-between align-middle py-4 px-20 bg-gray-850 absolute w-screen z-20'>
      <div className='flex gap-4 items-center'>
        <p className='text-sm font-normal text-white'>
          © {`${new Date().getFullYear()}`} The Inception Company
        </p>
      </div>
      <div className='flex gap-1 items-center'>
        <InformationCircleIcon className='w-4 h-4 text-white' />
        <p className='text-sm font-normal text-white'>
          For assistance please call <a href='tel:8886283678'>888-628-3678</a>
          &nbsp; or email{' '}
          <a href='mailto:LiveEventProduction@inceptioncompany.com'>
            LiveEventProduction@inceptioncompany.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
