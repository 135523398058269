/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs';
import advanced from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

export const timezonedDayjs = (timezone: string, ...args: any[]) => {
  dayjs.tz.setDefault(timezone);
  return dayjs(...args).tz();
};

export const formatDate = (
  timezone: string,
  format: string,
  ...args: any[]
) => {
  let formattedDate = '';

  const date = timezonedDayjs(timezone, ...args);

  // Check the format and format the date accordingly
  formattedDate = dayjs(date).format(format);

  return formattedDate;
};

export const getFormatedTime = (time: Date | string, timezone: string) => {
  if (!time) return '';
  const tz = timezone !== '' ? timezonedDayjs(timezone).format('z') : '';
  let dayjsTime = dayjs(time);
  if (typeof time === 'string') {
    dayjsTime = dayjs(time, 'HH:mm');
  }
  return `${dayjsTime.format('hh:mm A')} ${tz}`;
};

export const getFormatedDate = (date: Date | string | undefined) => {
  if (!date) return '';
  let dayjsDate = dayjs(date);
  if (typeof date === 'string') {
    dayjsDate = dayjs(date, 'YYYY-MM-DD');
  }
  return dayjsDate.format('MM/DD/YYYY');
};
