/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from 'assets/close_MD-1.svg';
import Button from 'components/Buttons/Index';
import Checkbox from 'components/Inputs/Checkbox';
import Modal from 'components/Modal';
import {
  EVENT_INFO_COPY_KEY_MAP,
  VENUE_INFO_COPY_KEY_MAP,
} from 'constants/order-form';
import _ from 'lodash';
import { AVOrder, State } from 'types/av-orders';
import { AvailableBundles, AvailableItems } from 'types/item-bundle';
import { getFormBasicValues } from 'utils/avUtils';
import { breakAndCapitalize, formatCurrency } from 'utils/helper';

interface ICopyProjectModal {
  states: State[];
  orderToCopy: AVOrder;
  items: AvailableItems[];
  bundles: AvailableBundles[];
  onClose: () => void;
  onCreate: (copyKeys: string[]) => void;
}

interface ISectionContainer {
  label: string;
  name: string;
  checked: boolean;
  children: JSX.Element;
  onSelect: (id: string) => void;
}

const SectionContainer = ({
  label,
  name,
  checked,
  children,
  onSelect,
}: ISectionContainer) => {
  return (
    <div className='mb-8'>
      <Checkbox
        label={label}
        name={name}
        checked={checked}
        containerClass='!py-0'
        labelClass='text-base font-medium text-gray-950'
        onChange={() => onSelect(name)}
      />
      {children}
    </div>
  );
};

export const CopyProjectModal = ({
  items,
  states,
  bundles,
  orderToCopy,
  onClose,
  onCreate,
}: ICopyProjectModal) => {
  const [selectedItems, setSelectedItems] = useState<AvailableItems[]>();
  const [selectedBundles, setSelectedBundles] = useState<AvailableBundles[]>();
  const [sectionIds, setSectionIds] = useState<string[]>([]);

  const onSelect = (id: string) => {
    if (sectionIds.includes(id)) {
      setSectionIds(sectionIds.filter((sectionId) => sectionId !== id));
    } else {
      setSectionIds([...sectionIds, id]);
    }
  };

  useEffect(() => {
    if (orderToCopy?.lineItems.length) {
      const temp: AvailableItems[] = [];
      items.map((item) => {
        orderToCopy?.lineItems.map((selectedItem) => {
          if (selectedItem && selectedItem.item?._id === item?._id) {
            temp.push({
              ...item,
              quantity: selectedItem.quantity,
              price: selectedItem.price,
            });
          }
        });
      });
      setSelectedItems(temp);
    }
  }, [orderToCopy?.lineItems, items]);

  useEffect(() => {
    if (orderToCopy?.lineItems.length) {
      const temp: AvailableBundles[] = [];
      bundles.map((bundle) => {
        orderToCopy?.lineItems.map((selectedBundle) => {
          if (selectedBundle && selectedBundle.bundle?._id === bundle?._id) {
            temp.push({
              ...bundle,
              quantity: selectedBundle.quantity,
              price: selectedBundle.price,
            });
          }
        });
      });
      setSelectedBundles(temp);
    }
  }, [bundles, orderToCopy?.lineItems]);

  const getFormVenueValues = useCallback(
    (key: string) => {
      if (key === 'venue.state') {
        const stateId = _.get(orderToCopy, key); // getting state id
        const state = states?.find((s) => s._id === stateId);
        return state;
      }
      return _.get(orderToCopy, key);
    },

    [orderToCopy, states],
  );

  return (
    <Modal className='w-[50%] h-[60vh]' open onClose={onClose}>
      <div className='flex flex-col h-full '>
        <div className='flex justify-between'>
          <div>
            <h3 className='text-2xl font-normal text-gray-800'>
              Select what you want to copy
            </h3>
            <p>
              Select the fields that you want to import from the original order
              to the new one.
            </p>
          </div>
          <CloseIcon className='w-6 h-6 cursor-pointer' onClick={onClose} />
        </div>
        <div className='py-5 px-6 mt-4 flex flex-1 h-[calc(100%-100px)] overflow-auto'>
          <div className='flex-[0.5]'>
            <SectionContainer
              label='Event Information'
              name='eventInfo'
              checked={sectionIds.includes('eventInfo')}
              onSelect={onSelect}
            >
              <div className='ml-8 gap-1'>
                {Object.keys(EVENT_INFO_COPY_KEY_MAP).map((key) => (
                  <div className='flex mt-1' key={key}>
                    <p className='font-normal text-sm text-gray-500'>
                      {breakAndCapitalize(`${EVENT_INFO_COPY_KEY_MAP[key]}`)}:
                      &nbsp;
                    </p>
                    <p className='text-sm font-normal text-gray-500'>
                      {getFormBasicValues(key, orderToCopy)}
                    </p>
                  </div>
                ))}
              </div>
            </SectionContainer>
            <SectionContainer
              label='Venue Information'
              name='venueInfo'
              checked={sectionIds.includes('venueInfo')}
              onSelect={onSelect}
            >
              <div className='ml-8 gap-1'>
                {Object.keys(VENUE_INFO_COPY_KEY_MAP).map((key) => {
                  if (VENUE_INFO_COPY_KEY_MAP[key] === 'state') {
                    const state = getFormVenueValues(key);
                    return (
                      <>
                        <div className='flex mt-1' key={key}>
                          <p className='font-normal text-sm text-gray-500'>
                            State: &nbsp;
                          </p>
                          <p className='text-sm font-normal text-gray-500'>
                            {state.name}
                          </p>
                        </div>
                        <div className='flex mt-1' key={key}>
                          <p className='font-normal text-sm text-gray-500'>
                            Country: &nbsp;
                          </p>
                          <p className='text-sm font-normal text-gray-500'>
                            {state.country}
                          </p>
                        </div>
                      </>
                    );
                  }
                  return (
                    <div className='flex mt-1' key={key}>
                      <p className='font-normal text-sm text-gray-500'>
                        {breakAndCapitalize(`${VENUE_INFO_COPY_KEY_MAP[key]}`)}:
                        &nbsp;
                      </p>
                      <p className='text-sm font-normal text-gray-500'>
                        {getFormVenueValues(key)}
                      </p>
                    </div>
                  );
                })}
              </div>
            </SectionContainer>
          </div>
          <div className='flex-[0.5]'>
            {selectedItems?.length || selectedBundles?.length ? (
              <SectionContainer
                label='My Cart'
                name='lineItems'
                checked={sectionIds.includes('lineItems')}
                onSelect={onSelect}
              >
                <div className='ml-8 gap-1'>
                  {selectedBundles?.map((bundle, idx) => (
                    <div key={`${bundle.bundle?._id}-${idx}`}>
                      <div className='flex items-center justify-between gap-x-3'>
                        <div className='text-sm font-normal text-gray-500'>
                          {`${bundle.quantity} ${bundle.bundle?.name}`}
                        </div>
                        <div className='text-sm font-normal text-gray-500'>
                          {formatCurrency(bundle?.price || 0)}
                        </div>
                      </div>

                      <ul className='text-sm ml-5 text-gray-400'>
                        {bundle?.bundle?.items.map((bundleItem) => (
                          <li
                            className='list-disc text-sm font-normal text-gray-500'
                            key={bundleItem._id}
                          >
                            {bundleItem.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                  {selectedItems?.map((item, idx) => {
                    if (!item.item) return null;
                    return (
                      <div key={`${item.item?._id}-${idx}`}>
                        <div className='flex items-center justify-between gap-x-3'>
                          <div className='text-sm font-normal text-gray-500'>
                            {`${item.quantity} ${item.item?.name}`}
                          </div>
                          <div className='text-sm font-normal text-gray-500'>
                            {formatCurrency(item?.price || 0)}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </SectionContainer>
            ) : null}
          </div>
        </div>
        <div className='flex gap-3 justify-end'>
          <Button
            className='!border-gray-300 !text-gray-500 mr-2 !px-12 !py-2 rounded-xl'
            variant='outlined'
            onClick={onClose}
            label='Cancel'
            type='button'
          />
          <Button
            className='!bg-green-950 !px-8 rounded-xl'
            onClick={() => onCreate(sectionIds)}
            label='Create Order'
            type='button'
          />
        </div>
      </div>
    </Modal>
  );
};
