import { FC } from 'react';

import { Box, Typography } from '@mui/material';

interface IFieldWithValue {
  label: string;
  value?: string | number;
  modified?: boolean;
  children?: never;
}

interface IFieldWithChildren {
  label: string;
  value?: never;
  modified?: boolean;
  children: React.ReactNode;
}

type IField = IFieldWithValue | IFieldWithChildren;

export const ReadOnlyKeyValue: FC<{ field: IField }> = ({ field }) => {
  const { label, value, modified, children } = field;
  return (
    <Box
      key={label}
      sx={{
        display: 'flex',
        gap: '6px',
        background: modified ? '#fedf89' : 'transparent',
      }}
    >
      <Typography sx={{ color: '#0c11d' }}>{label}:</Typography>

      {value ? (
        <Typography sx={{ color: '#7c838f' }}>{value}</Typography>
      ) : (
        children
      )}
    </Box>
  );
};
