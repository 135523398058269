import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { PROTECTED_ROUTES, PUBLIC_ROUTES } from 'constants/routes';
import ForgotPassword from 'pages/ForgotPassword/ForgotPassword';
import LoginPage from 'pages/Login';
import OrdersPage from 'pages/Orders';
import ResetPassword from 'pages/ResetPassword/ResetPassword';
import VerifyOtp from 'pages/VerifyOtp';

import ProtectedRoute from './PrivateRoutes';

const Routers = () => {
  return (
    <Suspense>
      <Routes>
        <Route path={PUBLIC_ROUTES.LOGIN} element={<LoginPage />} />
        <Route
          path={PROTECTED_ROUTES.ORDRES}
          element={
            <ProtectedRoute
              redirectPath={PUBLIC_ROUTES.LOGIN}
              element={<OrdersPage />}
            />
          }
        />
        <Route
          path={PUBLIC_ROUTES.FORGOT_PASSWORD}
          element={<ForgotPassword />}
        />
        <Route path={PUBLIC_ROUTES.VERIFY_OTP} element={<VerifyOtp />} />
        <Route
          path={PUBLIC_ROUTES.RESET_PASSWORD}
          element={<ResetPassword />}
        />
      </Routes>
    </Suspense>
  );
};

export default Routers;
