import React, { useState } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import PlacesAutocomplete from 'components/AVOrder/PlacesAutocomplete';
import Input from 'components/Inputs';
import TypeToSelect from 'components/Inputs/TypeToSelect';
import { CreateAVOrder, State } from 'types/av-orders';

import { MainHeading } from './UtilComponents';

interface IOrderVenueForm {
  states: State[];
  control: Control<CreateAVOrder>;
  setValue: UseFormSetValue<CreateAVOrder>;
}

const OrderVenueForm: React.FC<IOrderVenueForm> = ({
  states,
  control,
  setValue,
}) => {
  const [disableCityStateZip, setDisableCityStateZip] = useState(true);
  return (
    <div>
      <MainHeading title='Venue Information' classNames='mt-8' />
      <div className='flex gap-2 mt-1 items-center'>
        <div className='w-4/12'>
          <Controller
            control={control}
            name='venue.name'
            render={({ fieldState: { error }, field }) => (
              <Input
                type='text'
                label='Venue Name'
                value={field.value}
                onChange={field.onChange}
                error={error}
                name={field.name}
                placeholder='Enter Venue Name'
              />
            )}
          />
        </div>
        <div className='w-6/12'>
          <Controller
            control={control}
            name='venue.address'
            rules={{
              required: true,
            }}
            render={({ fieldState: { error }, field }) => (
              <PlacesAutocomplete
                setValue={setValue}
                error={error}
                field={field}
                states={states}
                onEnterAddressManually={() => {
                  setDisableCityStateZip(false);
                }}
              />
            )}
          />
        </div>
        <div className='w-2/12'>
          <Controller
            control={control}
            name='venue.suiteNum'
            render={({ fieldState: { error }, field }) => (
              <Input
                type='text'
                label='Suite #'
                value={field.value}
                onChange={field.onChange}
                error={error}
                name={field.name}
                optional
                placeholder='Unit #, etc.'
              />
            )}
          />
        </div>
      </div>

      <div className='flex gap-2 mt-4 items-center'>
        <div className='w-1/3'>
          <Controller
            control={control}
            name='venue.city'
            rules={{
              required: true,
            }}
            render={({ fieldState: { error }, field }) => (
              <Input
                type='text'
                label='City'
                value={field.value}
                onChange={field.onChange}
                disabled={disableCityStateZip}
                error={error}
                name={field.name}
                placeholder='Enter City'
              />
            )}
          />
        </div>
        <div className='w-1/3'>
          <Controller
            control={control}
            name='venue.state'
            rules={{
              required: true,
            }}
            render={({ fieldState: { error }, field }) => (
              <TypeToSelect
                options={states.map(({ _id, name, abbrev }) => ({
                  value: _id,
                  label: `${name} (${abbrev})`,
                }))}
                label='State'
                value={field.value}
                onChange={field.onChange}
                disabled={disableCityStateZip}
                error={error}
                name={field.name}
              />
            )}
          />
        </div>
        <div className='w-1/3'>
          <Controller
            control={control}
            name='venue.zip'
            rules={{
              required: true,
            }}
            render={({ fieldState: { error }, field }) => (
              <Input
                type='text'
                label='Zip'
                value={field.value}
                onChange={field.onChange}
                disabled={disableCityStateZip}
                error={error}
                name={field.name}
                placeholder='Enter Zip Code'
              />
            )}
          />
        </div>
      </div>
      <div className='flex gap-2 mt-4 items-center'>
        <div className='w-1/3'>
          <Controller
            control={control}
            name='venue.roomName'
            rules={{
              required: true,
            }}
            render={({ fieldState: { error }, field }) => (
              <Input
                type='text'
                label='Room Name'
                value={field.value}
                onChange={field.onChange}
                error={error}
                name={field.name}
                placeholder='Enter Room Name'
              />
            )}
          />
        </div>
        <div className='w-2/3'>
          <Controller
            control={control}
            name='venue.reservationName'
            rules={{
              required: true,
            }}
            render={({ fieldState: { error }, field }) => (
              <Input
                type='text'
                label='Reservation Name'
                value={field.value}
                onChange={field.onChange}
                error={error}
                name={field.name}
                optional
                placeholder='Enter Reservation Name'
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderVenueForm;
