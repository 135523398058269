import { Fragment, ReactNode } from 'react';
import classNames from 'classnames';

import { Dialog, Transition } from '@headlessui/react';

interface IModal {
  open: boolean;
  onClose: () => void;
  className?: string;
  persist?: boolean;
  children: ReactNode;
}

const Modal: React.FC<IModal> = ({
  open,
  onClose,
  children,
  className = '',
  persist = false,
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        static={true}
        as='div'
        className='relative z-10'
        onClose={persist ? () => {} : onClose}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
          <div className='flex min-h-full items-start justify-center p-4 text-center md:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel
                className={classNames(
                  `relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all my-auto sm:p-6 ${className}`,
                )}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
