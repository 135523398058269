export const COLORS = {
  primary: '#497880',
  green: {
    950: '#497880',
  },
  gray: {
    300: '#d1d5db',
    400: '#9ca3af',
    600: '#475467',
    850: '#182230',
    950: '#0C111D',
  },
  yellow: {
    200: '#FEDF89',
  },
  red: {
    200: '#FECDCA',
  },
  stepperBg: '#edf2f3',
  slate: '#F6F8F8',
  success50: '#ECFDF3',
  success800: '#085D3A',
  warning50: '#FFFAEB',
  warning800: '#93370D',
  danger50: '#FEF3F2',
  danger800: '#912018',
  white: '#FFFFFF',
  offWhite: '#f1f1f1',
  highlightRed: '#e57373',
};
