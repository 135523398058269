/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback, useState } from 'react';
import classNames from 'classnames';

import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';

interface IStripeCardElement {
  label: string;
  type: string;
  onChange?: (e: any) => void;
}

const elementOptions = {
  style: {
    base: {
      'fontSize': '14px',
      'color': 'rgb(55, 65, 81)',
      '::placeholder': {
        color: '#ccc',
      },
    },
  },
};

const StripeCardElement: FC<IStripeCardElement> = ({
  label,
  type,
  onChange,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [error, setError] = useState({ message: '' });

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleOnChange = useCallback(
    (e: any) => {
      if (e.error) {
        setError({ message: e.error.message });
      } else {
        setError({ message: '' });
      }
      onChange?.(e);
    },
    [onChange],
  );

  return (
    <div className='w-full'>
      <label
        htmlFor='common-input'
        className='block text-sm font-medium leading-6 text-gray-800'
      >
        {label}
      </label>
      <div className='relative rounded-md shadow-sm'>
        <div
          className={classNames(
            'flex flex-col justify-center block bg-white h-10 w-full px-3 rounded-md border-0 py-1 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 ',
            {
              'ring-2 ring-inset ring-green-950 outline-green-950': isFocused,
            },
          )}
        >
          {type === 'card-number' ? (
            <CardNumberElement
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleOnChange}
              options={elementOptions}
            />
          ) : null}
          {type === 'card-expiry' ? (
            <CardExpiryElement
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleOnChange}
              options={elementOptions}
            />
          ) : null}
          {type === 'card-cvc' ? (
            <CardCvcElement
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleOnChange}
              options={elementOptions}
            />
          ) : null}
        </div>
        {error?.message ? (
          <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
            <ExclamationCircleIcon
              className='h-5 w-5 text-red-500'
              aria-hidden='true'
            />
          </div>
        ) : null}
      </div>
      {error?.message ? (
        <p className='mt-1 ml-1 text-xs text-red-600' id='email-error'>
          {error.message}
        </p>
      ) : null}
    </div>
  );
};

export default StripeCardElement;
