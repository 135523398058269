/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import { STEPS_STATUS } from 'constants/common';

import { CheckIcon } from '@heroicons/react/24/solid';

interface ISteps {
  steps: { id: number; name: string; status: STEPS_STATUS }[];
}

const Steps: React.FC<ISteps> = ({ steps }) => {
  return (
    <nav aria-label='Progress' className='w-[98%]'>
      <ol
        role='list'
        className='divide-y divide-gray-300 rounded-m md:flex md:divide-y-0'
      >
        {steps.map((step, stepIdx) => {
          const stepContainerClass = classNames(
            'flex items-center justify-center w-full px-6 py-2 text-sm font-medium border-gray-300 border-t border-b',
            {
              'border-green-950':
                step.status === STEPS_STATUS.COMPLETE ||
                step.status === STEPS_STATUS.CURRENT,
              'border-l rounded-tl-lg rounded-bl-lg': stepIdx === 0,
              'bg-stepperBg': step.status === STEPS_STATUS.CURRENT,
            },
          );
          const stepTitle = `Step ${step.id}: ${step.name}`;
          return (
            <li key={step.name} className='relative md:flex md:flex-1'>
              {step.status === STEPS_STATUS.COMPLETE ? (
                <a className={stepContainerClass}>
                  <span className='flex h-5 w-5 flex-shrink-0 items-center justify-center rounded-full bg-green-950'>
                    <CheckIcon
                      className='h-4 w-4 text-white'
                      aria-hidden='true'
                    />
                  </span>
                  <span className='ml-4 text-sm font-normal text-gray-850'>
                    {stepTitle}
                  </span>
                </a>
              ) : step.status === STEPS_STATUS.CURRENT ? (
                <a className={stepContainerClass} aria-current='step'>
                  <span className='ml-4 text-sm font-bold text-green-950'>
                    {stepTitle}
                  </span>
                </a>
              ) : (
                <a className={stepContainerClass}>
                  <span className='ml-4 text-sm font-normal text-gray-850 group-hover:text-gray-900'>
                    {stepTitle}
                  </span>
                </a>
              )}

              {/* Arrow separator for lg screens and up */}
              <div
                className='absolute left-full top-0 hidden h-full w-5 md:block z-10'
                aria-hidden='true'
              >
                <svg
                  className={classNames('h-full w-full text-gray-300', {
                    'text-green-950':
                      step.status === STEPS_STATUS.COMPLETE ||
                      step.status === STEPS_STATUS.CURRENT,
                  })}
                  viewBox='0 0 22 80'
                  fill='none'
                  preserveAspectRatio='none'
                >
                  <path
                    d='M0 -2L20 40L0 82'
                    vectorEffect='non-scaling-stroke'
                    stroke='currentcolor'
                    strokeLinejoin='round'
                    fill={
                      step.status === STEPS_STATUS.CURRENT ? '#edf2f3' : '#fff'
                    }
                  />
                </svg>
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Steps;
