/* eslint-disable @typescript-eslint/no-explicit-any */
import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authState } from 'state/atoms';

interface IProtectedRoute {
  redirectPath: string;
  element: React.ReactNode | any;
}

const ProtectedRoute = ({ redirectPath, element }: IProtectedRoute) => {
  const auth = useRecoilValue(authState) as string;

  if (!auth) {
    return <Navigate to={redirectPath} replace />;
  }

  return element;
};

export default ProtectedRoute;
