import { PolarSelect } from 'constants/common';
import { AVOrderType } from 'constants/orders';
import { Regex } from 'constants/regex';
import dayjs from 'dayjs';
import { CreateAVLineItem } from 'types/av-customer';
import * as yup from 'yup';

import { OrderValidationErrors } from './vaidation-errors';

export const createOrderValidationScheme = yup
  .object({
    type: yup.string().oneOf(Object.values(AVOrderType)).required(),
    hasAdditionalVenues: yup.string().optional(),
    lineItems: yup
      .mixed<{ [K in 'bundles' | 'items']: CreateAVLineItem[] }>()
      .test(
        'required',
        OrderValidationErrors.FIELD_REQUIRED,
        (value) =>
          value?.bundles?.some((lineItem) => lineItem?.bundle) ||
          value?.items?.some((lineItem) => lineItem?.item),
      )
      .required(),
    orderId: yup.string().required(OrderValidationErrors.FIELD_REQUIRED),
    enteredBy: yup.string().required(OrderValidationErrors.FIELD_REQUIRED),
    attendeeCount: yup
      .number()
      .min(0.1, OrderValidationErrors.NUMBER_GREATER_THAN_ZERO)
      .typeError(OrderValidationErrors.NOT_A_NUMBER)
      .required(OrderValidationErrors.FIELD_REQUIRED),
    endClient: yup.string().required(OrderValidationErrors.FIELD_REQUIRED),
    metadata: yup.object().optional(),
    customer: yup.string().required('Customer is required!'),
    startDate: yup
      .date()
      .required(OrderValidationErrors.FIELD_REQUIRED)
      .min(dayjs().startOf('day').toDate(), 'Date cannot be in the past'),

    endDate: yup
      .date()
      .required(OrderValidationErrors.FIELD_REQUIRED)
      .when('startDate', (args, schema) => {
        const startDate = args.at(0);
        return startDate
          ? schema.min(
              dayjs(startDate).startOf('day').toDate(),
              'End date must be after start date',
            )
          : schema;
      }),
    startTime: yup.date().required(OrderValidationErrors.FIELD_REQUIRED),
    setupTime: yup
      .date()
      .required(OrderValidationErrors.FIELD_REQUIRED)
      .when('startTime', (args, schema) => {
        const startTime = args.at(0);
        return startTime
          ? schema.max(
              dayjs(startTime).toDate(),
              'Setup time must be before start time',
            )
          : schema;
      }),
    endTime: yup
      .date()
      .required(OrderValidationErrors.FIELD_REQUIRED)
      .when('startTime', (args, schema) => {
        const startTime = args.at(0);
        return startTime
          ? schema.min(
              dayjs(startTime).toDate(),
              'End time must be after start time',
            )
          : schema;
      }),
    stripeSetupIntentId: yup.string().optional(),
    speakerName: yup.string().required(OrderValidationErrors.FIELD_REQUIRED),
    venue: yup
      .object({
        name: yup.string().required(OrderValidationErrors.FIELD_REQUIRED),
        address: yup.string().required(OrderValidationErrors.FIELD_REQUIRED),
        suiteNum: yup.string().optional(),
        city: yup.string().required(OrderValidationErrors.FIELD_REQUIRED),
        state: yup.string().required(OrderValidationErrors.FIELD_REQUIRED),
        zip: yup
          .string()
          .min(3, OrderValidationErrors.ZIP_PATTERN)
          .max(10, OrderValidationErrors.ZIP_PATTERN)
          .required(OrderValidationErrors.FIELD_REQUIRED),
        roomName: yup.string().required(OrderValidationErrors.FIELD_REQUIRED),
        venueContact: yup
          .object({
            phone: yup
              .string()
              .required(OrderValidationErrors.FIELD_REQUIRED)
              .matches(Regex.PHONE_NUMBER, OrderValidationErrors.PHONE_PATTERN),
            name: yup.string().required(OrderValidationErrors.FIELD_REQUIRED),
          })
          .required(),
      })
      .required('Venue details are required'),
    orderStatus: yup.string().optional(),
    rushFee: yup.number().optional(),
    comments: yup.string().optional(),
    localTimeZone: yup.string().optional(),
    onSiteContact: yup
      .object({
        phone: yup
          .string()
          .required(OrderValidationErrors.FIELD_REQUIRED)
          .matches(Regex.PHONE_NUMBER, OrderValidationErrors.PHONE_PATTERN),
        name: yup.string().required(OrderValidationErrors.FIELD_REQUIRED),
      })
      .required('On Site Contact details are required'),
  })
  .required();

export const createWebConfOrderValidationScheme = yup
  .object({
    type: yup.string().oneOf(Object.values(AVOrderType)).required(),
    lineItems: yup.mixed().optional(),
    orderId: yup.string().required(OrderValidationErrors.FIELD_REQUIRED),
    enteredBy: yup.string().required(OrderValidationErrors.FIELD_REQUIRED),
    attendeeCount: yup
      .number()
      .min(0.1, OrderValidationErrors.NUMBER_GREATER_THAN_ZERO)
      .typeError(OrderValidationErrors.NOT_A_NUMBER)
      .required(OrderValidationErrors.FIELD_REQUIRED),
    endClient: yup.string().required(OrderValidationErrors.FIELD_REQUIRED),
    metadata: yup.object().optional(),
    customer: yup.string().required('Customer is required!'),
    startDate: yup
      .date()
      .required(OrderValidationErrors.FIELD_REQUIRED)
      .min(dayjs().startOf('day').toDate(), 'Date cannot be in the past'),
    endDate: yup
      .date()
      .required(OrderValidationErrors.FIELD_REQUIRED)
      .when('startDate', (args, schema) => {
        const startDate = args.at(0);
        return startDate
          ? schema.min(
              dayjs(startDate).startOf('day').toDate(),
              'End date must be after start date',
            )
          : schema;
      }),
    startTime: yup.date().required(OrderValidationErrors.FIELD_REQUIRED),
    setupTime: yup.date().optional(),
    endTime: yup
      .date()
      .required(OrderValidationErrors.FIELD_REQUIRED)
      .when('startTime', (args, schema) => {
        const startTime = args.at(0);
        return startTime
          ? schema.min(
              dayjs(startTime).toDate(),
              'End time must be after start time',
            )
          : schema;
      }),
    stripeSetupIntentId: yup.string().optional(),
    speakerName: yup.string().required(OrderValidationErrors.FIELD_REQUIRED),
    orderStatus: yup.string().optional(),
    rushFee: yup.number().optional(),
    comments: yup.string().optional(),
    localTimeZone: yup.string().required(OrderValidationErrors.FIELD_REQUIRED),
    hasAdditionalVenues: yup
      .string()
      .oneOf(Object.values(PolarSelect))
      .required('Field is Required'),
    onSiteContact: yup
      .object({
        phone: yup.string().required(OrderValidationErrors.FIELD_REQUIRED),
        name: yup.string().required(OrderValidationErrors.FIELD_REQUIRED),
      })
      .required('On Site Contact details are required'),
  })
  .required();
