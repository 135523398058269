/* eslint-disable max-lines */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { WEBCONF_BASE_PRICE } from 'constants/orders';
import { API_ROUTES } from 'constants/routes';
import dayjs from 'dayjs';
import { useRequest } from 'hooks/useRequest';
import { useRecoilValue } from 'recoil';
import { currentUserState } from 'state/atoms';
import { AVEntityOrderFormField, AVOrderFormField } from 'types/av-form-fields';
import { AVOrder, CreateAVOrder, EndClient, State } from 'types/av-orders';
import { InputTypes } from 'types/form';
import {
  calculateWebConfOrderPrices,
  generateAVOrderFormValidation,
  getSectionWiseFormFields,
  getWebConfOrderDefaultValues,
} from 'utils/avUtils';
import { createWebConfOrderValidationScheme } from 'validations/order-form.schema';

import { yupResolver } from '@hookform/resolvers/yup';

import WebConfOrderModal from './WebConfOrderModal';
import WebConfOrderSubmissionSuccess from './WebConfOrderSubmissionSuccess';

interface IWebConfOrder {
  states: State[];
  nextOrderId: string;
  keysToCopy: string[];
  endClients: EndClient[];
  webConfOrderModal: boolean;
  editOrder: AVOrder | undefined;
  orderToCopy: AVOrder | undefined;
  onCloseWebConfOrderModal: () => void;
  addToLocalOrderState: (order: AVOrder) => void;
  updateLocalOrderState: (order: AVOrder) => void;
  isWithinCancellationPolicy: boolean;
}

const WebConfOrder: React.FC<IWebConfOrder> = ({
  states,
  editOrder,
  keysToCopy,
  endClients,
  orderToCopy,
  nextOrderId,
  webConfOrderModal,
  addToLocalOrderState,
  updateLocalOrderState,
  onCloseWebConfOrderModal,
  isWithinCancellationPolicy,
}) => {
  const { get } = useRequest();
  const currentUser = useRecoilValue(currentUserState);
  const [showSubmittedData, setShowSubmittedData] = useState(false);
  const [latesSubmittedOrder, setLatesSubmittedOrder] = useState<AVOrder>();
  const [webConfFormEntityFields, setWebConfFormEntityFields] = useState<
    AVEntityOrderFormField[]
  >([]);
  const [webConfFormFields, setWebConfFormFields] = useState<
    AVOrderFormField[]
  >([]);

  const [dynamicFieldsLoader, setDynamicFieldsLoader] = useState(false);

  const { contactFields, eventDetailFields, eventProfileFields } =
    useMemo(() => {
      return getSectionWiseFormFields(webConfFormEntityFields);
    }, [webConfFormEntityFields]);

  const {
    control,
    watch,
    trigger,
    setValue,
    handleSubmit,
    getValues,
    getFieldState,
    formState: { dirtyFields },
  } = useForm<CreateAVOrder>({
    defaultValues: getWebConfOrderDefaultValues(
      nextOrderId,
      webConfFormFields,
      currentUser,
      editOrder,
    ),
    resolver: yupResolver<CreateAVOrder>(
      createWebConfOrderValidationScheme.concat(
        generateAVOrderFormValidation(webConfFormFields),
      ),
    ),
  });

  const selectedEndClient = watch('endClient');

  useEffect(() => {
    if (selectedEndClient) {
      const endClient = endClients.find((ec) => ec._id === selectedEndClient);
      if (endClient) {
        setValue(
          'basePrice',
          endClient.webConfBasedPrice ?? WEBCONF_BASE_PRICE,
        );
      }
    }
  }, [selectedEndClient, endClients, setValue]);

  const getWebConfFormFields = useCallback(async () => {
    setDynamicFieldsLoader(true);
    const { data } = await get<AVEntityOrderFormField[]>(
      `${API_ROUTES.AV_CUSTOMER}/web-conf-order-form-field`,
    );

    if (data?.length) {
      const formFIelds = data.map((field) => field.formField);
      setWebConfFormFields(formFIelds);
      setWebConfFormEntityFields(data);
    }

    setDynamicFieldsLoader(false);
  }, [get]);

  useEffect(() => {
    getWebConfFormFields();
  }, [getWebConfFormFields]);

  const handleSubmittedOrder = useCallback((order: AVOrder) => {
    setLatesSubmittedOrder(order);
  }, []);

  const handleSubmitDataState = useCallback((state: boolean) => {
    setShowSubmittedData(state);
  }, []);

  useEffect(() => {
    if (nextOrderId && !editOrder) {
      setValue('orderId', nextOrderId);
    }
  }, [nextOrderId, setValue, editOrder]);

  useEffect(() => {
    if (keysToCopy?.length && orderToCopy) {
      if (keysToCopy.includes('eventInfo')) {
        setValue('endClient', orderToCopy.endClient?._id);
        setValue('attendeeCount', orderToCopy.attendeeCount);
        setValue('enteredBy', orderToCopy.enteredBy._id);
      }
    }
  }, [keysToCopy, orderToCopy, setValue]);

  useEffect(() => {
    if (
      editOrder?.attendeeCount &&
      editOrder?.startTime &&
      editOrder?.endTime
    ) {
      const formValues = getValues();

      const { additionalDurationPrice, additionalAttendeesPrice } =
        calculateWebConfOrderPrices(
          editOrder?.basePrice || WEBCONF_BASE_PRICE,
          editOrder?.attendeeCount,
          formValues?.startDate,
          formValues?.startTime ?? '',
          formValues?.endDate,
          formValues?.endTime ?? '',
        );

      setValue('additionalDurationPrice', additionalDurationPrice);
      setValue('additionalAttendeesPrice', additionalAttendeesPrice);
    }
  }, [editOrder, getValues, setValue]);

  useEffect(() => {
    webConfFormFields.forEach((field) => {
      if (field.inputType === InputTypes.DATE) {
        let dateValue;
        if (editOrder?.metadata?.[field.metadataKey]) {
          dateValue = dayjs(editOrder?.metadata?.[field.metadataKey])
            .set('hours', 0)
            .set('minute', 0)
            .format('YYYY-MM-DD');
        } else {
          dateValue = undefined;
        }

        setValue(
          `metadata.${field.metadataKey}` as keyof CreateAVOrder,
          dateValue,
        );

        return;
      }

      if (!editOrder?.metadata || !field.metadataKey) {
        return;
      }
      setValue(
        `metadata.${field.metadataKey}` as keyof CreateAVOrder,
        editOrder?.metadata[field.metadataKey],
      );
    });
  }, [currentUser?.timezone, editOrder?.metadata, setValue, webConfFormFields]);

  return (
    <>
      {showSubmittedData ? (
        <WebConfOrderSubmissionSuccess
          states={states}
          editOrder={editOrder}
          endClients={endClients}
          contactDynamicFields={contactFields}
          eventDetailFormFields={eventDetailFields}
          eventProfileDynamicFields={eventProfileFields}
          latesSubmittedOrder={editOrder || latesSubmittedOrder}
          getValues={getValues}
          getFieldState={getFieldState}
          goBack={onCloseWebConfOrderModal}
        />
      ) : (
        <WebConfOrderModal
          contactFields={contactFields}
          eventDetailFields={eventDetailFields}
          eventProfileFields={eventProfileFields}
          states={states}
          control={control}
          editOrder={editOrder}
          endClients={endClients}
          webConfOrderModal={webConfOrderModal}
          dynamicFieldsLoader={dynamicFieldsLoader}
          dirtyFields={dirtyFields as unknown as Partial<CreateAVOrder>}
          watch={watch}
          trigger={trigger}
          setValue={setValue}
          getValues={getValues}
          handleSubmit={handleSubmit}
          addToLocalOrderState={addToLocalOrderState}
          updateLocalOrderState={updateLocalOrderState}
          handleSubmitDataState={handleSubmitDataState}
          handleSubmittedOrder={handleSubmittedOrder}
          onCloseWebConfOrderModal={onCloseWebConfOrderModal}
          isWithinCancellationPolicy={isWithinCancellationPolicy}
        />
      )}
    </>
  );
};

export default WebConfOrder;
