/* eslint-disable max-lines */
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import AlertMessage from 'components/AlertMessage';
import Footer from 'components/Footer';
import Header from 'components/Header';
import SideOver from 'components/SideOver';
import { getAVOrderColumn } from 'components/TableColumns/av-order.column';
import DataTable from 'components/Tables/index';
import OrderToolbar from 'components/Tables/OrderToolbar';
import { AVOrderType, ORDER_ALERT_MESSAGES } from 'constants/orders';
import { API_ROUTES } from 'constants/routes';
import { DEFAULT_TIMEZONE } from 'constants/timezone';
import dayjs from 'dayjs';
import { useCancel } from 'hooks/useCancel';
import { useOrderFilter } from 'hooks/useFilter';
import { useRequest } from 'hooks/useRequest';
import { debounce } from 'lodash';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  downloadExcel,
  downloadPDF,
  msgBasedOnCancellationPolicy,
} from 'services/order';
import { authState, currentCustomerState, currentUserState } from 'state/atoms';
import { AVCustomer, CustomerBase } from 'types/av-customer';
import { AVOrderFormField, GenericObject } from 'types/av-form-fields';
import { AVOrder, CancelOrderPayload, EndClient, State } from 'types/av-orders';
import { IFilterOrder } from 'types/filter';
import {
  AvailableBundles,
  AvailableItems,
  ItemsAndBundles,
} from 'types/item-bundle';
import { timezonedDayjs } from 'utils/dayjs';
import { convertObjIntoQueryParam, removeEmptyKeys } from 'utils/helper';

import { CopyProjectModal } from './OrderForm/CopyProjectModal';
import { OrderDetailModal } from './OrderForm/OrderDetailModal';
import { WebconfCopyOrderModal } from './WebConfOrder/WebconfCopyOrderModal';
import OrderForm from './OrderForm';
import WebConfOrder from './WebConfOrder';

const OrdersPage = () => {
  const { get, post, isLoading: apiLoading } = useRequest();
  const { recordId, onCancel, onCloseConfirmation, openConfirm } = useCancel();
  const {
    recordId: cancelledOrderId,
    onCancel: openCancelledOrderAlert,
    onCloseConfirmation: closeCancelledOrderAlert,
    openConfirm: showCancelledOrderAlert,
  } = useCancel();
  const setCurrentUser = useSetRecoilState(currentUserState);
  const setCurrentCustomer = useSetRecoilState(currentCustomerState);

  const auth = useRecoilValue(authState);
  const user = useRecoilValue(currentUserState);
  const customer = useRecoilValue(currentCustomerState);

  const [isLoading, setIsLoading] = useState(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [items, setItems] = useState<AvailableItems[]>([]);
  const [bundles, setBundles] = useState<AvailableBundles[]>([]);
  const [states, setStates] = useState<State[]>([]);
  const [orderModal, setOrderModal] = useState(false);
  const [webConfOrderModal, setWebConfOrderModal] = useState(false);
  const [copyOrderModal, setCopyOrderModal] = useState(false);
  const [copyWebConfOrderModal, setCopyWebConfOrderModal] = useState(false);
  const [orderDetailModal, setOrderDetailModal] = useState(false);
  const [isSideOver, setSideOver] = useState(false);
  const [editOrder, setEditOrder] = useState<AVOrder>();
  const [orderToCopy, setOrderToCopy] = useState<AVOrder>();
  const [endClients, setEndClients] = useState<EndClient[]>([]);

  const [keysToCopy, setKeysToCopy] = useState<string[]>([]);
  const [selectedOrders, setSelectedOrders] = useState<string[]>([]);
  const [nextOrderId, setNextOrderId] = useState<string>('');
  const [isWithinCancellationPolicy, setIsWithinCancellationPolicy] =
    useState<boolean>(false);

  const {
    advanceFilters,
    localSearchQuery,
    isFilterApplied,
    avFilteredOrders,
    dashboardFormFields,
    onSetAdvanceFilters,
    onSetSearchFilter,
    setAVOrders,
    setFilteredAVOrders,
    setDashboardFormFields,
  } = useOrderFilter();

  const getOrders = useCallback(
    async (noLoading?: boolean) => {
      if (!noLoading) {
        setIsLoading(true);
      }

      const filters: GenericObject = { ...advanceFilters };
      filters.dynamicFilters = convertObjIntoQueryParam(filters.dynamicFilters);
      const allFilters = removeEmptyKeys(filters);
      const query = convertObjIntoQueryParam(allFilters);

      const { data } = await get<AVOrder[]>(
        `${API_ROUTES.AV_CUSTOMER}/client-order?${query}`,
      );

      if (data) {
        setAVOrders(data);
      }
      setIsLoading(false);
    },
    [advanceFilters, setAVOrders],
  );

  const getUser = useCallback(async () => {
    const { data } = await get<AVCustomer>(
      `${API_ROUTES.AV_CUSTOMER}/current-auth`,
    );
    setCurrentUser(data);
    if (data) {
      const { data: customerData } = await get<CustomerBase>(
        `${API_ROUTES.AV_CUSTOMER}/customer/${data.customer}`,
      );
      setCurrentCustomer(customerData);
    }
  }, [get, setCurrentUser, setCurrentCustomer]);

  useEffect(() => {
    if (auth) {
      getUser();
    }
  }, [getUser, auth]);

  const getAVCustomerEndClients = useCallback(async () => {
    const { data } = await get<EndClient[]>(
      `${API_ROUTES.AV_CUSTOMER}/av-customer-end-clients`,
    );

    if (data) {
      setEndClients(data);
    }
  }, [get]);

  const getNextAVOrderId = useCallback(async () => {
    const { data } = await get<string>(
      `${API_ROUTES.AV_CUSTOMER}/next-av-order-id`,
    );
    setNextOrderId(data ?? '');
  }, [get]);

  const onCreateOrder = useCallback(async () => {
    await getNextAVOrderId();
    setEditOrder(undefined);
    setOrderModal(true);
  }, [getNextAVOrderId]);

  const onFilter = useCallback(() => {
    setSideOver(true);
  }, []);

  const getOrdersForExport = useCallback(async (selectedOrders: string[]) => {
    const { data } = await post<AVOrder[], { selectedOrderIds: string[] }>(
      `${API_ROUTES.AV_CUSTOMER}/get-orders-for-export`,
      { selectedOrderIds: selectedOrders },
    );

    return data;
  }, []);

  const onExport = useCallback(async () => {
    const ordersToExport = selectedOrders.filter((orderId) =>
      avFilteredOrders.find((order) => order._id === orderId),
    );
    const orders = await getOrdersForExport(ordersToExport);

    if (orders?.length) {
      downloadExcel(orders, 'av_orders.xlsx');
      setSelectedOrders([]);
    }
  }, [avFilteredOrders, getOrdersForExport, selectedOrders]);

  const getOrderById = useCallback(async (orderId: string) => {
    const { data } = await get<AVOrder>(
      `${API_ROUTES.AV_CUSTOMER}/client-order/${orderId}`,
    );

    return data;
  }, []);

  const onEditOrder = useCallback(
    async (orderId: string) => {
      const data = await getOrderById(orderId);

      if (data) {
        if (customer?.enableAvPolicies) {
          const { daysPrior, at, tz } = customer.cancellationPolicy;
          const now = timezonedDayjs(user?.timezone || DEFAULT_TIMEZONE);
          const eventStartTime = dayjs(data.startDate, 'YYYY-MM-DD');
          const timeArr = at.split(':');
          const cancellationCutOff = timezonedDayjs(tz, eventStartTime)
            .subtract(daysPrior, 'day')
            .set('hour', Number(timeArr[0] || 0))
            .set('minute', Number(timeArr[1] || 0));
          if (now.isAfter(cancellationCutOff)) {
            setIsWithinCancellationPolicy(true);
          } else {
            setIsWithinCancellationPolicy(false);
          }
        }
        setEditOrder(data);
      }
    },
    [
      getOrderById,
      customer?.enableAvPolicies,
      customer?.cancellationPolicy,
      user?.timezone,
    ],
  );

  const getAVItemsAndBundles = useCallback(
    async (clientId: string) => {
      const { data } = await get<ItemsAndBundles>(
        `${API_ROUTES.AV_CUSTOMER}/items-bundles/${clientId}`,
      );

      if (data) {
        setBundles(data?.availableBundles ?? []);
        let availableItems: AvailableItems[] = [];
        if (data.availableItems) {
          availableItems = data.availableItems.sort(
            (a, b) =>
              a.item?.category?.localeCompare(b.item?.category) ||
              a.item?.subCategory?.localeCompare(b.item?.subCategory),
          );
        }
        setItems(availableItems);
      }
    },
    [get],
  );

  const onCopyOrder = useCallback(
    async (orderId: string) => {
      const data = await getOrderById(orderId);

      if (!data) return;

      if (data?.endClient && data.type === AVOrderType.AV_RENTAL) {
        setItems([]);
        setBundles([]);
        getAVItemsAndBundles(`${data.endClient._id}`);
      }

      if (data) {
        setOrderToCopy(data);
        await getNextAVOrderId();
      }
    },
    [getAVItemsAndBundles, getNextAVOrderId, getOrderById],
  );

  const onCreateOrderFromCopy = useCallback((copyKeys: string[]) => {
    setKeysToCopy(copyKeys);
    setCopyOrderModal(false);
    setOrderModal(true);
  }, []);

  const onCreateWebConfOrderFromCopy = useCallback((copyKeys: string[]) => {
    setKeysToCopy(copyKeys);
    setCopyWebConfOrderModal(false);
    setWebConfOrderModal(true);
  }, []);

  const onCloseCopyOrderModal = useCallback(() => {
    setOrderToCopy(undefined);
    setOrderDetailModal(false);
    setCopyOrderModal(false);
    setCopyWebConfOrderModal(false);
  }, []);

  useEffect(() => {
    if (!editOrder?._id) return;

    if (editOrder.type === AVOrderType.WEB_CONF) {
      setWebConfOrderModal(true);
    }

    if (editOrder.type === AVOrderType.AV_RENTAL) {
      setOrderModal(true);
    }
  }, [editOrder]);

  useEffect(() => {
    if (!orderDetailModal && orderToCopy) {
      if (orderToCopy?.type === AVOrderType.AV_RENTAL) {
        setCopyOrderModal(true);
      }

      if (orderToCopy?.type === AVOrderType.WEB_CONF) {
        setCopyWebConfOrderModal(true);
      }
    }
  }, [orderToCopy, orderDetailModal]);

  useEffect(() => {
    getOrders();
    const interval = setInterval(() => {
      getOrders(true);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [getOrders]);

  useEffect(() => {
    getAVCustomerEndClients();
  }, [getAVCustomerEndClients]);

  const cancelOrder = useCallback(async () => {
    setIsCancelLoading(true);
    const res = await post<boolean, CancelOrderPayload>(
      `${API_ROUTES.AV_CUSTOMER}${API_ROUTES.CANCEL_ORDER}`,
      { orderId: recordId },
    );
    setIsCancelLoading(false);
    if (res.error) return;
    getOrders();
    openCancelledOrderAlert(recordId);
    onCloseConfirmation();
  }, [getOrders, onCloseConfirmation, openCancelledOrderAlert, post, recordId]);

  const getConfirmationMessage = useCallback(() => {
    const data = avFilteredOrders.find((order) => order._id === recordId);
    return msgBasedOnCancellationPolicy(
      ORDER_ALERT_MESSAGES.BEFORE_WINDOW,
      ORDER_ALERT_MESSAGES.WITH_IN_WINDOW,
      customer,
      data,
      user?.timezone,
    );
  }, [avFilteredOrders, recordId, user?.timezone, customer]);

  const getFeedbackMessage = useCallback(() => {
    const data = avFilteredOrders.find(
      (order) => order._id === cancelledOrderId,
    );
    return msgBasedOnCancellationPolicy(
      ORDER_ALERT_MESSAGES.CANCELLED_SUCCESS_BEFORE_WINDOW,
      ORDER_ALERT_MESSAGES.CANCELLED_SUCCESS_WITH_IN_WINDOW,
      customer,
      data,
      user?.timezone,
    );
  }, [avFilteredOrders, cancelledOrderId, user?.timezone, customer]);

  const getData = useCallback(async () => {
    const stateP = await get<State[]>(API_ROUTES.GET_STATES);
    if (stateP.data) setStates(stateP.data);
  }, [get]);

  useEffect(() => {
    getData();
    getDashboardFormFields();
  }, []);

  const getDashboardFormFields = useCallback(async () => {
    const { data } = await get<AVOrderFormField[]>(
      `${API_ROUTES.AV_CUSTOMER}/dashboard-form-fields`,
    );

    if (data) {
      setDashboardFormFields(data);
    }
  }, [get]);

  const addToLocalOrderState = useCallback((order: AVOrder) => {
    setFilteredAVOrders((prev) => prev.concat(order));
  }, []);

  const updateLocalOrderState = useCallback((order: AVOrder) => {
    setFilteredAVOrders((prev) => {
      const index = prev.findIndex((item) => item._id === order?._id);
      if (index !== 0) {
        prev[index] = order;
      }
      return prev;
    });
  }, []);

  const closeOrderModal = useCallback(() => {
    setOrderModal(false);
    setOrderToCopy(undefined);
    setEditOrder(undefined);
    setKeysToCopy([]);
  }, []);

  const handleSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onSetSearchFilter(e.target.value);
  }, []);

  const setOrderId = useCallback(
    (orderId: string) => {
      onCopyOrder(orderId);
      setOrderDetailModal(true);
    },
    [onCopyOrder],
  );

  const onClickCheckbox = useCallback(
    (flag: boolean) => {
      if (flag) {
        const orders = avFilteredOrders.map((order) => order._id);
        setSelectedOrders(orders);
      } else {
        setSelectedOrders([]);
      }
    },
    [avFilteredOrders],
  );

  const onSelectRow = useCallback((orderId: string) => {
    setSelectedOrders((prev) => {
      if (prev.includes(orderId)) {
        return prev.filter((item) => item !== orderId);
      }
      return [...prev, orderId];
    });
  }, []);

  const debounceResults = useMemo(
    () => debounce(handleSearch, 300),
    [handleSearch],
  );

  const onCreateWebConfOrder = useCallback(async () => {
    await getNextAVOrderId();
    setEditOrder(undefined);
    setWebConfOrderModal(true);
  }, [getNextAVOrderId]);

  const onCloseWebConfOrderModal = useCallback(() => {
    setWebConfOrderModal(false);
    setEditOrder(undefined);
  }, []);

  const onApplyAdvanceFilter = useCallback((filters: IFilterOrder = {}) => {
    onSetAdvanceFilters({ ...filters });
  }, []);

  const allowExportOrders = useMemo(() => {
    if (selectedOrders.length > 0 && avFilteredOrders.length > 0) {
      return selectedOrders.some((orderId) =>
        avFilteredOrders.find((order) => order._id === orderId),
      );
    }
    return false;
  }, [selectedOrders, avFilteredOrders]);

  return (
    <div className='h-screen'>
      <Header />
      <div className='h-[calc(100vh-135px)] w-full max-w-[1920px] mx-auto'>
        {orderModal ? (
          <OrderForm
            items={items}
            states={states}
            bundles={bundles}
            editOrder={editOrder}
            orderModal={orderModal}
            endClients={endClients}
            keysToCopy={keysToCopy}
            nextOrderId={nextOrderId}
            orderToCopy={orderToCopy}
            resetForm={() => setEditOrder(undefined)}
            closeOrderModal={closeOrderModal}
            getAVItemsAndBundles={getAVItemsAndBundles}
            addToLocalOrderState={addToLocalOrderState}
            updateLocalOrderState={updateLocalOrderState}
            isWithinCancellationPolicy={isWithinCancellationPolicy}
          />
        ) : null}

        {webConfOrderModal ? (
          <WebConfOrder
            states={states}
            editOrder={editOrder}
            keysToCopy={keysToCopy}
            endClients={endClients}
            orderToCopy={orderToCopy}
            nextOrderId={nextOrderId}
            webConfOrderModal={webConfOrderModal}
            addToLocalOrderState={addToLocalOrderState}
            updateLocalOrderState={updateLocalOrderState}
            onCloseWebConfOrderModal={onCloseWebConfOrderModal}
            isWithinCancellationPolicy={isWithinCancellationPolicy}
          />
        ) : null}

        {webConfOrderModal || orderModal ? null : (
          <div className='w-full px-10 mx-auto flex flex-col h-full justify-start pt-8'>
            <OrderToolbar
              searchQuery={localSearchQuery}
              isFilterApplied={isFilterApplied}
              customer={customer}
              debounceResults={debounceResults}
              onFilter={onFilter}
              onExport={onExport}
              allowExportOrders={allowExportOrders}
              onCreateOrder={onCreateOrder}
              onCreateWebConfOrder={onCreateWebConfOrder}
            />

            <DataTable
              label='AV Orders'
              description='AV Orders with Order ID, Attendee Count, Phone etc'
              isLoading={isLoading}
              data={avFilteredOrders}
              onCopy={onCopyOrder}
              onEdit={onEditOrder}
              onCreate={onCreateOrder}
              downloadPDF={downloadPDF}
              onClickCheckbox={onClickCheckbox}
              onCancel={onCancel}
              isChecked={
                selectedOrders.length > 0 &&
                selectedOrders.length === avFilteredOrders.length
              }
              columns={getAVOrderColumn(
                user?.timezone,
                setOrderId,
                selectedOrders,
                onSelectRow,
                dashboardFormFields,
              )}
              state={{ sorting: [{ id: 'startDate', desc: true }] }}
            />

            <SideOver
              open={isSideOver}
              states={states}
              dashboardFormFields={dashboardFormFields}
              closeSideOver={() => setSideOver(false)}
              onApplyAdvanceFilter={onApplyAdvanceFilter}
            />

            <AlertMessage
              title='Cancel Order'
              isOpen={openConfirm}
              onClose={onCloseConfirmation}
              message={getConfirmationMessage()}
              buttons={[
                {
                  label: 'Confirm',
                  className:
                    'text-white !bg-red-500 !px-12 !py-[10px] rounded-xl',
                  onClick: () => cancelOrder(),
                  isLoading: isCancelLoading,
                },
              ]}
            />

            <AlertMessage
              title='Order Cancelled'
              isOpen={showCancelledOrderAlert}
              onClose={closeCancelledOrderAlert}
              message={getFeedbackMessage()}
              buttons={[
                {
                  label: 'Ok',
                  className: '!text-white !bg-green-950 mr-2 !px-12 rounded-xl',
                  onClick: closeCancelledOrderAlert,
                },
              ]}
            />

            {copyOrderModal && orderToCopy ? (
              <CopyProjectModal
                states={states}
                items={items}
                bundles={bundles}
                orderToCopy={orderToCopy}
                onClose={onCloseCopyOrderModal}
                onCreate={onCreateOrderFromCopy}
              />
            ) : null}

            {copyWebConfOrderModal && orderToCopy ? (
              <WebconfCopyOrderModal
                orderToCopy={orderToCopy}
                onClose={onCloseCopyOrderModal}
                onCreate={onCreateWebConfOrderFromCopy}
              />
            ) : null}

            {orderDetailModal && orderToCopy ? (
              <OrderDetailModal
                items={items}
                states={states}
                bundles={bundles}
                apiLoading={apiLoading}
                orderToCopy={orderToCopy}
                onClose={onCloseCopyOrderModal}
              />
            ) : null}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default OrdersPage;
