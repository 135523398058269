import React from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import { AVOrderFormField } from 'types/av-form-fields';
import { CreateAVOrder } from 'types/av-orders';

import { OrderDynamicForm } from './OrderDynamicForm';
import { MainHeading } from './UtilComponents';

interface IOrderEventDetailForm {
  control: Control<CreateAVOrder>;
  isLoading: boolean;
  dynamicFormFields: AVOrderFormField[];
  setValue: UseFormSetValue<CreateAVOrder>;
}

const OrderEventDetailForm: React.FC<IOrderEventDetailForm> = ({
  dynamicFormFields,
  control,
  isLoading,
  setValue,
}) => {
  return (
    <div>
      {dynamicFormFields?.length ? (
        <MainHeading title='Event Details' classNames='mt-8' />
      ) : null}

      <OrderDynamicForm
        control={control}
        setValue={setValue}
        isLoading={isLoading}
        dynamicFields={dynamicFormFields}
      />
    </div>
  );
};

export default OrderEventDetailForm;
