import React, { useMemo } from 'react';
import classNames from 'classnames';
import { WEBCONF_BASE_PRICE } from 'constants/orders';
import { AVOrder, CreateAVOrder } from 'types/av-orders';
import { calculateWebConfOrderPrices } from 'utils/avUtils';
import { formatCurrency } from 'utils/helper';

import { TotalQuoteItemContainer } from '../OrderForm/UtilComponents';

interface IWebConfOrderVenueForm {
  order: AVOrder | CreateAVOrder | undefined;
  checkIfFieldDirty: (fieldName: string) => boolean;
}

const WebConfOrderLineItems: React.FC<IWebConfOrderVenueForm> = ({
  order,
  checkIfFieldDirty,
}) => {
  const memoizedLineItems = useMemo(() => {
    if (order?.attendeeCount && order?.startTime && order?.endTime) {
      return calculateWebConfOrderPrices(
        order?.basePrice || WEBCONF_BASE_PRICE,
        order?.attendeeCount,
        order?.startDate,
        order?.startTime,
        order?.endDate,
        order?.endTime,
      );
    }
    return {
      additionalDuration: 0,
      additionalDurationPrice: 0,
      additionalAttendees: 0,
      additionalAttendeesPrice: 0,
      total: WEBCONF_BASE_PRICE,
    };
  }, [
    order?.attendeeCount,
    order?.startTime,
    order?.endTime,
    order?.basePrice,
    order?.startDate,
    order?.endDate,
  ]);

  const isAdditionalDurationPriceChanged = useMemo(
    () => checkIfFieldDirty('additionalDurationPrice'),
    [checkIfFieldDirty],
  );

  const isAdditionalAttendeesPriceChanged = useMemo(
    () => checkIfFieldDirty('additionalAttendeesPrice'),
    [checkIfFieldDirty],
  );

  return (
    <div>
      <div className='flex justify-between mb-4'>
        <div className='flex-[0.8]'>
          <h2 className='text-base font-medium text-gray-950'>Base Price</h2>
        </div>
        <p className='flex-[0.2] text-base font-normal text-gray-950 text-right'>
          {formatCurrency(order?.basePrice || WEBCONF_BASE_PRICE)}
        </p>
      </div>
      <div
        className={classNames('flex justify-between mb-4', {
          'bg-yellow-200 px-1 rounded-lg mb-1':
            isAdditionalDurationPriceChanged,
        })}
      >
        <div className='flex-[0.8]'>
          <h2 className='text-base font-medium text-gray-950'>
            Additional Duration{' '}
            {memoizedLineItems.additionalDuration > 0
              ? `(${memoizedLineItems.additionalDuration} ${
                  memoizedLineItems.additionalDuration > 1 ? 'hours' : 'hour'
                })`
              : ''}
          </h2>
        </div>
        <p className='flex-[0.2] text-base font-normal text-gray-950 text-right'>
          {formatCurrency(memoizedLineItems.additionalDurationPrice)}
        </p>
      </div>
      <div
        className={classNames('flex justify-between mb-4', {
          'bg-yellow-200 px-1 rounded-lg mb-1':
            isAdditionalAttendeesPriceChanged,
        })}
      >
        <div className='flex-[0.8]'>
          <h2 className='text-base font-medium text-gray-950'>
            Additional Attendees{' '}
            {memoizedLineItems.additionalAttendees > 0
              ? `(${memoizedLineItems.additionalAttendees} attendees)`
              : ''}
          </h2>
        </div>
        <p className='flex-[0.2] text-base font-normal text-gray-950 text-right'>
          {formatCurrency(memoizedLineItems.additionalAttendeesPrice)}
        </p>
      </div>
      <div className='flex justify-between mt-8 pt-1 border-t border-t-gray-300'>
        <h2 className='font-bold text-base text-gray-950 flex-[0.8]'>
          Sub-total
        </h2>
        <h2 className='font-bold text-base text-gray-950 flex-[0.2] text-right'>
          {formatCurrency(memoizedLineItems.total)}
        </h2>
      </div>
      {order?.rushFee ? (
        <TotalQuoteItemContainer
          heading='Rush Fee'
          value={formatCurrency(order?.rushFee)}
        />
      ) : null}
      <div className='flex justify-between mt-8'>
        <h2 className='font-bold text-base text-gray-950 uppercase flex-[0.8]'>
          Order Total
        </h2>
        <h2 className='font-bold text-base text-gray-950 flex-[0.2] text-right'>
          {formatCurrency(memoizedLineItems.total + (order?.rushFee || 0))}
        </h2>
      </div>
    </div>
  );
};

export default WebConfOrderLineItems;
