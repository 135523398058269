import React from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import Input from 'components/Inputs';
import { AVOrderFormField } from 'types/av-form-fields';
import { CreateAVOrder } from 'types/av-orders';

import { OrderDynamicForm } from './OrderDynamicForm';
import { MainHeading } from './UtilComponents';

interface IOrderContactForm {
  isLoading: boolean;
  control: Control<CreateAVOrder>;
  dynamicFormFields: AVOrderFormField[];
  setValue?: UseFormSetValue<CreateAVOrder>;
}

const OrderContactForm: React.FC<IOrderContactForm> = ({
  setValue,
  control,
  isLoading,
  dynamicFormFields,
}) => {
  return (
    <div className='mt-8'>
      <MainHeading title='Contacts' />

      <div className='text-sm font-medium mt-2 text-gray-800'>
        Venue Contact
      </div>
      <div className='flex mt-2 gap-2 items-center'>
        <div className='sm:w-2/5 xl:w-1/3'>
          <Controller
            control={control}
            name='venue.venueContact.name'
            rules={{
              required: true,
            }}
            render={({ fieldState: { error }, field }) => (
              <Input
                type='text'
                label='Full Name'
                value={field.value}
                onChange={field.onChange}
                error={error}
                name={field.name}
                placeholder='Enter Name'
              />
            )}
          />
        </div>
        <div className='sm:w-2/5 xl:w-1/3'>
          <Controller
            control={control}
            name='venue.venueContact.phone'
            rules={{
              required: true,
            }}
            render={({ fieldState: { error }, field }) => (
              <Input
                type='text'
                label='Phone Number'
                value={field.value}
                onChange={field.onChange}
                error={error}
                name={field.name}
                placeholder='Enter Phone Number'
              />
            )}
          />
        </div>
      </div>

      <div className='text-sm font-medium mt-4 text-gray-800'>
        On-Site Contact
      </div>

      <div className='flex gap-2 mt-1'>
        <div className='sm:w-2/5 xl:w-1/3'>
          <Controller
            control={control}
            name='onSiteContact.name'
            rules={{
              required: 'Full Name is required',
            }}
            render={({ fieldState: { error }, field }) => (
              <Input
                type='text'
                label='Full Name'
                value={field.value}
                onChange={field.onChange}
                error={error}
                name={field.name}
                placeholder='Enter Full Name'
              />
            )}
          />
        </div>
        <div className='sm:w-2/5 xl:w-1/3'>
          <Controller
            control={control}
            name='onSiteContact.phone'
            rules={{
              required: 'Phone Number is required',
            }}
            render={({ fieldState: { error }, field }) => (
              <Input
                type='text'
                label='Phone Number'
                value={field.value}
                onChange={field.onChange}
                error={error}
                name={field.name}
                placeholder='Enter Phone Number'
              />
            )}
          />
        </div>
      </div>

      <OrderDynamicForm
        control={control}
        setValue={setValue}
        isLoading={isLoading}
        dynamicFields={dynamicFormFields}
      />
    </div>
  );
};

export default OrderContactForm;
