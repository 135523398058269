import React, { FC, useEffect, useState } from 'react';
import StripeCardElement from 'components/CreditCard/StripeCardElement';

interface ICreditCardComponent {
  onCompleted: () => void;
}

const CreditCardComponent: FC<ICreditCardComponent> = ({ onCompleted }) => {
  const [validCardnumber, setValidCardnumber] = useState<boolean>(false);
  const [validExpiry, setValidExpiry] = useState<boolean>(false);
  const [validCvc, setValidCvc] = useState<boolean>(false);

  useEffect(() => {
    if (validCardnumber && validExpiry && validCvc) {
      onCompleted();
    }
  }, [onCompleted, validCardnumber, validExpiry, validCvc]);

  return (
    <>
      <div className='mt-2'>
        <StripeCardElement
          label='Card Number'
          type='card-number'
          onChange={(e) => setValidCardnumber(e.complete)}
        />
        <div className='flex gap-4'>
          <StripeCardElement
            label='Valid Thru'
            type='card-expiry'
            onChange={(e) => setValidExpiry(e.complete)}
          />
          <StripeCardElement
            label='CVC'
            type='card-cvc'
            onChange={(e) => setValidCvc(e.complete)}
          />
        </div>
      </div>
    </>
  );
};

export default CreditCardComponent;
