import { FC } from 'react';

type SectionHeadingProps = {
  heading: string;
};

export const SectionHeading: FC<SectionHeadingProps> = ({ heading }) => {
  return (
    <h2 className='text-lg font-semibold text-gray-950 mb-2'>{heading}</h2>
  );
};

export default SectionHeading;
