import React from 'react';
import { ReactComponent as CheckIcon } from 'assets/check.svg';

interface ICheckbox {
  label?: string;
  value?: string;
  name: string;
  checked: boolean;
  containerClass?: string;
  checkClass?: string;
  checkBoxClass?: string;
  labelClass?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (e: any) => void;
}

const Checkbox: React.FC<ICheckbox> = ({
  label,
  value,
  name,
  checked,
  containerClass,
  checkBoxClass,
  checkClass,
  labelClass,
  onChange,
}) => {
  return (
    <div className={`relative flex items-start py-4 ${containerClass}`}>
      <div className='mr-3 flex h-6 items-center'>
        <input
          onChange={onChange}
          checked={checked}
          value={value}
          id={name}
          name={name}
          type='checkbox'
          className={`peer relative appearance-none rounded-md w-5 h-5 cursor-pointer border border-gray-300 focus-visible:outline-none checked:bg-green-950 ${checkBoxClass}`}
        />
        <CheckIcon
          className={`w-4 h-4 left-[2px] absolute hidden peer-checked:block cursor-pointer ${checkClass}`}
          onClick={() => onChange({ target: { checked: !checked } })}
        />
      </div>
      {label ? (
        <div className='min-w-0 flex-1 text-sm leading-6'>
          <label
            htmlFor={name}
            className={`select-none font-medium text-gray-800 ${labelClass}`}
          >
            {label}
          </label>
        </div>
      ) : null}
    </div>
  );
};

export default Checkbox;
