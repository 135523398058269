/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';
import Input from 'components/Inputs';
import Select from 'components/Inputs/Select';
import { AVOrderFormField, GenericObject } from 'types/av-form-fields';
import { InputTypes } from 'types/form';

enum ChangeIdentifier {
  INPUT = 'input',
  DROPDOWN = 'dropdown',
}

type DynamicFilterFieldsProps = {
  dynamicFields: AVOrderFormField[];
  dynamicFilters: GenericObject;
  setDynamicFilters: React.Dispatch<React.SetStateAction<GenericObject>>;
};

export const DynamicFilterFields: FC<DynamicFilterFieldsProps> = ({
  dynamicFields,
  dynamicFilters,
  setDynamicFilters,
}) => {
  const handleInputChange =
    (type: string, metadataKey: string) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (type === ChangeIdentifier.INPUT) {
        setDynamicFilters({
          ...dynamicFilters,
          [metadataKey]: e.target.value,
        });
      } else {
        setDynamicFilters({
          ...dynamicFilters,
          [metadataKey]: e,
        });
      }
    };

  const handleCheckboxChange = (metadataKey: string, value: string[]) => {
    const __dynamicFilters = { ...dynamicFilters };
    if (!value.length) {
      delete __dynamicFilters[metadataKey];
    } else {
      __dynamicFilters[metadataKey] = value.join(',');
    }
    setDynamicFilters(__dynamicFilters);
  };

  const handleDateChange = (metadataKey: string) => (value: DateValueType) => {
    setDynamicFilters({
      ...dynamicFilters,
      [metadataKey]: value,
    });
  };
  return (
    <>
      {dynamicFields?.map((val, index) => {
        const fieldValue = dynamicFilters[val.metadataKey] || '';
        if (
          [InputTypes.TEXT, InputTypes.TEXT_AREA].includes(
            val.inputType as InputTypes,
          )
        ) {
          return (
            <div key={`${index}`} className='mb-2'>
              <Input
                type='text'
                placeholder={`Enter ${val.label}`}
                value={fieldValue}
                onChange={handleInputChange(
                  ChangeIdentifier.INPUT,
                  val.metadataKey,
                )}
                label={val.label}
                name={val.metadataKey}
              />
            </div>
          );
        }

        if (val.inputType === InputTypes.DATE) {
          return (
            <div key={index} className='mb-2'>
              <label className='block text-sm font-medium leading-6 text-gray-900'>
                {val.label}
              </label>
              <Datepicker
                classNames={{
                  input: () =>
                    'border py-2 w-full px-3 rounded-md text-sm focus-visible:outline-green-950',
                }}
                asSingle
                useRange={false}
                onChange={handleDateChange(val.metadataKey)}
                value={fieldValue}
                displayFormat={'MM/DD/YYYY'}
              />
            </div>
          );
        }

        if (val.inputType === InputTypes.SECTION) {
          return null;
        }

        if (val.inputType === InputTypes.CHECKBOX) {
          const checkboxValueArray = fieldValue ? fieldValue.split(',') : [];
          return (
            <div key={index} className='mb-2'>
              <Select
                multiple
                value={checkboxValueArray}
                onChange={(e) => {
                  handleCheckboxChange(val.metadataKey, e);
                }}
                options={val.inputValues.split(',').map((op) => ({
                  label: op.trim(),
                  key: op.trim(),
                }))}
                label={val.label}
                name={val.metadataKey}
                controlStyle={{ marginTop: 0 }}
              />
            </div>
          );
        }

        return (
          <div key={index} className='mb-2'>
            <Select
              value={fieldValue}
              onChange={handleInputChange(
                ChangeIdentifier.DROPDOWN,
                val.metadataKey,
              )}
              options={val.inputValues.split(',').map((op) => ({
                label: op.trim(),
                key: op.trim(),
              }))}
              label={val.label}
              name={val.metadataKey}
              controlStyle={{ marginTop: 0 }}
            />
          </div>
        );
      })}
    </>
  );
};
